import React, { useDeferredValue, useEffect, useState } from 'react';
import { Grid, GridColumn, useApi, Suspense, Gutter } from '@axiom/ui';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import qs from 'qs';

import { WorkFeedFilters } from '../WorkFeedFilters/WorkFeedFilters';
import { CandidateUtil } from '../../utils/candidate-util';
import { WorkFeedUtil } from '../../utils/work-feed-util';
import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { PracticeAreaApi } from '../../api/protected/practice-areas/practice-areas';
import { UserApi } from '../../api/protected/user/user';
import { WorkFeedInterestedTracker } from '../WorkFeedInterestedTracker/WorkFeedInterestedTracker';

import { WorkFeedBody } from './WorkFeedBody';

export const WorkFeed = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { pageNumber: urlPageNumber, candidateId: urlCandidateId } =
    useParams();
  const parsedPageNumber = urlPageNumber ? parseInt(urlPageNumber, 10) : 1;
  const [pageNumber, setPageNumber] = useState(parsedPageNumber);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentView = WorkFeedUtil.getView(location.pathname);
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }, { data: practiceAreas }] = useApi(
    CandidateApi.readCandidate(candidateId),
    PracticeAreaApi.readPracticeAreas()
  );

  const getFilters = () => {
    return WorkFeedFilterUtil.getFilters(
      searchParams,
      candidate,
      practiceAreas
    );
  };

  const [filters, setFilters] = useState(getFilters());
  const deferredFilters = useDeferredValue(filters);
  const deferredPageNumber = useDeferredValue(pageNumber);

  useEffect(() => {
    const searchFilters = searchParams.get('filters');

    if (searchFilters) {
      const washedFilters = WorkFeedFilterUtil.washFilters(
        qs.parse(searchFilters, { arrayLimit: 40 })
      );
      setSearchParams({
        filters: washedFilters ? qs.stringify(washedFilters) : '',
      });
    }
  }, []);

  useEffect(() => {
    setFilters({ ...getFilters() });
    setPageNumber(parsedPageNumber);
  }, [searchParams, parsedPageNumber]);

  return (
    <>
      <Grid name="WORK_FEED">
        <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
          <WorkFeedInterestedTracker candidateId={candidateId} />
          <Gutter bottom="16px" />
          {WorkFeedUtil.isAllView(currentView) && (
            <WorkFeedFilters candidate={candidate} filters={filters} />
          )}
        </GridColumn>
        <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
          <Suspense
            isPending={
              filters !== deferredFilters || pageNumber !== deferredPageNumber
            }
          >
            <WorkFeedBody
              currentView={currentView}
              candidate={candidate}
              filters={deferredFilters}
              pageNumber={deferredPageNumber}
            />
          </Suspense>
        </GridColumn>
      </Grid>
    </>
  );
};
