import React from 'react';
import { shape, func, arrayOf, string } from 'prop-types';
import { Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { CandidateOpportunitiesConst } from '@axiom/const';
import {
  DataTest,
  Button,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Text,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';

import { FormNewOpportunityYesStore } from '../../stores/protected/form-new-opportunity-yes-store';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { RawCheckbox } from '../../shared/Checkbox/Checkbox';
import { CandidateExperiencesStore } from '../../stores/protected/candidate-experiences-store';
import CharacterCounter from '../CharacterCounter/CharacterCounter';
import { formatDataTestId } from '../../utils/dataTest';
import { DateUtil } from '../../utils/date-util';

import {
  NOFormGroupIndent,
  NOInputColumnWrapper,
  NOSectionWrapper,
  NOCheckboxWrapper,
  NODropdownWrapper,
} from './NewOpportunitiesStyles';

const { OptInQuestions } = CandidateOpportunitiesConst;

const getCheckboxLabel = experience => {
  if (!experience.startDate && !experience.endDate) {
    return null;
  }
  const displayStartDate = DateUtil.displayLongMonthYear(experience.startDate);
  const displayEndDate = experience.endDate
    ? DateUtil.displayLongMonthYear(experience.endDate)
    : 'Present';

  return (
    <>
      <div>{experience.client};</div>
      <div>
        {displayStartDate} - {displayEndDate}
      </div>
    </>
  );
};

export class NewOpportunityYesModalComponent extends React.Component {
  handleCheckbox = value =>
    this.props.setForm({ hasNoConflictOfInterest: value });

  handleInputChange = (event, propName) => {
    const data = event.target.value;

    this.props.setForm({
      [propName]: data,
    });
  };

  handleDropdown = value =>
    this.props.setForm({ candidateExploringOutsideRoles: value });

  handleExperienceActions = (expId, isChecked) => {
    let checkedExperiences = [...this.props.opportunityYesForm.experienceIds];
    if (isChecked) {
      checkedExperiences.push(expId);
    } else {
      checkedExperiences = checkedExperiences.filter(id => id !== expId);
    }

    this.props.setForm({ experienceIds: checkedExperiences });
  };

  render() {
    const {
      candidateId,
      experiences,
      opportunityYesForm,
      opportunityYesForm: {
        experienceIds,
        hasNoConflictOfInterest,
        candidateAvailabilityNotes,
        candidateExperienceNotes,
        candidateExploringOutsideRoles,
        candidatePlannedTimeOffNotes,
      },
      submitForm,
      cancelForm,
    } = this.props;

    return (
      <Modal>
        <ModalHeader onClose={cancelForm}>Opt Into Job Opportunity</ModalHeader>
        <ModalSection>
          {experiences.length > 0 && (
            <DataTest value="new_opp_experience_container">
              <h4>Select Relevant Experience</h4>
              <p>
                Select each experience that makes you a good match for this
                opportunity
              </p>
              <NOFormGroupIndent>
                <Grid>
                  <GridRow>
                    {experiences.map(item => (
                      <GridColumn
                        desktopWidth="6"
                        tabletWidth="6"
                        mobileWidth="12"
                      >
                        <NOInputColumnWrapper key={item.id}>
                          <RawCheckbox
                            checked={experienceIds.indexOf(item.id) > -1}
                            label={getCheckboxLabel(item)}
                            onChange={isChecked =>
                              this.handleExperienceActions(item.id, isChecked)
                            }
                          />
                        </NOInputColumnWrapper>
                      </GridColumn>
                    ))}
                  </GridRow>
                </Grid>
              </NOFormGroupIndent>
              <Gutter top="10px" bottom="30px">
                <Text size="small">
                  Please make sure that each experience you select here is
                  accurate and up to date in your bio
                </Text>
              </Gutter>
            </DataTest>
          )}
          <h4>Tell Us More</h4>
          <NOSectionWrapper
            data-test={formatDataTestId('Conflicts_Of_Interest_container')}
          >
            <Label>
              To opt in to the opportunity, you must certify that there is no
              conflict of interest. If you’re not sure if something is a
              conflict of interest and want to talk to us about it, close this
              window, change your response to Unsure, and we’ll reach out to you
              soon.
            </Label>
            <NOCheckboxWrapper
              name="hasNoConflictOfInterest"
              checked={hasNoConflictOfInterest}
              onChange={this.handleCheckbox}
              label={OptInQuestions.hasNoConflictOfInterest}
            />
          </NOSectionWrapper>
          <NOSectionWrapper
            data-test={formatDataTestId('Exploring_Opportunities_container')}
          >
            <Label>
              <DataTest value="Exploring_Outside_Roles_Label" inline>
                {OptInQuestions.candidateExploringOutsideRoles}
              </DataTest>
            </Label>
            <NODropdownWrapper
              name="candidateExploringOutsideRoles"
              dataTest={formatDataTestId('Exploring_Outside_Roles')}
              options={[
                'Yes, I am actively seeking other roles',
                'Yes, I am currently interviewing for another role',
                'No, not at this time',
              ]}
              value={candidateExploringOutsideRoles}
              onChange={this.handleDropdown}
              appendLocal
            />
          </NOSectionWrapper>
          <NOSectionWrapper
            data-test={formatDataTestId('Planned_Time_Off_container')}
          >
            <Label>
              <DataTest value="label" inline>
                {OptInQuestions.candidatePlannedTimeOffNotes}
              </DataTest>
            </Label>
            <Input
              data-test={formatDataTestId('Planned_Time_Off_Notes')}
              placeholder="Description text goes here."
              onChange={event =>
                this.handleInputChange(event, 'candidatePlannedTimeOffNotes')
              }
              type="textarea"
              name="candidatePlannedTimeOffNotes"
              maxLength={1000}
              value={candidatePlannedTimeOffNotes}
            />
            <CharacterCounter
              text={candidatePlannedTimeOffNotes}
              location="right"
              className="text-muted"
            />
          </NOSectionWrapper>
          <NOSectionWrapper
            data-test={formatDataTestId(
              'Engagement_Start_Availability_container'
            )}
          >
            <Label>
              <DataTest value="label" inline>
                {OptInQuestions.candidateAvailabilityNotes}
              </DataTest>
            </Label>
            <Input
              data-test={formatDataTestId('Availability_Notes')}
              placeholder="Description text goes here."
              onChange={event =>
                this.handleInputChange(event, 'candidateAvailabilityNotes')
              }
              type="textarea"
              name="candidateAvailabilityNotes"
              maxLength={1000}
              value={candidateAvailabilityNotes}
            />
            <CharacterCounter
              text={candidateAvailabilityNotes}
              location="right"
              className="text-muted"
            />
          </NOSectionWrapper>
          <NOSectionWrapper
            data-test={formatDataTestId('Experience_Highlights_container')}
          >
            <Label>
              <DataTest value="label" inline>
                {OptInQuestions.candidateExperienceNotes}
              </DataTest>
            </Label>
            <Input
              data-test={formatDataTestId('Experience_Notes')}
              placeholder="Description text goes here."
              name="candidateExperienceNotes"
              onChange={event =>
                this.handleInputChange(event, 'candidateExperienceNotes')
              }
              maxLength={1000}
              type="textarea"
              value={candidateExperienceNotes}
            />
            <CharacterCounter
              text={candidateExperienceNotes}
              location="right"
              className="text-muted"
            />
          </NOSectionWrapper>
          <Gutter top="10px" bottom="30px">
            <Text size="small" className="font-weight-bold">
              We'll consider your preferences when we submit you to clients.
              Please make sure the My Preferences section in your profile is up
              to date.
            </Text>
          </Gutter>
        </ModalSection>
        <ModalFooter>
          <Button variation="outline" name="CANCEL" onClick={cancelForm}>
            Cancel
          </Button>
          <Button
            name="SUBMIT"
            disabled={!hasNoConflictOfInterest}
            onClick={() => submitForm(candidateId, opportunityYesForm)}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

NewOpportunityYesModalComponent.defaultProps = {
  experiences: [],
};
NewOpportunityYesModalComponent.propTypes = {
  experiences: arrayOf(shape({})),
  candidateId: string.isRequired,
  opportunityYesForm: FormNewOpportunityYesStore.getDataShape().isRequired,
  submitForm: func.isRequired,
  cancelForm: func.isRequired,
  setForm: func.isRequired,
};
export default connect(
  state => ({
    candidateId: CurrentCandidateStore.selectCandidate(state).record.id,
    experiences: CandidateExperiencesStore.selectExperiences(state),
    opportunityYesForm: FormNewOpportunityYesStore.selectFormData(state),
  }),
  {
    cancelForm: FormNewOpportunityYesStore.actionHideForm,
    submitForm: FormNewOpportunityYesStore.actionSubmitForm,
    setForm: FormNewOpportunityYesStore.actionSetForm,
  }
)(NewOpportunityYesModalComponent);
