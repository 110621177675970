import { groupBy } from 'lodash';
import { LanguageConst } from '@axiom/const';

import { capitalizeString } from './string-util';

export const LanguageUtil = {
  groupByCategories(languages) {
    const groupedByName = groupBy(languages, lang => lang.name);

    Object.keys(groupedByName).forEach(langName => {
      groupedByName[langName] = groupBy(
        groupedByName[langName],
        lang =>
          `n:${lang.name}:p:${lang.languageProficiency}:c:${lang.languageCEFR}`
      );
    });

    return groupedByName;
  },
  buildLanguageDisplayBars(languages) {
    return [
      capitalizeString(
        LanguageConst.LANGUAGE_PROFICIENCIES[languages[0].languageProficiency]
      ),
      languages
        .reduce((langs, current) => {
          if (current.languageSkill) {
            langs.push(capitalizeString(current.languageSkill));
          }
          return langs;
        }, [])
        .join(', '),
    ]
      .filter(item => item)
      .join(' \u2022 ');
  },
};
