import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Gutter,
  Pill,
  Pills,
  SidedrawerUtil,
  Visible,
  useBreakpoint,
  useApi,
} from '@axiom/ui';
import { Candidate, CandidateWorkFeedFormFilter } from '@axiom/validation';

import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { FilterPill } from '../../types/work-feed-types';
import { WorkFeedFiltersSidedrawer } from '../WorkFeedFiltersSidedrawer/WorkFeedFiltersSidedrawer';
import { PracticeAreaApi } from '../../api/protected/practice-areas/practice-areas';

import { useWorkFeedFilterPills } from './useWorkFeedFilterPills';

export const WorkFeedFilterPills = ({
  candidate,
  filters,
}: {
  candidate: Candidate;
  filters: CandidateWorkFeedFormFilter;
}) => {
  const [_, setSearchParams] = useSearchParams();
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());
  const { isMobile } = useBreakpoint();
  const pills = useWorkFeedFilterPills(filters);

  const onRemoveFilter = (filter: FilterPill) => {
    const category = filters[filter.name as keyof typeof filters];
    const index = category.indexOf(filter.value);
    category.splice(index, 1);

    WorkFeedFilterUtil.applyFilters(
      setSearchParams,
      candidate,
      practiceAreas,
      filters
    );
  };

  const handleClearAll = () => {
    WorkFeedFilterUtil.applyFilters(
      setSearchParams,
      candidate,
      practiceAreas,
      null
    );
  };

  const handleOpenSidedrawer = () => {
    SidedrawerUtil.show({
      direction: 'left',
      only: 'smallScreen',
      name: 'work-feed-filters-side-drawer',
      content: <WorkFeedFiltersSidedrawer candidate={candidate} />,
    });
  };

  return (
    <>
      <Visible only="smallScreen">
        <Button
          name="OPEN_FILTERS_SIDEDRAWER"
          pattern="secondary"
          onClick={handleOpenSidedrawer}
          fluid={isMobile}
        >
          Show filters
        </Button>
        <Gutter bottom="16px" />
      </Visible>
      {pills.length > 0 && (
        <Gutter bottom="16px">
          <Pills name="WORK_FEED_FILTER_PILLS">
            {pills.map(pill => (
              <Pill
                name={pill.value}
                data-value={pill.value}
                key={pill.value}
                pattern="secondary"
                onDismiss={() => {
                  onRemoveFilter(pill);
                }}
              >
                {pill.label}
              </Pill>
            ))}
            <Button
              name="CLEAR_ALL"
              onClick={handleClearAll}
              variation="minimal"
              pattern="secondary"
            >
              Clear All
            </Button>
          </Pills>
        </Gutter>
      )}
    </>
  );
};
