import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError, useApiWithErrors, Tabs, Tab, Gutter } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import {
  WORK_FEED_VIEWS,
  WorkFeedUtil,
  WorkFeedViewsType,
} from '../../utils/work-feed-util';
import { WorkFeedFilterPills } from '../WorkFeedFilterPills/WorkFeedFilterPills';
import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';

import { WorkFeedCards } from './WorkFeedCards';

export type WorkFeedBodyProps = {
  candidate: Candidate;
  currentView: WorkFeedViewsType;
  filters: Record<string, unknown>;
  pageNumber: number;
};
export const WorkFeedBody = ({
  candidate,
  currentView,
  filters,
  pageNumber,
}: WorkFeedBodyProps) => {
  const navigate = useNavigate();

  const getSearchFilters = () => {
    return {
      page: pageNumber,
      pageSize: 10,
      filters,
    };
  };

  const [search, saved, interested] = useApiWithErrors(
    WorkFeedApi.readWorkFeed(candidate.id, getSearchFilters()),
    WorkFeedApi.readWorkFeedSaved(candidate.id, {
      page: pageNumber,
      pageSize: 10,
    }),
    WorkFeedApi.readWorkFeedInterested(candidate.id, {
      page: pageNumber,
      pageSize: 10,
    })
  );

  const getCurrentOpportunities = () => {
    let currentOpportunities = search;
    if (WorkFeedUtil.isSavedView(currentView)) {
      currentOpportunities = saved;
    } else if (WorkFeedUtil.isInterestedView(currentView)) {
      currentOpportunities = interested;
    }

    return currentOpportunities;
  };

  useEffect(() => {
    const data = [search, saved, interested];
    if (data.some(res => res instanceof ApiError)) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i] instanceof ApiError) {
          const error = data[i] as ApiError;
          if (error.getStatusCode() === 403) {
            const failUrl = `../403`;
            navigate(failUrl, { replace: true });
            return;
          }
          throw data[i];
        }
      }
    } else {
      const currentOpportunities = getCurrentOpportunities();
      // navigate to last page if user goes over pageCount
      if (pageNumber > 1 && pageNumber > currentOpportunities.meta.pageCount) {
        const queryParams = window.location.search || '';
        const pageIndicator =
          currentOpportunities.meta.pageCount > 1
            ? `/page/${currentOpportunities.meta.pageCount}`
            : '';

        navigate(`.${pageIndicator}${queryParams}`, { replace: true });
      }
    }
  }, [search, saved, interested, pageNumber, currentView]);

  if ([search, saved, interested].some(res => res instanceof ApiError)) {
    return null;
  }

  return (
    <Tabs name="WORK_FEED_BODY" startTab={currentView}>
      <Tab
        name={WORK_FEED_VIEWS.all}
        label="All"
        onClick={() => navigate('../all')}
      >
        <Gutter bottom="16px" />
        <div data-test="WORK_FEED_FILTER_PILLS_WRAPPER">
          <WorkFeedFilterPills filters={filters} candidate={candidate} />
        </div>
        <WorkFeedCards
          workFeedItems={search.data}
          resultCount={search.meta?.resultCount}
          candidate={candidate}
          meta={search.meta}
          view={currentView}
        />
      </Tab>
      <Tab
        name={WORK_FEED_VIEWS.saved}
        label="Saved"
        countBadge={saved.meta?.resultCount}
        onClick={() => navigate('../saved')}
      >
        <Gutter bottom="16px" />
        <WorkFeedCards
          workFeedItems={saved.data}
          resultCount={saved.meta?.resultCount}
          candidate={candidate}
          meta={saved.meta}
          view={currentView}
        />
      </Tab>
      <Tab
        name={WORK_FEED_VIEWS.interested}
        label="Interested"
        countBadge={interested.meta?.resultCount}
        onClick={() => navigate('../interested')}
      >
        <Gutter bottom="16px" />
        <WorkFeedCards
          workFeedItems={interested.data}
          resultCount={interested.meta?.resultCount}
          candidate={candidate}
          meta={interested.meta}
          view={currentView}
        />
      </Tab>
    </Tabs>
  );
};
