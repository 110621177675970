import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { isIE } from '@axiom/ui/src/utils/detect-browser';

const QuickLinksListItems = ({ links }) => {
  const scrollToNode = (event, node) => {
    event.preventDefault();

    const headerHeight = document.querySelector('nav')?.offsetHeight || 10;
    const element = document.querySelector(`[name="${node}"]`);
    const top =
      element.getBoundingClientRect().top + window.pageYOffset - headerHeight;

    if (isIE()) {
      window.scrollTo(0, top);
    } else {
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  return links.map(link => (
    <li key={link.text}>
      <a
        href={`#${link.sectionName}`}
        onClick={e => scrollToNode(e, link.sectionName)}
      >
        {link.text}
      </a>
    </li>
  ));
};

QuickLinksListItems.propTypes = {
  links: arrayOf(
    shape({
      text: string.isRequired,
      sectionName: string.isRequired,
    })
  ).isRequired,
};

export default QuickLinksListItems;
