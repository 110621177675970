import ApiHelper from '../../../lib/api-helper';
import { CANDIDATE_LANGUAGES_API_NAME } from '../../constants';

const api = new ApiHelper(CANDIDATE_LANGUAGES_API_NAME);

export const normalizeCandidateLanguagesToApi = (languages, languagePayload) =>
  languagePayload.map(lang => ({
    languageId: languages.find(l => l.name === lang.name).id,
    languageSkill: lang.languageSkill || null,
    languageProficiency: lang.languageProficiency,
    languageCEFR: lang.languageCEFR || null,
  }));

export const getLanguagesByCandidateId = async candidateId => {
  const { data } = await api.GET(`/candidates/${candidateId}/languages`);
  return data;
};

export const putLanguagesByCandidateId = async (candidateId, languages) => {
  const { data } = await api.PUT(
    `/candidates/${candidateId}/languages`,
    languages
  );
  return data;
};
