import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardSection,
  ConfirmSubmitDialog,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  SmallHeader,
  ReadonlyTextarea,
  useApi,
  Banner,
  Grid,
  GridRow,
  GridColumn,
  SectionHeader,
  Flashy,
  Paragraph,
  CondensedSmall,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';
import { ProfileCompletionConst } from '@axiom/const';

import { isLPUser } from '../../utils/roles';
import { CandidateSummaryUtil } from '../../utils/candidate-summary-util';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { UserApi } from '../../api/protected/user/user';

import { ProfileSummaryConfirm } from './ProfileSummaryConfirm';
import { ProfileSummaryEdit } from './ProfileSummaryEdit';

export const ProfileSummary = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [
    hasConfirmationModalBeenAccepted,
    setHasConfirmationModalBeenAccepted,
  ] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [{ data: user }, { data: candidate }] = useApi(
    UserApi.readUser(),
    CandidateApi.readCandidate(candidateId)
  );
  const isSummaryApproved =
    !!candidate.candidateSummaryApprovedAt &&
    !!candidate.candidateSummaryApprovedBy;

  const candidateSummaryLengthEmpty =
    CandidateSummaryUtil.candidateSummaryLengthEmpty(
      candidate.candidateSummary
    );
  const candidateSummaryLengthTooShort =
    CandidateSummaryUtil.candidateSummaryLengthTooShort(
      candidate.candidateSummary
    );

  const handleEditClick = () => {
    if (isLPUser(user) && !hasConfirmationModalBeenAccepted) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsEditMode(true);
    }
  };

  const handleNoClick = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleYesClick = () => {
    setIsConfirmationModalOpen(false);
    setHasConfirmationModalBeenAccepted(true);
    setIsEditMode(true);
  };

  const handleModalClose = () => {
    setIsEditMode(false);
  };

  return (
    <>
      <Card name="PROFILE_SUMMARY">
        <CardHeader>
          <Layout position="middle">
            <LayoutItem fluid>
              <SmallHeader name="PENDO-PROFILE-SUMMARY">Summary</SmallHeader>
            </LayoutItem>
            {!isEditMode && (
              <IconButton
                icon="pencil"
                name="EDIT_PROFILE_SUMMARY_BUTTON"
                onClick={handleEditClick}
                pattern="secondary"
                variation="minimal"
              />
            )}
          </Layout>
        </CardHeader>
        <CardSection divider>
          {(candidateSummaryLengthEmpty || candidateSummaryLengthTooShort) && (
            <Gutter bottom="16px">
              <Banner name="PROFILE_SUMMARY_BANNER" type="info" impact="high">
                <Grid>
                  <GridRow>
                    <GridColumn smallScreenWidth={12} largeScreenWidth={9}>
                      <Layout horizontalGutter="8px" position="middle" wrap>
                        <LayoutItem>
                          <SectionHeader name="PROFILE_SUMMARY_BANNER_TITLE">
                            {candidateSummaryLengthEmpty &&
                              ProfileCompletionConst
                                .ProfileCompletionSummaryBanner.emptyTitle}
                            {candidateSummaryLengthTooShort &&
                              ProfileCompletionConst
                                .ProfileCompletionSummaryBanner.tooShortTitle}
                          </SectionHeader>
                        </LayoutItem>
                        <LayoutItem>
                          <CondensedSmall>
                            <Flashy color="textSubtle">|</Flashy>
                          </CondensedSmall>
                        </LayoutItem>
                        <LayoutItem>
                          <CondensedSmall>
                            <Flashy color="textSubtle">10 mins</Flashy>
                          </CondensedSmall>
                        </LayoutItem>
                      </Layout>
                      <Gutter bottom="8px" />
                      <Paragraph name="PROFILE_SUMMARY_BANNER_CONTENT">
                        {candidateSummaryLengthEmpty &&
                          ProfileCompletionConst.ProfileCompletionSummaryBanner
                            .emptyContent}
                        {candidateSummaryLengthTooShort &&
                          ProfileCompletionConst.ProfileCompletionSummaryBanner
                            .tooShortContent}
                      </Paragraph>
                      <Gutter bottom="16px" only="smallScreen" />
                    </GridColumn>
                    <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
                      <Layout position="right">
                        <LayoutItem>
                          <Button
                            pattern="primary"
                            name="EDIT_PROFILE_SUMMARY_BANNER_BUTTON"
                            onClick={handleEditClick}
                          >
                            Edit Now
                          </Button>
                        </LayoutItem>
                      </Layout>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Banner>
            </Gutter>
          )}
          {candidate.candidateSummary && (
            <Gutter bottom="16px">
              <Layout>
                <LayoutItem fluid>
                  <ReadonlyTextarea
                    name="candidateSummaryReadonly"
                    value={candidate.candidateSummary}
                  />
                </LayoutItem>
              </Layout>
            </Gutter>
          )}
          <ProfileSummaryConfirm
            candidateId={candidate.id}
            isSummaryApproved={isSummaryApproved}
            user={user}
          />
        </CardSection>
      </Card>
      {isEditMode && (
        <ProfileSummaryEdit
          candidate={candidate}
          onClose={() => {
            handleModalClose();
          }}
        />
      )}
      {isConfirmationModalOpen && !hasConfirmationModalBeenAccepted && (
        <ConfirmSubmitDialog
          name="EDIT_SUMMARY_CONFIRM_DIALOG"
          modalTitle="Edit Summary"
          modalBody="Axiom will review changes you make to this content before updating the profile we share with prospective clients."
          onNo={handleNoClick}
          onYes={handleYesClick}
          yesButtonText="Ok"
        />
      )}
    </>
  );
};
