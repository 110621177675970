import React, { useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { z, ZodRawShape } from 'zod';
import { TaxonomyConst } from '@axiom/const';
import {
  Candidate,
  CandidateLegalTechSkill,
  Taxonomy,
} from '@axiom/validation';
import {
  Accordion,
  AccordionHeader,
  Accordions,
  AccordionSection,
  Button,
  CondensedHeader,
  CondensedLarge,
  Form,
  Gutter,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ParagraphHeader,
  Radio,
  useBreakpoint,
} from '@axiom/ui';

import { CandidateLegalTechSkillsApi } from '../../api/protected/candidates/candidate-legal-tech-skills';

const { LegalTechProficiencies } = TaxonomyConst;

export const LegalTechSkillsEdit: React.FC<{
  candidateId: Candidate['id'];
  closeModal: () => void;
  legalTechSkills: CandidateLegalTechSkill[];
  taxonomy: Taxonomy;
}> = ({ candidateId, closeModal, legalTechSkills, taxonomy }) => {
  const { isMobile } = useBreakpoint();
  const orderedLegalTechSkillsStructure = useMemo(() => {
    return orderBy(
      Object.entries(taxonomy.legalTechSkillCategories).map(
        ([key, { displayName }]) => ({
          key,
          displayName,
          sort: key.toLowerCase(),
          skills: orderBy(
            Object.entries(taxonomy.legalTechSkills)
              .filter(([, skill]) => skill.legalTechSkillCategory === key)
              .map(([skillKey, skill]) => ({
                key: skillKey,
                sort: skillKey.toLowerCase(),
                ...skill,
              })),
            ['sort']
          ),
        })
      ),
      ['sort']
    );
  }, [taxonomy]);

  const returnNestedMapping = (
    dataOrFunc: unknown | ((skill: string, category: string) => unknown)
  ) =>
    orderedLegalTechSkillsStructure.reduce(
      (schema, { key, skills }) => ({
        ...schema,
        [key]: skills.reduce(
          (acc, skill) => ({
            ...acc,
            [skill.key]:
              typeof dataOrFunc === 'function'
                ? dataOrFunc(skill.key, key)
                : dataOrFunc,
          }),
          {}
        ),
      }),
      {}
    );

  const initialValues = useMemo(
    () =>
      returnNestedMapping(
        (skill: string, category: string) =>
          legalTechSkills.find(
            ({ legalTechSkill, legalTechCategory }) =>
              legalTechSkill === skill && legalTechCategory === category
          )?.proficiency ?? TaxonomyConst.LegalTechProficiencies.None
      ),
    [orderedLegalTechSkillsStructure, legalTechSkills]
  );
  const formSchema = useMemo(
    () =>
      z.object(
        Object.fromEntries(
          Object.entries(returnNestedMapping(z.string())).map(
            ([key, value]) => [key, z.object(value as ZodRawShape)]
          )
        )
      ),
    [orderedLegalTechSkillsStructure]
  );
  const candidateLegalTechSkillsLookup = useMemo(
    () =>
      legalTechSkills.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.legalTechCategory]: {
            ...acc[curr.legalTechCategory],
            [curr.legalTechSkill]: curr,
          },
        }),
        {} as Record<string, Record<string, CandidateLegalTechSkill>>
      ),
    [legalTechSkills]
  );
  const [openAccordion, setOpenAccordion] = useState<string>();
  return (
    <Form
      name="LEGAL_TECH_SKILLS_EDIT_FORM"
      schema={formSchema}
      initialValues={initialValues}
      onSubmit={async changedData => {
        const [updates, deletes] = Object.entries({
          ...initialValues,
          ...changedData,
        }).reduce(
          ([updateList, deleteList], [legalTechCategory, skills]) => {
            Object.entries(skills).forEach(([legalTechSkill, proficiency]) => {
              if (proficiency !== LegalTechProficiencies.None) {
                // we PUT all values that are not none
                updateList.push({
                  legalTechCategory,
                  legalTechSkill,
                  proficiency,
                });
              } else if (
                candidateLegalTechSkillsLookup[legalTechCategory]?.[
                  legalTechSkill
                ]?.id
              ) {
                deleteList.push(
                  candidateLegalTechSkillsLookup[legalTechCategory][
                    legalTechSkill
                  ].id
                );
              }
            });
            return [updateList, deleteList];
          },
          [
            [] as CandidateLegalTechSkill[],
            [] as CandidateLegalTechSkill['id'][],
          ]
        );
        if (deletes.length > 0) {
          await CandidateLegalTechSkillsApi.deleteLegalTechSkills(
            candidateId,
            deletes
          );
        }
        // if there is an actual update to make, i.e. not a noop for skills with proficiencies
        if (
          updates.some(
            ({ legalTechSkill, legalTechCategory, proficiency }) =>
              // either a candidate skill record does not exist, so we need to create
              !candidateLegalTechSkillsLookup[legalTechCategory]?.[
                legalTechSkill
              ]?.proficiency ||
              // or it does exist with a different proficiency, so we need to update
              candidateLegalTechSkillsLookup[legalTechCategory][legalTechSkill]
                .proficiency !== proficiency
          )
        ) {
          await CandidateLegalTechSkillsApi.updateLegalTechSkills(
            candidateId,
            updates
          );
        }
        if (Object.keys(changedData).length) {
          await CandidateLegalTechSkillsApi.refreshLegalTechSkills(candidateId);
        }
        closeModal();
      }}
    >
      {({ fireSubmit }) => (
        <Modal name="LEGAL_TECH_SKILLS_MODAL">
          <ModalHeader
            onClose={() => closeModal()}
            name="LEGAL_TECH_SKILLS_MODAL_HEADER"
          >
            Legal Tech Skills
          </ModalHeader>
          <ModalSection>
            <ParagraphHeader>
              Select your proficiency level for each of the following Legal Tech
              skills.
            </ParagraphHeader>
            <Gutter bottom="16px" />
            <Accordions name="LEGAL_TECH_SKILLS_ACCORDIONS">
              {orderedLegalTechSkillsStructure.map(
                ({
                  key: categoryKey,
                  displayName: categoryDisplayName,
                  skills,
                }) => (
                  <Accordion
                    key={categoryKey}
                    name={`${categoryKey}_accordion`}
                    expanded={openAccordion === categoryKey}
                  >
                    <AccordionHeader
                      onToggle={value => {
                        setOpenAccordion(value ? categoryKey : null);
                      }}
                    >
                      <CondensedHeader name="LEGAL_TECH_SKILL_CATEGORY_NAME_EDIT">
                        {categoryDisplayName}
                      </CondensedHeader>
                    </AccordionHeader>
                    {skills.map(({ displayName, key }) => (
                      <AccordionSection key={`${categoryKey}.${key}`}>
                        <Gutter bottom="24px" />
                        <Layout
                          position="space-between"
                          stackableOn="mobile"
                          verticalGutter="8px"
                        >
                          <LayoutItem fluid>
                            <CondensedLarge name="LEGAL_TECH_SKILL_SKILL_NAME">
                              {displayName}
                            </CondensedLarge>
                          </LayoutItem>
                          <LayoutItem>
                            <Layout
                              horizontalGutter={isMobile ? '8px' : '24px'}
                            >
                              <Radio
                                name={`${categoryKey}.${key}`}
                                option={LegalTechProficiencies.None}
                                displayValue="None"
                              />
                              <Radio
                                name={`${categoryKey}.${key}`}
                                option={LegalTechProficiencies.SomeFamiliarity}
                                displayValue="Some"
                              />
                              <Radio
                                name={`${categoryKey}.${key}`}
                                option={LegalTechProficiencies.HighProficiency}
                                displayValue="High"
                              />
                            </Layout>
                          </LayoutItem>
                        </Layout>
                      </AccordionSection>
                    ))}
                  </Accordion>
                )
              )}
            </Accordions>
          </ModalSection>
          <ModalFooter>
            <Button
              variation="outline"
              onClick={() => closeModal()}
              name="PENDO_LEGAL_TECH_SKILLS_EDIT_CANCEL"
            >
              Cancel
            </Button>
            <Button
              onClick={fireSubmit}
              name="PENDO_LEGAL_TECH_SKILLS_EDIT_SAVE"
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};
