import React from 'react';
import { EventProps } from 'react-big-calendar';
import { CalendarComponentEvent } from '@axiom/validation';

import { Flashy } from '../Flashy/Flashy';
import { CondensedSmall } from '../../content/CondensedSmall/CondensedSmall';

type CalendarEventPropType = Omit<EventProps, 'event'> & {
  event: CalendarComponentEvent;
};

export const CalendarEvent = ({ event }: CalendarEventPropType) => {
  return (
    <div
      data-test={event.name ?? 'EVENT'}
      className={event.error ? 'error' : ''}
    >
      <CondensedSmall name="EVENT_HEADER">
        {event.error ? (
          <Flashy color="textAlert">{event.error}</Flashy>
        ) : (
          event.title
        )}
      </CondensedSmall>
    </div>
  );
};
