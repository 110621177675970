import { Submission } from '@axiom/validation';

import { StringUtil } from './string-util';

export const getSubmissionFileName = (submission: Submission) => {
  const { jobName, submissionLastUpdatedAt } = submission;
  const date = new Date(submissionLastUpdatedAt).toISOString().slice(0, 10);
  return `Axiom Submission_${StringUtil.cleanStringForFileName(
    jobName
  )}_${date}.pdf`;
};
