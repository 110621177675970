/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { string, arrayOf, oneOfType, shape, func, bool, any } from 'prop-types';
import { ComboBox as KendoComboBox } from '@progress/kendo-react-dropdowns';

import { KendoErrorWrapper } from '../../themes';

import { ComboBoxWrapper } from './ComboBoxStyles';

class ComboBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  handleChange = event => {
    this.setState({
      value: event.target.value,
    });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  // The Kendo DropDown component doesn't support opening on focus,
  // so we mimic clicking the caret icon when the input is clicked.
  // This relies on the internal markup of the Kendo component.
  handleWrapperClick = event => {
    if (event.target.tagName !== 'INPUT') return;
    const wrapper = event.target.closest('.k-dropdown-wrap');
    const caret = wrapper && wrapper.querySelector('.k-select');
    if (caret) caret.click();
  };

  render() {
    const {
      handleChange,
      handleWrapperClick,
      props,
      state: { value },
    } = this;

    return (
      <KendoErrorWrapper invalid={props.invalid}>
        <ComboBoxWrapper onClick={handleWrapperClick}>
          <KendoComboBox
            className={props.invalid ? 'form-control is-invalid' : ''}
            {...props}
            onBlur={this.props.onBlur}
            onChange={handleChange}
            value={value}
            popupSettings={{ animate: false }}
          />
        </ComboBoxWrapper>
      </KendoErrorWrapper>
    );
  }
}

ComboBox.defaultProps = {
  data: [],
  error: '',
  invalid: false,
  onBlur: null,
  onChange: () => {},
  value: '',
};

ComboBox.propTypes = {
  data: arrayOf(oneOfType([string, shape({})])),
  error: string,
  invalid: bool,
  onBlur: func,
  onChange: func,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

export default ComboBox;
