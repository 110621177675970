import { shape, arrayOf, string } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import { getLanguages } from '../../api/protected/languages/lanugages';

export const LANGUAGES_NAMESPACE = 'LANGUAGES';
const stateLanguages = new CollectionStateHelper(LANGUAGES_NAMESPACE, 'FETCH');
export const LanguagesStore = {
  getReducers() {
    return {
      ...stateLanguages.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateLanguages.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
      })
    );
  },
  actionSetLanguages(languages) {
    return stateLanguages.setCollection(languages);
  },
  actionFetchLanguages() {
    return stateLanguages.fetchCollection(getLanguages());
  },
  selectLanguages(state) {
    return stateLanguages.selectData(state);
  },
};
