import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import { Dropdown as SDropdown } from 'semantic-ui-react';

import { Badge } from '../../element/Badge/Badge';
import { IconButton } from '../../element/Button/IconButton';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Icon } from '../../element/Icon/Icon';

export type RawDropdownTreeValueType =
  | string
  | number
  | null
  | Array<string | number>;

type FormattedDropdownOption = {
  key: string;
  parentId?: string;
  text: string;
  value: string;
  hasChildren: boolean;
};

export type RawDropdownTreeProps = {
  displayKey: string;
  disabled?: boolean;
  hideIcon?: boolean;
  id?: string;
  invalid?: boolean;
  multiple?: boolean;
  name: string;
  nestedKey: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (value: RawDropdownTreeValueType) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onType?: (value: RawDropdownTreeValueType) => void;
  options: Array<Record<string, unknown>>;
  placeholder?: string;
  value?: RawDropdownTreeValueType;
  valueKey: string;
};

export const RawDropdownTree = ({
  displayKey,
  disabled = false,
  hideIcon,
  id,
  invalid = false,
  multiple = false,
  name,
  nestedKey,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  value,
  valueKey,
}: RawDropdownTreeProps) => {
  const [expandedIds, setExpandedIds] = useState(new Set());
  const [searchValue, setSearchValue] = useState('');
  const dropdownOptions = useMemo(() => {
    const formatOptions = (
      acc: Array<FormattedDropdownOption>,
      o: Record<string, string>,
      parentOption?: FormattedDropdownOption
    ) => {
      const display = get(o, displayKey);
      const v = get(o, valueKey);
      if (display) {
        const nestedOption =
          nestedKey &&
          (get(o, nestedKey) as unknown as Array<Record<string, string>>);

        const hasNestedOptions = nestedOption && Array.isArray(nestedOption);
        const parentId = parentOption?.value;
        const formattedOption = {
          key: v,
          value: v,
          text: display,
          parentId,
          searchText: `${
            parentOption?.text ? `${parentOption.text} -> ` : ''
          }${display}`,
          hasChildren: hasNestedOptions,
          disableSelection: hasNestedOptions && !multiple,
        };
        acc.push(formattedOption);

        if (hasNestedOptions) {
          nestedOption.forEach(nestedO =>
            formatOptions(acc, nestedO, formattedOption)
          );
        }
      }

      return acc;
    };
    return options.reduce((acc, option) => {
      return formatOptions(acc, option as Record<string, string>);
    }, []);
  }, [options]);

  if (multiple && (value === undefined || value === null)) {
    value = [];
  }

  const getAllChildrenAndSelectedChildrenOfParent = (
    parentId: string | number,
    optionValues: RawDropdownTreeValueType
  ) => {
    if (!Array.isArray(optionValues)) {
      optionValues = [optionValues];
    }
    const totalChildrenOfParent = dropdownOptions.filter(
      opt => opt.parentId === parentId
    );

    const selectedChildrenOfParent = totalChildrenOfParent.filter(child => {
      return Array.isArray(optionValues) && optionValues.includes(child.value);
    });

    return [selectedChildrenOfParent, totalChildrenOfParent];
  };

  const displayOptions = dropdownOptions.map(opt => {
    const [selectedChildrenOfParent, totalChildrenOfParent] =
      getAllChildrenAndSelectedChildrenOfParent(opt.value, value);
    const childItemShouldHide = opt.parentId && !expandedIds.has(opt.parentId);
    const parentItemShouldHide =
      selectedChildrenOfParent.length > 0 &&
      selectedChildrenOfParent.length === totalChildrenOfParent.length;

    return {
      className: AttrsHelper.formatClassname(
        (childItemShouldHide || parentItemShouldHide) && !searchValue
          ? 'hide-option'
          : '',
        `value-${opt.value}`
      ),
      text: opt.text,
      key: opt.key,
      value: opt.value,
      content: searchValue ? (
        <div>
          <Layout position="middle" data-test={`option-${opt.searchText}`}>
            <LayoutItem>{opt.searchText}</LayoutItem>
          </Layout>
        </div>
      ) : (
        <Layout>
          <LayoutItem fluid>
            <div className={AttrsHelper.formatClassname(opt.parentId)}>
              <Layout position="middle">
                {opt.hasChildren && (
                  <LayoutItem rightGutter="16px" topGutter="2px">
                    <span
                      data-test="expand-item"
                      onClick={e => {
                        e.stopPropagation();

                        if (expandedIds.has(opt.value)) {
                          expandedIds.delete(opt.value);
                        } else {
                          expandedIds.add(opt.value);
                        }
                        setExpandedIds(new Set([...expandedIds]));
                      }}
                    >
                      <Icon
                        name={
                          expandedIds.has(opt.value)
                            ? 'arrow-down'
                            : 'arrow-right'
                        }
                      />
                    </span>
                  </LayoutItem>
                )}
                <div
                  style={{
                    width: '100%',
                    cursor: opt.disableSelection ? 'default' : 'pointer',
                  }}
                  onClick={e => {
                    if (opt.disableSelection) e.stopPropagation();
                  }}
                >
                  <Layout position="middle" direction="horizontal">
                    <LayoutItem fluid leftGutter={opt.parentId && '60px'}>
                      {opt.text}
                    </LayoutItem>
                    {selectedChildrenOfParent.length > 0 && !multiple && (
                      <LayoutItem position="right">
                        <Badge background="blue" name="selected-children">
                          {selectedChildrenOfParent.length}
                        </Badge>
                      </LayoutItem>
                    )}
                  </Layout>
                </div>
              </Layout>
            </div>
          </LayoutItem>
        </Layout>
      ),
    };
  });
  const hasValue = multiple
    ? (value as Array<unknown>)?.length > 0
    : !!value || value === 0;

  const changeValue = (optionValue: RawDropdownTreeValueType) => {
    optionValue = Array.isArray(optionValue)
      ? [...new Set(optionValue)]
      : optionValue;
    onChange(optionValue);
    setSearchValue('');
  };

  return (
    <div data-test={name}>
      <SDropdown
        disabled={disabled}
        error={invalid}
        fluid
        icon={
          !hasValue &&
          !hideIcon && (
            <IconButton
              pattern="secondary"
              variation="minimal"
              icon="arrow-down"
              name="default-dropdown-arrow"
              className="control-icon fixed-dimension-inline"
            />
          )
        }
        id={id}
        multiple={multiple}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          onBlur(e);
          setSearchValue('');
        }}
        onChange={(
          _e,
          { value: optionValue }: { value: RawDropdownTreeValueType }
        ) => {
          /**
           * For multiple options, we need to check if it has a new option and make sure
           * that if the option is a parent, that we replace it with all it's children
           * For single options, we need to make sure the parent was not selected
           */
          if (multiple) {
            if (
              Array.isArray(optionValue) &&
              Array.isArray(value) &&
              optionValue.length > value.length
            ) {
              const newlyAddedId = optionValue.find(
                optId => Array.isArray(value) && !value.includes(optId)
              );

              const newOption = dropdownOptions.find(
                opt => opt.value === newlyAddedId
              );

              if (newOption.parentId) {
                changeValue(optionValue);
              } else {
                const [selectedChildrenOfParent, totalChildrenOfParent] =
                  getAllChildrenAndSelectedChildrenOfParent(
                    newlyAddedId,
                    optionValue
                  );
                // Check if all children are selected for a parent. If so, remove all, else add all

                let newOptions = optionValue.filter(
                  optId => optId !== newlyAddedId
                );

                if (
                  selectedChildrenOfParent.length ===
                  totalChildrenOfParent.length
                ) {
                  newOptions = newOptions.filter(
                    optValue =>
                      !totalChildrenOfParent.find(
                        childOpt => childOpt.value === optValue
                      )
                  );
                } else {
                  newOptions = newOptions.concat(
                    totalChildrenOfParent.map(opt => opt.value)
                  );
                }

                changeValue(newOptions);
              }
            } else {
              changeValue(optionValue);
            }
          } else if (optionValue) {
            const foundOption = dropdownOptions.find(
              opt => opt.value === optionValue
            );

            if (foundOption.parentId) {
              changeValue(optionValue);
            }
          } else {
            changeValue(optionValue);
          }
        }}
        onFocus={onFocus}
        onSearchChange={(e, data) => {
          setSearchValue(data.searchQuery);
        }}
        options={displayOptions}
        placeholder={placeholder}
        renderLabel={({ text }) => {
          // TODO: remove this in Semantic v3
          // This maintains compatibility with Shorthand API in v1 as this might be called in "Label.create()"
          if (typeof text === 'function') {
            return text;
          }

          return {
            content: (
              <>
                {text}
                <span
                  className="pill-control-icon"
                  onClick={e => {
                    e.stopPropagation();
                    const foundPillItem = dropdownOptions.find(
                      op => op.text === text
                    );
                    onChange(
                      (value as Array<string>).filter(
                        v => v !== foundPillItem?.value
                      )
                    );
                  }}
                >
                  <Icon name="x-mark" />
                </span>
              </>
            ),
          };
        }}
        search
        searchQuery={searchValue}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        scrolling
        value={value}
      />
    </div>
  );
};
