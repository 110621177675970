import Ajv from 'ajv';
import { z } from 'zod';
import {
  PositionCalculatedTalentType,
  PositionCalculatedTalentTypes,
  PositionsConst,
  TalentType,
} from '@axiom/const';
import { SchemaDate, SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { addCustomKeywords } from './keywords';
import { TagSchema } from './tag';

const ReservedTypeValues = Object.values(PositionsConst.ReservedType).concat([
  null,
]) as NonEmptyArray<string>;
const EndDateStatusValues = [
  'Confirmed',
  'Not Confirmed',
  null,
] as NonEmptyArray<string>;

const PositionCalculatedTalentTypeValues = Object.values(
  PositionCalculatedTalentTypes
) as NonEmptyArray<PositionCalculatedTalentType>;

const [firstRole, ...otherRoles] = Object.values(PositionsConst.Roles);

export const PositionSchema = z.object({
  aiDescription: z.string().nullish(),
  baselineBillRate: z.number().nullable(),
  baselineCost: z.number().nullable(),
  baselineMargin: z.number().nullable(),
  billingHoursPerDay: z.number().nullable(),
  billingHoursPerWeek: z.number().nullable(),
  billingUnitsPerWeek: z.number().nullable(),
  calculatedTalentType: z.enum(PositionCalculatedTalentTypeValues).nullable(),
  createdAt: SchemaTimestamp.nullable(),
  demandDriver: z.string().max(255).nullable(),
  description: z.string().nullable(),
  endDate: SchemaDate.nullable(),
  endDateStatus: z.enum(EndDateStatusValues).nullable(),
  estimatedEngagementTotalMonths: z.number().nullable(),
  id: z.string().uuid(),
  integrationId: z.string().max(255).nullable(),
  isPremium: z.boolean().nullish(),
  name: z.string().max(255).nullable(),
  opportunityId: z.string().uuid().nullable(),
  otherCandidatesStatusOnPosition: z
    .array(
      z.object({
        status: z.string().nullable(),
        candidateName: z.string().nullable(),
      })
    )
    .nullable(),
  product: z.string().max(255).nullable(),
  reservedType: z.enum(ReservedTypeValues).nullable(),
  role: z.enum([firstRole, ...otherRoles]).nullable(),
  salesforceId: z.string().max(255).nullable(),
  startDate: SchemaDate.nullable(),
  talentExperienceYears: z.string().max(255).nullable(),
  talentType: z
    .enum(
      Object.values(PositionsConst.TalentTypes) as NonEmptyArray<TalentType>
    )
    .nullable(),
  tags: z.array(TagSchema).nullish(),
  updatedAt: SchemaTimestamp.nullable(),
  weeklyTimeUnit: z.string().max(255).nullable(),
  worksite: z.string().max(255).nullable(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

// add parseFloat support
addCustomKeywords(ajv);

const positionCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    opportunityId: {
      type: 'string',
      format: 'uuid',
    },
    name: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    product: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    role: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    startDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    endDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    baselineMargin: {
      type: ['number', 'null'],
      maximum: Number.MAX_SAFE_INTEGER,
    },
    baselineBillRate: {
      type: ['number', 'null'],
      maximum: Number.MAX_SAFE_INTEGER,
    },
    baselineCost: {
      type: ['number', 'null'],
      maximum: Number.MAX_SAFE_INTEGER,
    },
    demandDriver: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    billingUnitsPerWeek: {
      // TODO: remove string as a type once Boomi is sending as numeric
      type: ['number', 'string', 'null'],
      parseFloat: {
        type: 'number',
      },
    },
    aiDescription: {
      type: ['string', 'null'],
    },
    description: {
      type: ['string', 'null'],
    },
    talentExperienceYears: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    talentType: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    worksite: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    salesforceId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    weeklyTimeUnit: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    billingHoursPerDay: {
      type: ['number', 'null'],
      maximum: 24,
    },
    reservedType: {
      type: ['string', 'null'],
      enum: ReservedTypeValues,
    },
    integrationId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    isPremium: {
      type: ['boolean'],
    },
    endDateStatus: {
      type: ['string', 'null'],
      enum: EndDateStatusValues,
    },
  },
};

export const upsertPositionValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...positionCommonValidation,
    ...additionalOpts,
    anyOf: Object.keys(positionCommonValidation.properties).map(key => ({
      required: [key],
    })),
  });

export const upsertPositionValidator = upsertPositionValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const upsertPositionAdditionalPropertiesValidator =
  upsertPositionValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const filterPositionValidator = ajv.compile({
  ...positionCommonValidation,
  anyOf: Object.keys(positionCommonValidation.properties).map(key => ({
    required: [key],
  })),
});

export const positionsStatsFilterValidator = ajv.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    ...positionCommonValidation.properties,
    startDate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        start: {
          type: 'string',
          format: 'date',
        },
        end: {
          type: 'string',
          format: 'date',
        },
      },
    },
    'opportunity.practiceAreaId': {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
    'opportunity.stageCode': {
      type: 'array',
      items: {
        type: ['string', 'null'],
        maxLength: 255,
      },
    },
  },
});
