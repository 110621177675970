import React from 'react';
import { Gutter, Header5 } from '@axiom/ui';

const SharedBioDownloadMessage = () => (
  <Gutter left="0.5rem">
    <Header5>
      Sharing this bio with a client? Click the download button below. Clients
      can’t open bio links.
    </Header5>
  </Gutter>
);

export default SharedBioDownloadMessage;
