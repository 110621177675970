import { ProfileCompletionConst } from '@axiom/const';

export const CandidateSummaryUtil = {
  candidateSummaryLengthEmpty: (candidateSummary: string) => {
    return (candidateSummary?.length || 0) === 0;
  },
  candidateSummaryLengthTooShort: (candidateSummary: string) => {
    const candidateSummaryLength = candidateSummary?.length || 0;

    return (
      candidateSummaryLength !== 0 &&
      candidateSummaryLength > 0 &&
      candidateSummaryLength <
        ProfileCompletionConst.minimumAcceptableSummaryLength
    );
  },
};
