import { z } from 'zod';

const TaxonomyIndustrySchema = z.object({
  displayValue: z.string(),
});
const TaxonomyLegalTechCategorySchema = z.object({
  displayName: z.string(),
});
export const TaxonomyLegalTechSkillSchema = z.object({
  displayName: z.string(),
  legalTechSkillCategory: z.string(),
});

export const TaxonomySchema = z.object({
  industries: z.record(z.string(), TaxonomyIndustrySchema),
  legalTechSkillCategories: z.record(
    z.string(),
    TaxonomyLegalTechCategorySchema
  ),
  legalTechSkills: z.record(z.string(), TaxonomyLegalTechSkillSchema),
});
