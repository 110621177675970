import React, { useContext } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { LayoutItem } from '../Layout/LayoutItem';
import { Layout } from '../Layout/Layout';
import { IconButton } from '../../element/Button/IconButton';

import { AccordionContext } from './AccordionContext';

export type AccordionHeaderProps = {
  children: React.ReactNode;
  className?: string;
  name?: string;
  onToggle?: (expand: boolean) => void;
};
export const AccordionHeader = ({
  children,
  className,
  name,
  onToggle,
}: AccordionHeaderProps) => {
  const { expanded, toggle } = useContext(AccordionContext);
  const handleClick = () => {
    onToggle?.(!expanded);
    toggle(!expanded);
  };
  return (
    <div
      className={AttrsHelper.formatClassname('accordion-header', className)}
      data-test={name}
      onClick={handleClick}
    >
      <Layout position="middle">
        <LayoutItem fluid>{children}</LayoutItem>

        <IconButton
          className="fixed-dimension-inline"
          name="expand-icon"
          pattern="secondary"
          variation="minimal"
          icon={expanded ? 'arrow-down' : 'arrow-right'}
          onClick={handleClick}
        />
      </Layout>
    </div>
  );
};
