import React from 'react';
import {
  useApi,
  Grid,
  GridColumn,
  Card,
  CardSection,
  CardHeader,
  SmallHeader,
  Form,
  FormGroup,
  Gutter,
  Radio,
  Flashy,
  Layout,
  Toggle,
  Suspense,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { CandidateSchema } from '@axiom/validation';

import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { WorkFeedAlerts } from '../WorkFeedAlerts/WorkFeedAlerts';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { UserApi } from '../../api/protected/user/user';
import { CandidateUtil } from '../../utils/candidate-util';

const CommunicationPreferencesSchema = CandidateSchema.pick({
  preferredContactMethod: true,
});

const CommunicationPreferencesAlertsSchema = CandidateSchema.pick({
  isOptInWorkFeedEmail: true,
});

export const CommunicationPreferencesView = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));

  const {
    preferredContactMethod,
    workEmail,
    personalEmail,
    isAccessibleToWorkFeed,
    isOptInWorkFeedEmail,
  } = candidate;

  return (
    <Grid name="COMMUNICATION_PREFERENCES">
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <PreferencesNavigation />
        <Gutter only="smallScreen" bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        <Layout direction="vertical" verticalGutter="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Communications</SmallHeader>
            </CardHeader>
            <CardSection>
              <Form
                schema={CommunicationPreferencesSchema}
                onSubmit={formData => {
                  return CandidateApi.updateCandidate(
                    candidateId,
                    CandidateSchema.partial().parse(formData)
                  ).then(() => {
                    return CandidateApi.refreshCandidate(candidateId);
                  });
                }}
                initialValues={{
                  preferredContactMethod,
                }}
                submitOnChange
              >
                {() => (
                  <FormGroup
                    name="preferredContactMethod"
                    label="Preferred Email"
                    description="Where would you like us to send notifications about new opportunities or things that need your attention?"
                  >
                    <Layout direction="vertical" verticalGutter="8px">
                      {workEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Axiom"
                          displayValue={workEmail}
                        />
                      )}
                      {personalEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Personal"
                          displayValue={personalEmail}
                        />
                      )}
                      {workEmail && personalEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Both"
                          displayValue="Both"
                        />
                      )}
                      {!workEmail && !personalEmail && (
                        <Flashy name="NO_EMAIL_WARNING" bold color="textAlert">
                          Please reach out to your Axiom contact to set your
                          email.
                        </Flashy>
                      )}
                    </Layout>
                  </FormGroup>
                )}
              </Form>
            </CardSection>
          </Card>
          {isAccessibleToWorkFeed && (
            <Card name="ALERTS_PREFERENCES_CARD">
              <CardHeader>
                <SmallHeader>Alerts</SmallHeader>
              </CardHeader>
              <CardSection divider>
                <Form
                  schema={CommunicationPreferencesAlertsSchema}
                  onSubmit={formData => {
                    return CandidateApi.updateCandidate(
                      candidateId,
                      CandidateSchema.partial().parse(formData)
                    ).then(() => {
                      return CandidateApi.refreshCandidate(candidateId);
                    });
                  }}
                  initialValues={{
                    isOptInWorkFeedEmail: !!isOptInWorkFeedEmail,
                  }}
                  submitOnChange
                >
                  {() => (
                    <Toggle
                      name="isOptInWorkFeedEmail"
                      label="Alert Subscriptions"
                      description="I'd like to receive opportunity alerts via email."
                    />
                  )}
                </Form>
              </CardSection>
              <CardSection>
                <Suspense>
                  <WorkFeedAlerts candidateId={candidateId} />
                </Suspense>
              </CardSection>
            </Card>
          )}
        </Layout>
      </GridColumn>
    </Grid>
  );
};
