import React, { useState } from 'react';

import DefaultCompanyLogo from '../../../public/static/images/default-company-logo.png';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type CompanyLogoProps = {
  alt?: string;
  className?: string;
  inverse?: boolean;
  monochromatic?: boolean;
  name?: string;
  src?: string;
};

export const CompanyLogo = ({
  alt = 'Company Logo',
  className,
  inverse,
  monochromatic,
  name,
  src,
}: CompanyLogoProps) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(null);

  const dimension = '75px';
  return (
    <>
      {src && imageLoadFailed !== true && (
        <img
          alt={alt}
          src={src}
          onError={() => setImageLoadFailed(true)}
          width={dimension}
          data-test={name}
          className={AttrsHelper.formatClassname(
            'company-logo',
            monochromatic && 'image-monochromatic',
            inverse && 'image-inverse',
            className
          )}
        />
      )}
      {(!src || imageLoadFailed === true) && (
        <img
          src={DefaultCompanyLogo}
          alt={alt}
          width={dimension}
          height={dimension}
          data-test={name}
          className={AttrsHelper.formatClassname(
            'company-logo',
            monochromatic && 'image-monochromatic',
            inverse && 'image-inverse',
            className
          )}
        />
      )}
    </>
  );
};
