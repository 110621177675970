import { ProfileCompletionConst } from '@axiom/const';

const { ProfileCompletionCriteria } = ProfileCompletionConst;

export const ProfileCompletionUtil = {
  getActionsNeededCTA: {
    [ProfileCompletionCriteria.needsPracticeStartYear]: {
      ctaTitle: 'preferences.',
      ctaLink: '../preferences/profile',
    },
  },
};
