// eslint-disable-next-line import/prefer-default-export
export const TagConst = {
  TAG_TYPES: {
    Tag: 'tag',
    Skill: 'skill',
    Industry: 'industry',
    SubIndustry: 'subIndustry',
    LawSchoolRank: 'lawSchoolRank',
  },
};
