import React from 'react';
import { shape, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import {
  Banner,
  Card,
  CardHeader,
  CardSection,
  Drawer,
  DrawerHeader,
  DrawerSection,
  Gutter,
  SmallHeader,
} from '@axiom/ui';

import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { OpportunityUtil } from '../../utils/opportunity-util';

import { ActiveSubmissionItem } from './ActiveSubmissionItem';
import { HistorySubmissionItem } from './HistorySubmissionItem';

const shouldShowActiveView = candidateStatus =>
  CandidateOpportunitiesStore.getActiveStatuses().indexOf(candidateStatus) > -1;

const SubmissionComponent = ({
  candidate,
  activeSubmissions,
  historySubmissions,
  opportunitiesState,
  practiceAreas,
}) => {
  return (
    <div data-test="SUBMISSIONS">
      <Card>
        <CardHeader>
          <SmallHeader>Active Submissions</SmallHeader>
        </CardHeader>

        <StoreStateTreatment
          name="SUBMISSIONS_TREATMENT"
          storeState={opportunitiesState}
          renderLoadedView={() => (
            <>
              {activeSubmissions.map(item => (
                <CardSection divider key={item.id}>
                  {shouldShowActiveView(item.candidateStatus) ? (
                    <ActiveSubmissionItem
                      candidate={candidate}
                      opportunity={item}
                      practiceAreas={practiceAreas.raw}
                    />
                  ) : (
                    <HistorySubmissionItem
                      opportunity={OpportunityUtil.transformDisplayOpportunity(
                        candidate,
                        item
                      )}
                      practiceAreas={practiceAreas}
                    />
                  )}
                </CardSection>
              ))}
              {activeSubmissions.length === 0 && (
                <CardSection>
                  <Banner type="info" impact="low">
                    You have no active submissions to track at this time. We'll
                    reach out when we have new opportunities for you to
                    consider. Please make sure your profile is up to date.
                  </Banner>
                </CardSection>
              )}
            </>
          )}
        />
      </Card>
      <Gutter bottom="24px" />
      {historySubmissions.length > 0 && (
        <Drawer>
          <DrawerHeader>
            <SmallHeader>Submission History</SmallHeader>
          </DrawerHeader>
          {historySubmissions.map(item => (
            <DrawerSection key={item.id} divider>
              <HistorySubmissionItem
                opportunity={OpportunityUtil.transformDisplayOpportunity(
                  candidate,
                  item
                )}
                practiceAreas={practiceAreas}
              />
            </DrawerSection>
          ))}
          {historySubmissions.length === 0 && (
            <DrawerSection>
              <Banner type="info" impact="low">
                There's no submission history to show at this time.
              </Banner>
            </DrawerSection>
          )}
        </Drawer>
      )}
    </div>
  );
};

SubmissionComponent.defaultProps = {
  activeSubmissions: [],
  historySubmissions: [],
};

SubmissionComponent.propTypes = {
  candidate: shape({}).isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  opportunitiesState: CandidateOpportunitiesStore.getStateShape().isRequired,
  activeSubmissions: arrayOf(shape({})),
  historySubmissions: arrayOf(shape({})),
};

export const Submissions = connect(state => ({
  candidate: CurrentCandidateStore.selectCandidate(state),
  opportunitiesState: CandidateOpportunitiesStore.select(state),
  activeSubmissions:
    CandidateOpportunitiesStore.selectActiveOpportunities(state),
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
  historySubmissions:
    CandidateOpportunitiesStore.selectHistoricOpportunities(state),
}))(SubmissionComponent);
