import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useBreakpoint } from '../../../hooks/use-breakpoint';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { AxiomLogo } from '../../element/AxiomLogo/AxiomLogo';
import { LayoutItem } from '../Layout/LayoutItem';
import { Layout } from '../Layout/Layout';
import { LayoutBleed } from '../LayoutBleed/LayoutBleed';
import { Visible } from '../Visible/Visible';

import {
  HeaderBlock,
  HeaderMenuBlock,
  HeaderMobileMenuButton,
} from './HeaderBarStyles';

export const HeaderBar = ({
  children,
  name,
}: {
  children?: React.ReactNode;
  name?: string;
}) => {
  const breakpoints = useBreakpoint();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  useEffect(() => {
    if (breakpoints.isSmallScreen) {
      if (mobileMenuIsOpen) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = null;
      }
    }
  }, [mobileMenuIsOpen]);
  return (
    <HeaderBlock data-test={name}>
      <LayoutBleed>
        <Layout position="middle">
          <LayoutItem fluid>
            <Link to="/" className="text-align-middle">
              <AxiomLogo height="48px" name="NAVBARLOGO" />
            </Link>
          </LayoutItem>
          <LayoutItem>
            <Layout position="middle">
              {!!children && (
                <Visible only="smallScreen">
                  <HeaderMobileMenuButton
                    className={AttrsHelper.formatClassname(
                      mobileMenuIsOpen && 'mobile-menu-open'
                    )}
                    onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
                  >
                    <span />
                  </HeaderMobileMenuButton>
                </Visible>
              )}
              <HeaderMenuBlock
                className={AttrsHelper.formatClassname(
                  mobileMenuIsOpen && 'mobile-menu-open'
                )}
              >
                {children}
              </HeaderMenuBlock>
            </Layout>
          </LayoutItem>
        </Layout>
      </LayoutBleed>
    </HeaderBlock>
  );
};
