import { Api } from '@axiom/ui';
import { Tag } from '@axiom/validation';
import { TagConst } from '@axiom/const';

import { EnvUtil } from '../../../utils/env-util';

const { TAG_TYPES } = TagConst;

class SkillsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readSkills() {
    return super.read<{ data: Tag[] }>({
      endpoint: '/tags',
      method: 'GET',
      query: { type: TAG_TYPES.Skill },
    });
  }
}

export const SkillsApi = new SkillsApiClass();
