const HoursPerWeek = {
  OneToTen: '1-10',
  ElevenToTwenty: '11-20',
  TwentyOneToThirty: '21-30',
  ThirtyOneToForty: '31-40',
  FortyPlus: '40+',
} as const;

export const TimeCommitment = {
  FullTime: 'fullTime',
  PartTime: 'partTime',
  HoursPerWeek,
};

export type TimeCommitmentHoursPerWeekType =
  typeof TimeCommitment.HoursPerWeek[keyof typeof TimeCommitment.HoursPerWeek];
