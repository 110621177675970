import { get } from 'lodash';
import { bool, shape, any } from 'prop-types';
/**
 * USAGE
 * When you need to post/put outside of a form set, use this.
 * It will store within submits in the state structure and look like so:
 * {
 *   isLoading
 *   error
 *   data
 *   submits: {
 *     [eventName]: {
 *       isSubmitting
 *       data
 *       successData
 *       errorData
 *     }
 *   }
 * }
 *
 * REDUCER STATES
 * start submit, submit successful, submit errored
 *
 */
const SubmitStateHelperShapeStructure = {
  isSubmitting: bool,
  submitData: any,
  successData: any,
  errorData: any,
};

export const SubmitStateHelperShape = shape(SubmitStateHelperShapeStructure);

export default class SubmitStateHelper {
  /**
   * @param {string} namespace - Store namespace
   * @param {string} eventName - Event namespace
   */
  constructor(namespace, eventName) {
    this.namespace = namespace;
    this.eventName = eventName;
  }

  generateReducers() {
    return {
      [this._getLoadingActionName()]: (state, submitData) => {
        const newSubmitState = { ...(state.submits || []) };
        newSubmitState[this.eventName] = {
          isSubmitting: true,
          submitData,
          successData: null,
          errorData: null,
        };
        return {
          ...state,
          submits: newSubmitState,
        };
      },
      [this._getSuccessActionName()]: (state, payload) => {
        const newSubmitState = { ...(state.submits || []) };
        newSubmitState[this.eventName] = {
          ...newSubmitState[this.eventName],
          isSubmitting: false,
          successData: payload,
          errorData: null,
        };
        return {
          ...state,
          submits: newSubmitState,
        };
      },
      [this._getErrorActionName()]: (state, err) => {
        const newSubmitState = { ...(state.submits || []) };
        newSubmitState[this.eventName] = {
          ...newSubmitState[this.eventName],
          isSubmitting: false,
          successData: null,
          errorData: err,
        };
        return {
          ...state,
          submits: newSubmitState,
        };
      },
    };
  }

  getStructure() {
    return { [this.eventName]: SubmitStateHelperShapeStructure };
  }

  /**
   * Call to submit data and store a success/error result in store
   * @param {Promise} apiCall - POST/PUT/PATCH call to server
   */
  submitData(apiCall) {
    return dispatch => {
      dispatch({ type: this._getLoadingActionName() });

      apiCall
        .then(data => {
          dispatch(this._getSuccessAction(data));
        })
        .catch(e => {
          dispatch({ type: this._getErrorActionName(), error: e });
        });
    };
  }

  /**
   * @param {Object} state - Redux State
   */
  select(state) {
    return {
      ...(get(state, `${this.namespace}.submits.${this.eventName}`) || {}),
    };
  }

  /**
   * @private
   */
  _getLoadingActionName() {
    return `${this.namespace}_${this.eventName}_START`;
  }

  /**
   * @private
   */
  _getSuccessActionName() {
    return `${this.namespace}_${this.eventName}_SUCCESS`;
  }

  /**
   * @private
   */
  _getErrorActionName() {
    return `${this.namespace}_${this.eventName}_ERROR`;
  }

  /**
   * @private
   */
  _getSuccessAction(payload) {
    return { type: this._getSuccessActionName(), payload };
  }
}
