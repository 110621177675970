/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import {
  arrayOf,
  oneOfType,
  number,
  string,
  func,
  shape,
  bool,
} from 'prop-types';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import { FormFeedback } from 'reactstrap';
import { filterBy } from '@progress/kendo-data-query';

import { KendoErrorWrapper } from '../../themes';
import { formatDataTestId } from '../../utils/dataTest';

import { MultiSelectWrapper } from './MultiSelectStyles';

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.data.slice(),
      value: props.value || [],
    };
  }

  handleChange = event => {
    const { data } = this.props;
    this.setState({
      options: data.slice(),
      value: event.target.value,
    });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  filterChange = event => {
    const {
      props: { data },
      state: { options },
    } = this;
    const filteredOptions =
      event.filter.value.length > 0
        ? filterBy(options.slice(), event.filter)
        : data.slice();
    this.setState({
      options: filteredOptions,
    });
  };

  getOptionComponent = (li, itemProps) => (
    <li
      {...li.props}
      data-test={itemProps.dataItem.dataTest || itemProps.dataItem.value}
    >
      <span>{li.props.children}</span>
    </li>
  );

  getDataValue = () => this.props.value.map(option => option.value).join(',');

  render() {
    const {
      props: { dataItemKey, textField, disabled, name, ...inputProps },
      state: { options },
    } = this;

    return (
      <KendoErrorWrapper invalid={inputProps.invalid}>
        <MultiSelectWrapper
          className={inputProps.invalid ? 'form-control is-invalid' : ''}
          invalid={inputProps.invalid}
          data-test={formatDataTestId('multiSelect')}
          data-value={this.getDataValue()}
        >
          <KendoMultiSelect
            {...this.inputProps}
            data={options}
            dataItemKey={dataItemKey}
            disabled={disabled}
            filterable
            itemRender={this.getOptionComponent}
            name={name}
            onChange={this.handleChange}
            onFilterChange={this.filterChange}
            textField={textField}
            value={this.state.value}
            popupSettings={{ animate: false }}
          />
        </MultiSelectWrapper>
        <FormFeedback invalid={inputProps.invalid}>
          {inputProps.error}
        </FormFeedback>
      </KendoErrorWrapper>
    );
  }
}

MultiSelect.defaultProps = {
  dataItemKey: 'value',
  dataTest: '',
  disabled: false,
  name: undefined,
  onChange: () => {},
  textField: 'label',
  value: null,
};

MultiSelect.propTypes = {
  data: arrayOf(shape({})).isRequired,
  dataItemKey: string,
  dataTest: string,
  disabled: bool,
  name: string,
  onChange: func,
  textField: string,
  value: arrayOf(oneOfType([number, string, shape({})])),
};

export default MultiSelect;
