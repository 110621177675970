import { shape, string, arrayOf } from 'prop-types';
import { uniq } from 'lodash';

import FormStateHelper from '../../lib/form-state-helper';
import { AjvUtil } from '../../utils/ajv-util';
import {
  normalizeCandidateLanguagesToApi,
  putLanguagesByCandidateId,
} from '../../api/protected/candidates/candidate-languages';
import { CandidateLanguageUtil } from '../../utils/candidate-language-util';

import { CurrentCandidateStore } from './current-candidate-store';
import { CandidateLanguagesStore } from './candidate-languages-store';

export const FORM_LANGUAGE_NAMESPACE = 'FORM_LANGUAGE';

const FormLanguageValidation = AjvUtil.compileSchema({
  required: ['name', 'languageProficiency'],
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    name: {
      type: 'string',
      minLength: 1,
    },
    languageProficiency: {
      type: 'string',
      minLength: 1,
    },
    languageSkills: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    languageCEFR: {
      type: ['string', 'null'],
      enum: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', null],
    },
  },
});

const stateLanguageForm = new FormStateHelper(
  FORM_LANGUAGE_NAMESPACE,
  'FORM',
  FormLanguageValidation
);

export const FormLanguageStore = {
  getReducers() {
    return {
      ...stateLanguageForm.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateLanguageForm.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return shape({
      candidateId: string,
      name: string,
      languageCEFR: string,
      languageProficiency: string,
      languageSkills: arrayOf(string),
    });
  },
  validate(formData) {
    return stateLanguageForm.validateForm(formData);
  },
  actionShowForm(candidateId, formKey = null, formLanguages = []) {
    const lang = formLanguages.length ? formLanguages[0] : {};
    const langForm = {
      candidateId,
      name: lang.name,
      languageCEFR: lang.languageCEFR,
      languageProficiency: lang.languageProficiency,
      languageSkills: uniq(formLanguages.map(l => l.languageSkill)).filter(
        skill => skill !== null
      ),
    };

    return stateLanguageForm.showForm(langForm, formKey);
  },
  actionHideForm() {
    return stateLanguageForm.hideForm();
  },
  actionSubmitForm:
    (formData, initialFormData, currentCandidateLanguages, languages) =>
    dispatch => {
      /**
       * The PUT for candidate languages expects all languages back on save, even those that didn't change, because it
       * removes everything and readds, so we have to filter out what records we pulled in for the form.
       *
       * We can't look at id though because that's actually the languageId, and you could have multiple proficiencies for the same
       * language, so we're looking for records with the three cats above to figure that out.
       *
       * If all three are blank, we can assume this is a new entry and don't need to filter
       */
      let submittableLanguages = CandidateLanguageUtil.filterOutByLanguageObj(
        currentCandidateLanguages,
        initialFormData
      );

      if (!formData.languageSkills.length) {
        submittableLanguages.push({
          ...formData,
        });
      } else {
        submittableLanguages = submittableLanguages.concat(
          formData.languageSkills.map(skill => ({
            ...formData,
            languageSkill: skill,
          }))
        );
      }

      submittableLanguages = normalizeCandidateLanguagesToApi(
        languages,
        submittableLanguages
      );

      dispatch(
        stateLanguageForm.submitForm(
          putLanguagesByCandidateId(
            initialFormData.candidateId,
            submittableLanguages
          ).then(() => {
            dispatch(
              CurrentCandidateStore.actionFetchCandidate(formData.candidateId)
            );
            dispatch(
              CandidateLanguagesStore.actionFetchLanguages(
                initialFormData.candidateId
              )
            );
          })
        )
      );
    },
  selectFormState(state) {
    return stateLanguageForm.select(state);
  },
  selectFormData(state) {
    return stateLanguageForm.selectData(state);
  },
};
