import React from 'react';
import {
  Banner,
  Button,
  CondensedSmall,
  DateUtil,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  useApi,
} from '@axiom/ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserApi } from '../../api/protected/user/user';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateUtil } from '../../utils/candidate-util';
import { CalendarApi } from '../../api/calendar';

import { InterviewAvailabilityModal } from './InterviewAvailabilityModal';

const LASTUPDATEUSERTYPES = {
  TALENT: 'Talent',
  HQ: 'HQ',
};

export const InterviewAvailability = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showInterviewAvailabilityModal = pathname.includes(
    '/interview-availability'
  );
  const { candidateId: urlCandidateId } = useParams();
  const [{ data: user }] = useApi(UserApi.readUser());
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));
  const { id: calendarId = null } = candidate.calendar || {};

  const [{ data: calendarData } = { data: {} }] = useApi(
    calendarId && CalendarApi.readCalendar(calendarId)
  );
  const { calculatedDisplayName } = candidate;
  const {
    freeTimeUpdatedAt: lastUpdateAt = null,
    freeTimeUpdatedUserType: lastUpdateUserType = null,
  } = calendarData;

  const handleCloseModal = () => {
    navigate('../');
  };

  const handleOpenModal = () => {
    navigate('interview-availability');
  };

  return (
    <>
      <SectionHeader name="INTERVIEW_AVAILABILITY">
        Interview Availability
      </SectionHeader>

      <Gutter bottom="16px" />
      {DateUtil.getCountFromDateToDate(
        new Date(),
        lastUpdateAt || '1970-01-01',
        'days'
      ) >= 30 && (
        <>
          <Banner name="TIMED_REMINDER_BANNER" type="critical">
            <ParagraphHeader as="span">It's been a while!</ParagraphHeader>{' '}
            <Paragraph as="span">
              Please update your interview availability.
            </Paragraph>
          </Banner>
          <Gutter bottom="16px" />
        </>
      )}

      <Paragraph>
        Update your availability for interviews and get in front of clients even
        faster.
      </Paragraph>

      <Gutter bottom="16px" />

      <Layout position="right">
        <LayoutItem>
          <Button
            variation="outline"
            name="INTERVIEW_AVAILABILITY_BUTTON"
            onClick={handleOpenModal}
          >
            Update
          </Button>
        </LayoutItem>
      </Layout>
      {lastUpdateAt && (
        <Gutter top="16px">
          <CondensedSmall name="LAST_UPDATED">
            Last Updated: {DateUtil.displayRelativeTime(lastUpdateAt)} by{' '}
            {lastUpdateUserType === LASTUPDATEUSERTYPES.TALENT
              ? calculatedDisplayName
              : 'Axiom'}
          </CondensedSmall>
        </Gutter>
      )}
      {showInterviewAvailabilityModal && (
        <InterviewAvailabilityModal
          candidate={candidate}
          calendarData={calendarData}
          user={user}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};
