import moment from 'moment';
import { CalendarComponentEvent, CalendarEventType } from '@axiom/validation';

import { CalendarEventsUtil } from './calendar-events-util';

export const EventStates = {
  NEW: 'new',
  MODIFIED: 'modified',
} as const;

export type MergeEventsType = {
  events: CalendarComponentEvent[];
  deleteIds: CalendarComponentEvent['id'][];
};

const getEventState = (
  fromState: CalendarComponentEvent['state'],
  toState: CalendarComponentEvent['state']
) => {
  return fromState === EventStates.NEW ? fromState : toState;
};

export const useMergeEvents = () => {
  const idsToDelete: CalendarComponentEvent['id'][] = [];

  const setIdsToDelete = (
    ids: CalendarComponentEvent['id'][] | CalendarComponentEvent['id'],
    state?: CalendarComponentEvent['state']
  ) => {
    (Array.isArray(ids) ? ids : [ids]).forEach(id => {
      if (state !== EventStates.NEW) {
        idsToDelete.push(id);
      }
    });

    return idsToDelete;
  };

  const getLastFreetimeEvent = (events: CalendarComponentEvent[]) => {
    let idx = -1;

    for (let i = events.length - 1; i >= 0; i--) {
      const e = events[i];
      if (!e.busy) {
        idx = i;
        break;
      }
    }

    return events[idx] || null;
  };

  return (data: MergeEventsType) => {
    const allEvents = CalendarEventsUtil.sortEvents(data.events);

    setIdsToDelete(data.deleteIds);

    const newEvents = allEvents.reduce(
      (
        events: CalendarComponentEvent[],
        currentEvent: CalendarComponentEvent
      ) => {
        const lastEvent: CalendarComponentEvent =
          events[events.length - 1] || null;

        if (lastEvent) {
          const lastFreetime = getLastFreetimeEvent(events);
          const ceStart = moment(currentEvent.start);
          const ceEnd = moment(currentEvent.end);
          const leStart = moment(lastFreetime?.start || lastEvent.start);
          const leEnd = moment(lastFreetime?.end || lastEvent.end);

          if (lastFreetime && !currentEvent.busy) {
            // MERGE NON-staticEvent TYPE EVENTS
            if (
              ceStart.isBetween(leStart, leEnd, 'minutes', '[]') ||
              ceEnd.isBetween(leStart, leEnd, 'minutes', '[]') ||
              (ceStart.isBefore(leStart, 'minutes') &&
                ceEnd.isAfter(leEnd, 'minutes'))
            ) {
              // event A touches or is within event B
              // or
              // event A over event B

              lastFreetime.start = moment.min(ceStart, leStart).toDate();
              lastFreetime.end = moment.max(ceEnd, leEnd).toDate();
              lastFreetime.state = getEventState(
                lastFreetime.state,
                EventStates.MODIFIED
              );

              setIdsToDelete(currentEvent.id, currentEvent.state);
            } else {
              events.push(currentEvent);
            }
          } else if (lastEvent.busy && currentEvent.busy) {
            // is staticEvent type
            if (
              ceStart.isSame(leStart, 'minutes') &&
              ceEnd.isSame(leEnd, 'minutes')
            ) {
              // staticEvent already exists in this  slot
              lastEvent.state = getEventState(lastEvent.state, null);
              setIdsToDelete(currentEvent.id, lastEvent.state);
            } else {
              events.push(currentEvent);
            }
          } else {
            events.push(currentEvent);
          }
        } else {
          events.push(currentEvent);
        }

        return events;
      },
      [] as CalendarEventType[]
    );

    return { events: newEvents, deleteIds: idsToDelete };
  };
};
