import { shape, string } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';

import FormStateHelper from '../../lib/form-state-helper';
import { updateOpportunityByCandidate } from '../../api/protected/candidates/candidate-opportunities-legacy';
import { AppStore } from '../app-store';

import { CurrentCandidateStore } from './current-candidate-store';
import { CandidateOpportunitiesStore } from './candidate-opportunities-store';

export const FORM_NEW_OPPORTUNITY_NO_NAMESPACE = 'FORM_NEW_OPPORTUNITY_NO';

const formNewOppNoState = new FormStateHelper(
  FORM_NEW_OPPORTUNITY_NO_NAMESPACE,
  'MAIN'
);
export const FormNewOpportunityNoStore = {
  getReducers() {
    return {
      ...formNewOppNoState.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...formNewOppNoState.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return shape({
      opportunityId: string,
      candidateStatus: string,
      candidateReasonForNo: string,
    });
  },
  actionShowForm(opportunity) {
    const TALENT_OPT_OUT =
      CandidateOpportunitiesConst.CandidateStatuses.TalentOptOut;
    const oppForm = {
      opportunityId: opportunity.id,
      candidateStatus: TALENT_OPT_OUT,
      candidateReasonForNo: opportunity.candidateReasonForNo,
    };
    return formNewOppNoState.showForm(oppForm, opportunity.id);
  },
  actionSetForm(setObj) {
    return formNewOppNoState.setFormData(setObj);
  },
  actionHideForm() {
    return formNewOppNoState.hideForm();
  },
  actionSubmitForm(formData) {
    return (dispatch, getState) => {
      const { id: candidateId } = CurrentCandidateStore.selectCandidate(
        getState()
      ).record;

      dispatch(
        formNewOppNoState.submitForm(
          updateOpportunityByCandidate(
            candidateId,
            formData.opportunityId,
            formData
          )
            .then(() => {
              const headerText = 'Confirm Opt-out';
              const bodyText =
                'Confirming your decision will move this card to your "History" in Submissions for preservation.';
              dispatch(
                AppStore.actionShowConfirmDialog({ bodyText, headerText })
              );
              CandidateOpportunitiesStore.load(candidateId);
            })
            .catch(() => {
              dispatch(
                AppStore.actionShowErrorDialog(
                  'Submit Error',
                  'There was an error submitting your data. Please try again later.'
                )
              );
            })
        )
      );
    };
  },
  selectFormState(state) {
    return formNewOppNoState.select(state);
  },
  selectFormData(state) {
    return formNewOppNoState.selectData(state);
  },
};
