import React, { useEffect, useState } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

import { AccordionContext } from './AccordionContext';

export type AccordionProps = {
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  name?: string;
};

export const Accordion = ({
  children,
  className,
  expanded,
  name,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'accordion',
        isExpanded && 'expanded',
        className
      )}
    >
      <AccordionContext.Provider
        value={{
          expanded: isExpanded,
          toggle: () => {
            setIsExpanded(!isExpanded);
          },
        }}
      >
        {children}
      </AccordionContext.Provider>
    </div>
  );
};
