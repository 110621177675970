import React from 'react';
import {
  Banner,
  Button,
  Card,
  CardHeader,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  ParagraphHeader,
  SmallHeader,
} from '@axiom/ui';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';

import SubmitStateHelperTreatment from '../SubmitStateHelperTreatment/SubmitStateHelperTreatment';
import CollectionStateHelperTreatment from '../CollectionStateHelperTreatment/CollectionStateHelperTreatment';
import FormStateHelperTreatment from '../FormStateHelperTreatment/FormStateHelperTreatment';
import { FormLanguageStore } from '../../stores/protected/form-profile-detail-languages-store';
import { CandidateLanguagesStore } from '../../stores/protected/candidate-languages-store';
import { SubmitStateHelperShape } from '../../lib/submit-state-helper';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';

import { LanguageItemsDisplay } from './LanguageItemsDisplay';
import { LanguageItemsForm } from './LanguageItemsForm';

const LanguagesComponent = ({
  candidate,
  languageFormState,
  deleteLanguageState,
  languages,
  languageState,
  showNewLanguageForm,
}) => {
  const langNames = Object.keys(languages);

  return (
    <Card name="languages_container">
      <CardHeader>
        <Layout position="middle">
          <LayoutItem fluid>
            <SmallHeader>Languages</SmallHeader>
          </LayoutItem>
          <LayoutItem>
            <Button
              name="LANGUAGE_ADD_BUTTON"
              pattern="secondary"
              variation="minimal"
              icon="plus"
              onClick={() => showNewLanguageForm(candidate.id)}
            >
              Add
            </Button>
          </LayoutItem>
        </Layout>
      </CardHeader>
      <CollectionStateHelperTreatment collectionState={languageState}>
        <SubmitStateHelperTreatment submitState={deleteLanguageState}>
          <FormStateHelperTreatment
            formState={languageFormState}
            formComponent={
              <CardSection divider>
                <LanguageItemsForm />
              </CardSection>
            }
            displayComponent={
              langNames.length === 0 && (
                <CardSection>
                  <Banner impact="low" type="info">
                    No languages added
                  </Banner>
                </CardSection>
              )
            }
          />
          {langNames.map(langName => (
            <CardSection divider key={langName}>
              <ParagraphHeader>{langName}</ParagraphHeader>
              <Gutter bottom="8px" />
              {Object.keys(languages[langName]).map(formKey => (
                <FormStateHelperTreatment
                  key={formKey}
                  formState={languageFormState}
                  formItemIdentifier={formKey}
                  formComponent={<LanguageItemsForm />}
                  displayComponent={
                    <LanguageItemsDisplay
                      candidateLanguages={languages[langName][formKey]}
                      formKey={formKey}
                    />
                  }
                />
              ))}
            </CardSection>
          ))}
        </SubmitStateHelperTreatment>
      </CollectionStateHelperTreatment>
    </Card>
  );
};

LanguagesComponent.propTypes = {
  languageFormState: FormLanguageStore.getStateShape().isRequired,
  deleteLanguageState: SubmitStateHelperShape.isRequired,
  languages: shape({}).isRequired,
  languageState: shape({}).isRequired,
  showNewLanguageForm: func.isRequired,
  candidate: CurrentCandidateStore.getDataShape().isRequired,
};

export const Languages = connect(
  state => ({
    languageFormState: FormLanguageStore.selectFormState(state),
    deleteLanguageState:
      CandidateLanguagesStore.selectDeleteLanguageState(state),
    languages: CandidateLanguagesStore.selectLanguagesGrouped(state),
    languageState: CandidateLanguagesStore.selectLanguagesState(state),
  }),
  {
    showNewLanguageForm: FormLanguageStore.actionShowForm,
  }
)(LanguagesComponent);
