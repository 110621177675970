import { Taxonomy } from '@axiom/validation';

export const TaxonomyUtil = {
  getIndustryEnums: (
    industries: Taxonomy['industries'],
    sort: (a: string, b: string) => number = undefined
  ) => {
    return Object.keys(industries).sort(sort);
  },

  getIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries).map(key => ({
      label: industries[key].displayValue,
      value: key,
    }));
  },

  getFilteredIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries)
      .filter(opt => opt !== 'NA')
      .map(key => ({
        label: industries[key].displayValue,
        value: key,
      }));
  },
};
