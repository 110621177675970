import React, { useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  DSCandidate,
  DSNoCandidate,
  Gutter,
  Visible,
  PDFExport,
} from '@axiom/ui';
import { getSubmissionFileName } from '@axiom/ui/src/utils/submission-util';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { DigitalSubmissionStore } from '../../stores/digital-submission-store';
import { CurrentUserStore } from '../../stores/protected/current-user-store';
import { isHQUser } from '../../utils/roles';
import { generateBenchUrl } from '../../utils/link-util';

export const DigitalTalentSubmissionViewComponent = ({
  digitalSubmissionState,
  user,
}) => {
  const pdfRoot = useRef(null);

  return (
    <StoreStateTreatment
      storeState={digitalSubmissionState}
      renderLoadedView={({ data: submission }) => {
        if (submission.candidates.length === 0) {
          return <DSNoCandidate />;
        }

        const candidate = submission.candidates[0];
        return (
          <PDFExport fileName={getSubmissionFileName(submission)} ref={pdfRoot}>
            <DSCandidate
              key={candidate.id}
              barredLocations={candidate.barredLocations}
              candidate={candidate}
              certifications={candidate.certifications}
              currency={submission.currency}
              degrees={candidate.degrees}
              experiences={candidate.experiences}
              languages={candidate.languages}
              headerActionButtons={
                <div className="text-align-right">
                  <Visible only="largeScreen" as="span">
                    <Button
                      name="DOWNLOAD_PDF_BUTTON"
                      onClick={() => pdfRoot.current.save()}
                    >
                      Download
                    </Button>
                    {isHQUser(user) && (
                      <Gutter left="1rem" as="span">
                        <Button
                          name="VIEW_BENCH_BUTTON"
                          toTab={generateBenchUrl(submission.accountId)}
                        >
                          View Bench
                        </Button>
                      </Gutter>
                    )}
                  </Visible>
                  {submission.ownerUser && (
                    <Gutter left="1rem" as="span">
                      <Button
                        // For safety we are url encoding the subject
                        to={`mailto:${
                          submission.ownerUser.email
                        }?subject=${encodeURI(
                          "I'd like to discuss changes to my bio for this opportunity"
                        )}`}
                        name="Discuss_button"
                      >
                        Discuss
                      </Button>
                    </Gutter>
                  )}
                </div>
              }
            />
          </PDFExport>
        );
      }}
    />
  );
};

DigitalTalentSubmissionViewComponent.propTypes = {
  digitalSubmissionState: DigitalSubmissionStore.getStateShape().isRequired,
  user: CurrentUserStore.getStateShape().isRequired,
};

export const DigitalTalentSubmissionView = connect(state => ({
  digitalSubmissionState: DigitalSubmissionStore.select(state),
  user: CurrentUserStore.selectUser(state),
}))(DigitalTalentSubmissionViewComponent);
