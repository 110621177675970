import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type AccordionsProps = {
  children: React.ReactNode;
  className?: string;
  minimal?: boolean;
  name?: string;
};

export const Accordions = ({
  children,
  className,
  minimal,
  name,
}: AccordionsProps) => {
  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'accordions',
        minimal && 'minimal-accordion',
        className
      )}
    >
      {children}
    </div>
  );
};
