import React, { useContext } from 'react';

import { IconButton } from '../../element/Button/IconButton';
import {
  CardHeader,
  CardHeaderBackgrounds,
  CardHeaderProps,
} from '../Card/CardHeader';
import { Layout } from '../Layout/Layout';
import { LayoutItem } from '../Layout/LayoutItem';

import { DrawerContext } from './DrawerContext';

export type DrawerHeaderProps = {
  background?: CardHeaderProps['background'];
  children: React.ReactNode;
  className?: string;
  name?: string;
  onToggle?: (expand: boolean) => void;
};
export const DrawerHeader = ({
  background,
  children,
  className,
  name,
  onToggle,
}: DrawerHeaderProps) => {
  const { expanded, toggle } = useContext(DrawerContext);
  const handleClick = () => {
    onToggle?.(!expanded);
    toggle(!expanded);
  };
  return (
    <CardHeader
      background={background}
      className={className}
      name={name}
      onClick={handleClick}
    >
      <Layout position="left middle">
        <LayoutItem fluid>{children}</LayoutItem>
        <LayoutItem>
          <IconButton
            className="fixed-dimension-inline"
            name="expand-icon"
            pattern="secondary"
            variation="minimal"
            icon={expanded ? 'arrow-down' : 'arrow-right'}
            onClick={handleClick}
            inverse={
              background === CardHeaderBackgrounds.dark ||
              background === CardHeaderBackgrounds.blue
            }
          />
        </LayoutItem>
      </Layout>
    </CardHeader>
  );
};
