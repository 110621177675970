import React from 'react';
import { func, arrayOf } from 'prop-types';
import { chain } from 'lodash';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { LanguageConst } from '@axiom/const';
import {
  Button,
  FluidButtonLayout,
  Grid,
  GridColumn,
  GridRow,
  LabelWrapper,
} from '@axiom/ui';

import ComboBoxFF from '../ComboBoxFF/ComboBoxFF';
import { FormLanguageStore } from '../../stores/protected/form-profile-detail-languages-store';
import { LanguagesStore } from '../../stores/protected/languages-store';
import { FFMultiSelect } from '../FFMultiSelect/FFMultiSelect';
import { CandidateLanguageShape } from '../../models/language-model';
import { selectifyObject } from '../../utils/selectify';
import { CandidateLanguagesStore } from '../../stores/protected/candidate-languages-store';

const allowedLangProficiencies = selectifyObject(
  LanguageConst.LANGUAGE_PROFICIENCIES
);
const allowedLanguageSkills = selectifyObject(LanguageConst.LANGUAGE_SKILLS);
const allowedLanguageCEFR = Object.values(LanguageConst.LANGUAGE_CEFR_LEVELS);

const LanguageItemsFormComponent = ({
  languages,
  languageForm,
  candidateLanguages,
  submitForm,
  cancelForm,
}) => (
  <Form
    onSubmit={changedForm =>
      submitForm(changedForm, languageForm, candidateLanguages, languages)
    }
    initialValues={languageForm}
    validate={FormLanguageStore.validate}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Grid>
          <GridRow>
            <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
              <LabelWrapper label="Language">
                <Field
                  name="name"
                  component={ComboBoxFF}
                  options={chain(languages).map('name').value()}
                  allowCustom={false}
                />
              </LabelWrapper>
            </GridColumn>
            <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
              <LabelWrapper label="Proficiency">
                <Field
                  name="languageProficiency"
                  component={ComboBoxFF}
                  options={allowedLangProficiencies}
                  allowCustom={false}
                  textField="label"
                  dataItemKey="value"
                />
              </LabelWrapper>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              <LabelWrapper label="Skills">
                <Field
                  name="languageSkills"
                  component={FFMultiSelect}
                  options={allowedLanguageSkills}
                  textField="label"
                  dataItemKey="value"
                  shouldConvertValuesToScalar
                />
              </LabelWrapper>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
              <LabelWrapper label="CEFR">
                <Field
                  name="languageCEFR"
                  component={ComboBoxFF}
                  options={allowedLanguageCEFR}
                  allowCustom={false}
                />
              </LabelWrapper>
            </GridColumn>
          </GridRow>
        </Grid>

        <FluidButtonLayout>
          <Button
            variation="outline"
            onClick={cancelForm}
            name="profileLanguageCancelButton"
          >
            Cancel
          </Button>
          <Button type="submit" name="profileLanguageSaveButton">
            Save
          </Button>
        </FluidButtonLayout>
      </form>
    )}
  />
);

LanguageItemsFormComponent.propTypes = {
  languageForm: FormLanguageStore.getDataShape().isRequired,
  candidateLanguages: arrayOf(CandidateLanguageShape).isRequired,
  languages: LanguagesStore.getDataShape().isRequired,
  submitForm: func.isRequired,
  cancelForm: func.isRequired,
};

export const LanguageItemsForm = connect(
  state => ({
    candidateLanguages: CandidateLanguagesStore.selectLanguages(state),
    languageForm: FormLanguageStore.selectFormData(state),
    languages: LanguagesStore.selectLanguages(state),
  }),
  {
    submitForm: FormLanguageStore.actionSubmitForm,
    cancelForm: FormLanguageStore.actionHideForm,
  }
)(LanguageItemsFormComponent);
