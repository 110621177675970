import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Badge, Gutter, HeaderBarLink, Layout, LayoutItem } from '@axiom/ui';

export const PreferencesNavigationItem = ({
  children,
  name,
  target,
  url,
  showBadge = false,
}: {
  children: React.ReactNode;
  name: string;
  target?: string;
  url: string;
  showBadge?: boolean;
}) => {
  const location = useLocation();
  return (
    <Layout>
      <LayoutItem>
        <HeaderBarLink
          selected={location.pathname.endsWith(url.replace('../', ''))}
          name={name}
        >
          <Link to={url} target={target}>
            {children}
          </Link>
        </HeaderBarLink>
      </LayoutItem>
      {showBadge && (
        <LayoutItem>
          <Gutter left="8px">
            <Badge background="light blue" name="NOTIFICATION_BADGE">
              1
            </Badge>
          </Gutter>
        </LayoutItem>
      )}
    </Layout>
  );
};
