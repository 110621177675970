import React from 'react';
import orderBy from 'lodash/orderBy';
import { useApi } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { TaxonomyApi } from '../../api/protected/taxonomy/taxonomy';
import { SkillsApi } from '../../api/protected/tags/skills';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';

import { ExperiencesCard } from './ExperiencesCard';

export const Experiences = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: taxonomy }, { data: skills }, { data: experiences }] = useApi(
    TaxonomyApi.readTaxonomy(),
    SkillsApi.readSkills(),
    CandidateExperienceApi.readExperiences(candidateId)
  );

  const orderedExperiences = orderBy(
    experiences,
    ['endDate', 'startDate', 'isAxiom'],
    ['desc', 'desc', 'desc']
  );

  return (
    <ExperiencesCard
      experiences={orderedExperiences}
      industries={taxonomy.industries}
      skills={skills}
      candidateId={candidateId}
    />
  );
};
