export const CandidateLanguageUtil = {
  filterOutByLanguageObj: (languages, filterByLang) => {
    const { name, languageProficiency, languageCEFR } = filterByLang;

    if (name || languageProficiency || languageCEFR) {
      languages = languages.filter(
        lang =>
          lang.name !== name ||
          lang.languageProficiency !== languageProficiency ||
          lang.languageCEFR !== languageCEFR
      );
    }

    return languages;
  },
};
