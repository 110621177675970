import { shape, string, number, bool, oneOf } from 'prop-types';
import { Education } from '@axiom/const';

import { areaOfStudyShape } from './area-of-study-model';

export const EducationItemShape = shape({
  id: string,
  institution: string,
  lawSchoolId: string,
  degree: oneOf(Object.values(Education.DEGREES)),
  yearAwarded: number,
  stateExamScore: string,
  isPrimary: bool,
  areaOfStudy: areaOfStudyShape,
});

export const educationItemFromApi = ({
  id,
  institution,
  lawSchoolId,
  degree,
  yearAwarded,
  stateExamScore,
  isPrimary,
  areaOfStudy,
}) => ({
  id: id ?? null,
  lawSchoolId: lawSchoolId ?? null,
  institution: institution ?? null,
  degree: degree ?? null,
  yearAwarded: yearAwarded ?? null,
  stateExamScore: stateExamScore ?? null,
  isPrimary: isPrimary ?? null,
  areaOfStudy: areaOfStudy ?? null,
});

export const educationItemForApi = ({
  id,
  lawSchoolId,
  degree,
  yearAwarded,
  stateExamScore,
  isPrimary,
  areaOfStudy,
}) => ({
  id: id ?? null,
  lawSchoolId: lawSchoolId ?? null,
  degree: degree ?? null,
  yearAwarded: yearAwarded ?? null,
  stateExamScore: stateExamScore ?? null,
  isPrimary: isPrimary ?? null,
  areaOfStudy: areaOfStudy ?? null,
});
