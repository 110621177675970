import memoize from 'lodash/memoize';

const toTree = memoize(skills => {
  const dedupped = skills.reduce((data, item) => {
    const exists = data.find(x => x.id === item.id);

    return exists ? data : data.concat([item]);
  }, []);

  const arrayToTree = (nodes, parentId) => {
    const arrayToReturn = [];

    nodes.forEach(node => {
      if (node.parentId === parentId) {
        arrayToReturn.push({
          id: node.id,
          name: node.name,
          type: node.type,
          children: arrayToTree(nodes, node.id),
        });
      }
    });

    return arrayToReturn;
  };

  return arrayToTree(dedupped, null);
});

// returns dedupped category > skills three
export const categorySkills = memoize(skills => {
  const tree = toTree(skills);

  const properStructure = tree.map(trunk => {
    const data = {
      id: trunk.id,
      name: trunk.name,
      children: [],
    };

    trunk.children.forEach(branch => {
      branch.children.forEach(leaf => {
        data.children.push({
          id: leaf.id,
          name: leaf.name,
        });
      });
    });

    return data;
  });

  return properStructure;
});

export const onlySkills = memoize(skills => {
  const tree = toTree(skills);
  const leaves = [];

  tree.forEach(trunk => {
    trunk.children.forEach(branch => {
      branch.children.forEach(leaf => {
        leaves.push(leaf);
      });
    });
  });

  return [];
});

export const toSkillsTree = memoize(skills => {
  const toReturn = [];

  // converting skills tree into a flat list, optimization for DropdownTree
  toTree(skills).forEach(category => {
    category.children.forEach(subCategory => {
      toReturn.push(
        ...subCategory.children.map(item => ({
          id: item.id,
          name: item.name,
          type: item.type,
          category: category.name,
          subCategory: subCategory.name,
        }))
      );
    });
  });

  return toReturn;
});

export const restructureForDropdownTree = memoize(skills => {
  const data = toSkillsTree(skills);
  const used = new Set();
  const tree = [];

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];
    const key = item.category + item.subCategory;

    if (used.has(key)) {
      for (let g = tree.length - 1; g >= 0; g -= 1) {
        const treeItem = tree[g];

        if (treeItem.category + treeItem.subCategory === key) {
          tree[g].items[tree[g].items.length] = {
            id: item.id,
            name: item.name,
          };

          break;
        }
      }
    } else {
      used.add(key);

      tree[tree.length] = {
        name: `${item.category} > ${item.subCategory}`,
        id: item.category + item.subCategory,
        category: item.category,
        subCategory: item.subCategory,
        items: [
          {
            id: item.id,
            name: item.name,
          },
        ],
      };
    }
  }

  return tree;
});
