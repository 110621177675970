import isUuid from 'is-uuid';
import { CandidateImageConst } from '@axiom/const';

import { EnvUtil } from './env-util';

const { IMAGE_RESIZE_DIRECTORY, ImageSizesByWidth, PROFILE_IMAGE_DIRECTORY } =
  CandidateImageConst;
export const CandidateUtil = {
  getCandidateData(data) {
    return data?.record || data;
  },
  isParaLegal(inputCandidate) {
    const candidate = this.getCandidateData(inputCandidate);

    return candidate?.occupationType === 'Legal Support';
  },

  reconcileUserCandidateId(user, candidateIdFromUrl) {
    return user.roles.some(r => r.startsWith('Envoy')) &&
      isUuid.anyNonNil(candidateIdFromUrl)
      ? candidateIdFromUrl
      : user.candidateId;
  },
  getProfileImageUri(candidate, size) {
    const { profileImageKey } = this.getCandidateData(candidate);

    if (
      !profileImageKey ||
      !ImageSizesByWidth[size] ||
      !EnvUtil.publicS3Bucket
    ) {
      return null;
    }

    const [idSlug] = profileImageKey
      .replace(`${PROFILE_IMAGE_DIRECTORY}/`, '')
      .split('/');
    const { width, height } = ImageSizesByWidth[size];

    return `${EnvUtil.publicBaseUrlS3}/${EnvUtil.publicS3Bucket}/${IMAGE_RESIZE_DIRECTORY}/${idSlug}/${width}x${height}`;
  },
};
