import React, { useCallback, useEffect, useState } from 'react';
import { Button, IconButton, Layout, useBreakpoint } from '@axiom/ui';

import { Grid } from '../../layout/Grid/Grid';
import { GridRow, GridRowProps } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';

export type CarouselType = {
  name?: string;
  children: React.ReactNode | [React.ReactNode];
};

export const Carousel = ({ name, children }: CarouselType) => {
  const childElements = Array.isArray(children) ? children : [children];
  const childCount = childElements.length;
  const { isMobile, isTablet } = useBreakpoint();
  const [pageNumber, setPageNumber] = useState(1);
  const [elmsToDisplay, setElmsToDisplay] = useState([]);
  const displayCount: GridRowProps['columns'] = (() => {
    if (isMobile) {
      return 1;
    } else if (isTablet) {
      return 2;
    }

    return 3;
  })();

  const paginationCount = Math.ceil(childCount / displayCount);

  useEffect(() => {
    const spacesToFill =
      (paginationCount * displayCount - childCount) % displayCount;
    const fillers =
      spacesToFill >= 1
        ? new Array(spacesToFill).fill(<div data-test="FILLER_DOM" />)
        : [];

    setPageNumber(1);
    setElmsToDisplay([...childElements, ...fillers]);
  }, [childCount, displayCount]);

  const getDisplaySet = useCallback(() => {
    const startIndex = (pageNumber - 1) * displayCount;
    const endIndex = startIndex + displayCount;

    return elmsToDisplay.length > displayCount
      ? elmsToDisplay.slice(startIndex, endIndex)
      : elmsToDisplay;
  }, [pageNumber, elmsToDisplay]);

  const goToPage = (n: number) => {
    setPageNumber(n);
  };

  const buildPagination = useCallback(() => {
    const data = new Array(paginationCount).fill(null).map((_, idx) => {
      const n = idx + 1;
      return (
        <Button
          pattern="primary"
          variation="minimal"
          className={`pagination-item dot ${pageNumber === n ? 'active' : ''}`}
          name="PAGINATION_DOT"
          key={idx.toString()}
          onClick={() => pageNumber !== n && goToPage(n)}
        >
          <span />
        </Button>
      );
    });

    const previousChevron = (
      <IconButton
        pattern="secondary"
        variation="minimal"
        className="pagination-item chevron"
        icon="arrow-left"
        disabled={pageNumber - 1 <= 0}
        onClick={() => goToPage(pageNumber - 1)}
        key="previousChevron"
        name="PREVIOUS_CHEVRON"
      />
    );

    const nextChevron = (
      <IconButton
        pattern="secondary"
        variation="minimal"
        className="pagination-item chevron"
        icon="arrow-right"
        disabled={pageNumber + 1 > paginationCount}
        onClick={() => goToPage(pageNumber + 1)}
        key="nextChevron"
        name="NEXT_CHEVRON"
      />
    );

    return [previousChevron, ...data, nextChevron];
  }, [pageNumber, paginationCount]);

  return (
    <Grid name={name || 'CAROUSEL'} className="carouselContainer">
      <GridRow columns={displayCount}>
        {getDisplaySet().map((child, idx) => (
          <GridColumn
            stretched
            key={child?.props?.id || child?.props?.name || idx.toString()}
          >
            {child}
          </GridColumn>
        ))}
      </GridRow>
      {paginationCount > 1 && (
        <GridRow gutterTop="24px">
          <GridColumn>
            <Layout position="center middle">{buildPagination()}</Layout>
          </GridColumn>
        </GridRow>
      )}
    </Grid>
  );
};
