import { bool, number, shape, string, exact } from 'prop-types';
import { LawyerDesiredMaxHourOverride } from '@axiom/const';
import { isEligibleForMaxHoursOverride } from '@axiom/utils';

import FormStore from '../../classes/form-store';
import { updateCandidate } from '../../api/protected/candidates/candidate';
import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';

// eslint-disable-next-line import/no-cycle
import { CurrentCandidateStore } from './current-candidate-store';

class FormAvailabilityPreferencesStoreClass extends FormStore {
  getDataShape() {
    return shape({
      minMaxWeeklyHours: exact({
        min: exact({
          value: number,
          label: string,
        }),
        max: exact({
          value: number,
          label: string,
        }),
      }).isRequired,
      preferredWeeklyMaxHours: number.isRequired,
      id: string,
      availabilityPreferencesUpdatedAt: string,
      availabilityPreferencesUpdatedByType: string,
      calculatedDisplayName: string,
      isParaLegal: bool.isRequired,
      profileStatus: string.isRequired,
    });
  }

  load(candidate = {}) {
    const {
      availabilityPreferencesUpdatedAt,
      availabilityPreferencesUpdatedByType,
      calculatedDisplayName,
      desiredWeeklyMaxHours,
      id = null,
      maxHours: maxHoursFromWorkday,
      profileStatus,
    } = candidate;

    return this.setState({
      minMaxWeeklyHours: {
        min: {
          value: 0,
        },
        max: {
          value: isEligibleForMaxHoursOverride(candidate)
            ? LawyerDesiredMaxHourOverride
            : maxHoursFromWorkday,
        },
      },
      maxHoursFromWorkday,
      preferredWeeklyMaxHours: desiredWeeklyMaxHours ?? maxHoursFromWorkday,
      id,
      profileStatus,
      calculatedDisplayName,
      availabilityPreferencesUpdatedAt,
      availabilityPreferencesUpdatedByType,
    });
  }

  validate() {
    return true;
  }

  submit(changedFields, formData) {
    const { id: candidateId } = formData;
    const candidateData = {};

    if (formData?.preferredWeeklyMaxHours !== undefined) {
      candidateData.desiredWeeklyMaxHours = formData.preferredWeeklyMaxHours;
    }

    return this.watchState(
      updateCandidate(candidateId, candidateData).then(() => {
        this.dispatch(CurrentCandidateStore.actionFetchCandidate(candidateId));
        WorkFeedApi.clearWorkFeed(candidateId);
        WorkFeedApi.clearWorkFeedSaved(candidateId);
        WorkFeedApi.clearWorkFeedInterested(candidateId);
      })
    );
  }
}

export const FormAvailabilityPreferencesStore =
  new FormAvailabilityPreferencesStoreClass();
