/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape, arrayOf, oneOfType, string, number, func } from 'prop-types';

import { FlexWrapper } from '../../themes/components';
import { FFError, isErroring } from '../FFError/FFError';
import DropdownList from '../DropdownList/DropdownList';

export const FFDropdownList = ({ input, meta, onValueChange, ...rest }) => {
  if (isErroring(meta)) {
    rest.valid = false;
  }

  return (
    <>
      <DropdownList
        value={input.value}
        onChange={value => {
          input.onChange(value);
          onValueChange(value);
        }}
        onBlur={value => input.onBlur(value)}
        name={input.name}
        {...rest}
        appendLocal
      />
      {isErroring(meta) && (
        <FlexWrapper>
          <FFError finalFormElementMeta={meta} />
        </FlexWrapper>
      )}
    </>
  );
};

FFDropdownList.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  options: arrayOf(oneOfType([string, number, shape({})])).isRequired,
  onValueChange: func,
};

FFDropdownList.defaultProps = {
  onValueChange: () => {},
};
