import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';

import { CandidateApi } from '../api/protected/candidates/candidate';
import { UserApi } from '../api/protected/user/user';
import { CandidateUtil } from '../utils/candidate-util';
import { isHQUser, isSalesUser } from '../utils/roles';

export const useDocumentTitle = (appendTitle: string) => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));

  if (isHQUser(user) || isSalesUser(user)) {
    return `${candidate.calculatedDisplayName} - ${appendTitle}`;
  }

  return `AxiomLaw - ${appendTitle}`;
};
