/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { arrayOf, shape, oneOfType, string, bool } from 'prop-types';

import MultiSelect from '../MultiSelect/MultiSelect';
import { FFError, isErroring } from '../FFError/FFError';
import { FlexLeftWrapper, FlexWrapper } from '../../themes/components';
import { KendoUtils } from '../../utils/kendo-util';

/**
 * shouldConvertValuesToScalar: must pass in dataItemKey and textField
 * This will make a value array of onjects that kendo wants, but then
 * transform to array of scalars that we usually need
 */
const convertValue = (props, options, values) => {
  if (props.shouldConvertValuesToScalar) {
    return KendoUtils.convertScalarValuesToObjectValues(
      values,
      options,
      props.dataItemKey,
      props.textField
    );
  }
  return values;
};
const parseValue = (props, values) => {
  if (props.shouldConvertValuesToScalar) {
    return KendoUtils.convertObjectValuesToScalarValues(
      values,
      props.dataItemKey
    );
  }
  return values;
};
export const FFMultiSelect = ({ input, meta, options, ...rest }) => {
  if (isErroring(meta)) {
    rest.invalid = true;
  }

  return (
    <>
      <MultiSelect
        value={convertValue(rest, options, input.value || [])}
        name={input.name}
        data={options}
        onChange={event => input.onChange(parseValue(rest, event.target.value))}
        {...rest}
      />
      <FlexWrapper>
        <FlexLeftWrapper>
          <FFError finalFormElementMeta={meta} />
        </FlexLeftWrapper>
      </FlexWrapper>
    </>
  );
};

FFMultiSelect.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  options: arrayOf(oneOfType([shape({}), string])),
  shouldConvertValuesToScalar: bool,
};

FFMultiSelect.defaultProps = {
  options: [],
  shouldConvertValuesToScalar: false,
};
