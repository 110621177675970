import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { WorkFeedRedirect } from '../components/WorkFeedRedirect/WorkFeedRedirect';

export const DefaultPage = () => (
  <TalentDataProvider>
    <WorkFeedRedirect />
  </TalentDataProvider>
);
