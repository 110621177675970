export const makeFullName = (...names) => {
  let name = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const partialName of names) {
    if (typeof partialName === 'string' && partialName.length) {
      name += `${partialName} `;
    }
  }
  return name.trim();
};

export const truncate = (str, len, mask = '') =>
  typeof str === 'string' && str.length > len
    ? `${str.substr(0, len - 1)}${mask}`
    : str;

export const capitalizeString = str =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;
