import React from 'react';
import { shape, string, bool, oneOfType } from 'prop-types';
import styled from 'styled-components';
import { FormFeedback as RSFormFeedback } from 'reactstrap';

import { PALETTE } from '../../themes/constants';
import { formatDataTestId } from '../../utils/dataTest';

const ErrorWrapper = styled(RSFormFeedback)`
  color: ${PALETTE.theme.danger};
  font-weight: bold;
  display: inline-block;
`;

const hasBeenTouched = finalFormElementMeta => {
  return typeof finalFormElementMeta.touched === 'boolean'
    ? finalFormElementMeta.touched
    : Object.keys(finalFormElementMeta.touched).reduce((value, key) => {
        if (value) return value;

        value = finalFormElementMeta.touched[key];

        return value;
      }, false);
};

export const isErroring = finalFormElementMeta =>
  !!(
    (finalFormElementMeta.error || finalFormElementMeta.submitError) &&
    hasBeenTouched(finalFormElementMeta)
  );

export const FFError = ({ finalFormElementMeta }) => {
  const touched = hasBeenTouched(finalFormElementMeta);

  return (
    <ErrorWrapper>
      {(finalFormElementMeta.error || finalFormElementMeta.submitError) &&
        touched && (
          <span data-test={formatDataTestId('formError')}>
            {finalFormElementMeta.error || finalFormElementMeta.submitError}
          </span>
        )}
    </ErrorWrapper>
  );
};

FFError.propTypes = {
  finalFormElementMeta: shape({
    touched: oneOfType([bool, shape({})]),
    error: string,
    submitError: string,
  }).isRequired,
};
