import React, { useState } from 'react';
import {
  CalendarComponentType,
  CalendarEventType,
  Candidate,
  User,
} from '@axiom/validation';
import {
  Grid,
  GridColumn,
  GridRow,
  ImageCircle,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  Calendar as CalendarComponent,
  CalendarPermissionsUtil,
  Button,
  LayoutItem,
  Gutter,
  Dropdown,
  Form,
  CalendarTimeZoneUtil,
  useBreakpoint,
  CandidateProfileUtil,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  PermissionImpersonationRoles,
  NO_TIMEZONE,
} from '@axiom/const';
import { z } from 'zod';
import { OnChangeArgType } from '@axiom/ui/src/components/element/Calendar/Calendar';

import { CalendarApi } from '../../api/calendar';
import { CandidateApi } from '../../api/protected/candidates/candidate';

const dayWeekMode = {
  slots: 2,
  increments: 30,
};

const CalendarSchema = z.object({
  TIMEZONE_DROPDOWN: z.string(),
});

export type InterviewAvailabilityModalType = {
  candidate: Candidate;
  calendarData: CalendarComponentType;
  user: User;
  onClose: () => void;
};

export const InterviewAvailabilityModal = ({
  candidate,
  calendarData,
  user,
  onClose,
}: InterviewAvailabilityModalType) => {
  const { isMobile } = useBreakpoint();
  const [calendarEvents, setCalendarEvents] = useState({
    modifiedEvents: [],
    newEvents: [],
    deleteEventIds: [],
  });
  const { id: calendarId = null, timezone: talentIana } =
    candidate.calendar || {};
  const timezone = CalendarTimeZoneUtil.getTimeZone(talentIana);
  const { userPermissions } = CalendarPermissionsUtil(
    user,
    PermissionImpersonationRoles.talent
  );
  const calendarPermissions = userPermissions();
  const timezoneOptions = CalendarTimeZoneUtil.getDropDownOptions();
  const handleCalendarOnChange = (events: OnChangeArgType) => {
    setCalendarEvents(events);
  };

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarId),
      CalendarApi.refreshCalendarEvents(calendarId),
      CandidateApi.refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = async (formChanges: {
    TIMEZONE_DROPDOWN: string;
  }) => {
    const { iana } = formChanges?.TIMEZONE_DROPDOWN
      ? CalendarTimeZoneUtil.getZoneFromId(formChanges.TIMEZONE_DROPDOWN)
      : timezone;

    const currentCalendarId =
      calendarId ||
      (
        await CalendarApi.createCalendar({
          candidateId: candidate.id,
          timezone: iana,
        })
      ).data.id;

    const promises = [];
    if (calendarEvents.newEvents.length) {
      promises.push(
        CalendarApi.createCalendarEvents(
          currentCalendarId,
          calendarEvents.newEvents
        )
      );
    }
    if (calendarEvents.modifiedEvents.length) {
      const payload = calendarEvents.modifiedEvents.reduce((crnt, event) => {
        const { id, ...evnt } = event;
        crnt[id] = evnt;
        return crnt;
      }, {} as { [key: string]: CalendarEventType });

      promises.push(
        CalendarApi.updateCalendarEvents(currentCalendarId, payload)
      );
    }
    if (calendarEvents.deleteEventIds.length) {
      CalendarApi.deleteCalendarEvents(
        currentCalendarId,
        calendarEvents.deleteEventIds
      );
    }

    await Promise.all(promises);

    if (formChanges.TIMEZONE_DROPDOWN) {
      // API call not part of the Promise.all() above to avoid race-condition
      await CalendarApi.updateCalendar(currentCalendarId, {
        timezone: iana,
      });
    }
    refreshData();
    onClose();
  };

  const checkToDisable = () => {
    if (
      calendarEvents.newEvents.length === 0 &&
      calendarEvents.modifiedEvents.length === 0 &&
      calendarEvents.deleteEventIds.length === 0
    ) {
      return true;
    }

    if (
      !!calendarEvents.newEvents.find(e => e.error) ||
      !!calendarEvents.modifiedEvents.find(e => e.error)
    ) {
      return true;
    }

    return false;
  };

  return (
    timezone && (
      <Form
        name="INTERVIEW_AUTO_FORM"
        schema={CalendarSchema}
        initialValues={{
          TIMEZONE_DROPDOWN: timezone === NO_TIMEZONE ? null : timezone.id,
        }}
        onSubmit={handleUpdateOnClick}
      >
        {({ fireSubmit, values }) => {
          return (
            <Modal size="large" name="INTERVIEW_AUTO_MODAL">
              <ModalHeader name="MODAL_HEADER" onClose={onClose}>
                <Layout position="middle" wrap>
                  <LayoutItem rightGutter="16px">
                    <ImageCircle
                      imageName={candidate.calculatedDisplayName}
                      src={CandidateProfileUtil.getProfileImageUri(
                        candidate,
                        PossibleImageSizes.W_100
                      )}
                      size="small"
                      name="TALENT_IMAGE"
                    />
                  </LayoutItem>
                  <LayoutItem fluid>
                    <SmallHeader name="PENDO_TALENT_INTERVIEW_AVAILABILITY">
                      Update your interview availability
                    </SmallHeader>
                  </LayoutItem>
                </Layout>
              </ModalHeader>
              <ModalSection>
                <Grid>
                  <GridRow>
                    <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                      <Paragraph>
                        Choose as many time slots as possible to increase the
                        likelihood of being matched for an interview. We suggest
                        a minimum 4 hours over the next two weeks.
                      </Paragraph>
                      <Gutter bottom="24px" />
                      <Dropdown
                        label="Time zone"
                        options={timezoneOptions}
                        name="TIMEZONE_DROPDOWN"
                        displayKey="label"
                        valueKey="value"
                      />
                      <Gutter bottom="16px" />
                    </GridColumn>
                    <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                      <CalendarComponent
                        permissions={calendarPermissions}
                        events={calendarData?.events || []}
                        backgroundEvents={[]}
                        dayMode={dayWeekMode}
                        weekMode={dayWeekMode}
                        timezone={
                          values.TIMEZONE_DROPDOWN
                            ? CalendarTimeZoneUtil.getZoneFromId(
                                values.TIMEZONE_DROPDOWN
                              ).iana
                            : timezone.iana
                        }
                        onChange={handleCalendarOnChange}
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </ModalSection>
              <ModalFooter>
                <Button
                  onClick={onClose}
                  pattern="primary"
                  variation="outline"
                  name="CANCEL_BUTTON"
                >
                  Cancel
                </Button>
                {!isMobile && (
                  <Button
                    onClick={fireSubmit}
                    disabled={checkToDisable()}
                    name="UPDATE_BUTTON"
                  >
                    Update
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          );
        }}
      </Form>
    )
  );
};
