import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import { get } from 'lodash';
import { FORM_ERROR } from 'final-form';

import { DateUtil } from './date-util';

const ajv = new Ajv({
  allErrors: true,
  coerceTypes: 'string',
  format: 'full',
  jsonPointers: true,
});
AjvErrors(ajv);

ajv.addKeyword('lessThanEqualDate', {
  type: 'string',
  errors: true,
  validate: function validateLessThanEqualDate(
    maxDatePropNameAgainst,
    dateStr,
    parentSchema,
    currentDataPath,
    parentDataObject,
    parentPropName,
    rootData
  ) {
    const maxDateStr = get(rootData, maxDatePropNameAgainst);

    validateLessThanEqualDate.errors = [
      {
        keyword: 'lessThanEqualDateRequirement',
        message: `This must be earlier than or equal to end date`,
        params: { keyword: 'lessThanEqualDateRequirement' },
      },
    ];

    if (!maxDateStr || !dateStr) {
      return true;
    }

    return DateUtil.castAsMoment(maxDateStr)
      .startOf('day')
      .isSameOrAfter(DateUtil.castAsMoment(dateStr).startOf('day'));
  },
});

ajv.addKeyword('cannotBeInFuture', {
  type: 'string',
  errors: true,
  validate: function customValidation(schema, data) {
    const date = DateUtil.castAsMoment(data);
    const today = DateUtil.castAsMoment(new Date());

    customValidation.errors = [
      {
        keyword: 'cannotBeInFuture',
        message: 'This date cannot be in the future',
        params: { keyword: 'cannotBeInFuture' },
      },
    ];

    return date.isBefore(today);
  },
});

const translateMessages = error => {
  const { message } = error;
  switch (true) {
    case message === 'should NOT be shorter than 1 characters':
      return 'Required';
    default:
      return message;
  }
};

export const AjvUtil = {
  compileSchema(ajvSchema) {
    return ajv.compile(ajvSchema);
  },
  /**
   * Need to deal with following error messages with array items
   *
   *   // let vObj = {
    //   name: "Required",
    //   testers: [ ce
    //     undefined,
    //     {
    //       name: 'Jip'
    //     }
    //   ],
    // };
   // return vObj;
   */
  formatValidationForFinalForm: (compiledAjvSchema, data) => {
    /**
     * React Final Form is expecting undefined if form is valid
     */
    if (!compiledAjvSchema || compiledAjvSchema(data)) {
      return undefined;
    }

    return AjvUtil.formatValidationErrorsForFinalForm(compiledAjvSchema.errors);
  },
  formatValidationErrorsForFinalForm: errors =>
    errors.reduce((returnObj, error) => {
      let dataPath = error.dataPath.replace(/^[./]/, '');
      if (error.keyword === 'required') {
        const path =
          dataPath.length > 0
            ? `${dataPath}.${error.params.missingProperty}`
            : error.params.missingProperty;
        returnObj[path] = 'Required';
      } else {
        if (dataPath.length === 0) {
          dataPath = FORM_ERROR;
        }
        returnObj[dataPath] = translateMessages(error);
      }
      return returnObj;
    }, {}),
};
