import qs from 'qs';
import crypto from 'crypto-js/sha256';

import { EnvUtil } from './env-util';
import { DateUtil } from './date-util';

let pendoInitialized = false;

export const WindowUtil = {
  toLogin() {
    window.location.href = `${EnvUtil.loginUrl}?RelayState=${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;
  },
  exists() {
    /**
     * try/catch because next.js will throw error if trying to access window on the server >.<
     */
    try {
      return !!window;
    } catch (e) {
      return false;
    }
  },
  getUrlQuery() {
    return qs.parse(window.location.search.slice(1));
  },
  setUrlQuery(paramsObj, replace) {
    let hostUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const search = paramsObj && qs.stringify(paramsObj);
    if (search) {
      hostUrl += `?${search}`;
    }

    if (window.location.hash) {
      hostUrl += window.location.hash;
    }

    const method = replace ? 'replaceState' : 'pushState';
    if (window.history && window.history[method]) {
      window.history[method]({ path: hostUrl }, '', hostUrl);
    }
  },
  initializePendo({ user = {}, candidate = {} }) {
    if (WindowUtil.exists() && window.pendo && !pendoInitialized) {
      pendoInitialized = true;
      /**
       * Pendo doesnt init every reload without the delay
       */
      const role = user?.roles?.[0] || null;
      const userId = user.id;

      const {
        homeOffice,
        profileStatus,
        availabilityPreferencesUpdatedAt,
        employmentStatus,
        employeeType,
        isSpotlightedLawyer,
        barredLocations,
        practiceArea,
        occupationType,
        isProfileShared,
        id: candidateId,
        weeklyAvailability,
        soonestEngagementEndDate,
        isAccessibleToWorkFeed,
      } = candidate;

      setTimeout(() => {
        const isLPUser = role === 'LPUser';
        let daysSinceLastAvailabiltyUpdate = null;

        if (isLPUser) {
          const now = DateUtil.castAsMoment(new Date());
          const then =
            availabilityPreferencesUpdatedAt &&
            DateUtil.castAsMoment(availabilityPreferencesUpdatedAt);
          daysSinceLastAvailabiltyUpdate = then ? now.diff(then, 'days') : 0;
        }

        let barredAdmissions = 'Not Admitted';
        if (barredLocations?.length) {
          const bl = barredLocations.filter(
            b => b.name.toLowerCase() !== 'not admitted'
          );

          if (bl.length) barredAdmissions = 'Admitted';
        }

        let pA = null;
        if (practiceArea) {
          pA = practiceArea?.parent
            ? practiceArea.parent.name
            : practiceArea.name;
        }

        const payload = {
          visitor: {
            id: userId ? crypto(userId).toString() : null,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            apUserId: user?.id,
            candidateStatus: isLPUser ? profileStatus : null,
            role,
            daysSinceLastAvailabiltyUpdate,
            acceptedTOS: !!user.termsAndConditionsAcceptedAt,
            homeOfficeName: homeOffice?.name || null,
            homeOfficeRegion: homeOffice?.categories || null,
            employmentStatus,
            employeeType,
            isSpotlighted: isSpotlightedLawyer ? 'ALT opt in' : 'ALT opt out',
            barredAdmissions,
            practiceArea: pA,
            occupationType,
            isProfileShared: isProfileShared
              ? 'Open to Client previews'
              : 'Not open to Client previews',
            isAccessibleToWorkFeed,
          },
          account: {
            id: 'AxiomLaw',
          },
        };
        // eslint-disable-next-line no-console
        console.log('Initing Pendo As:', payload.visitor);
        window.pendo.initialize(payload);
        WindowUtil.pendoTrack('initial_visit', {
          candidateId,
          profileStatus,
          weeklyAvailability,
          soonestEngagementEndDate,
          isAccessibleToWorkFeed,
        });
      }, 1000);
    }
  },
  pendoTrack(name, data) {
    if (WindowUtil.exists()) {
      if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
        console.log('Pendo tracking event fired:', { name, data });
        return window.pendo.track(name, data);
      }
    }

    setTimeout(function () {
      WindowUtil.pendoTrack(name, data);
    }, 500);

    return null;
  },
  scrollToTop(elm) {
    if (this.exists()) {
      const options = {
        top: elm?.offsetTop || elm?.offsetParent?.offsetTop || 0,
        left: 0,
        behavior: 'smooth',
      };
      window.scrollTo(options);
    }
  },
};
