import {
  arrayOf,
  exact,
  string,
  oneOfType,
  number,
  oneOf,
  bool,
} from 'prop-types';

export const DropdownSelectedValueShape = oneOfType([
  string,
  number,
  arrayOf(string),
  bool,
]);

export const OptionShape = exact({
  group: DropdownSelectedValueShape,
  groupLabel: DropdownSelectedValueShape,
  type: oneOf(['skill', 'practicearea', 'specialty', 'industry']),

  label: DropdownSelectedValueShape.isRequired,
  value: DropdownSelectedValueShape.isRequired,
});

export const DropdownOptionsShape = arrayOf(OptionShape);
