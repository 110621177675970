import React from 'react';
import { arrayOf, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  ConfirmSubmitDialog,
  Button,
  Card,
  CardHeader,
  SmallHeader,
  Layout,
  LayoutItem,
  CardSection,
  Banner,
  ParagraphHeader,
  Paragraph,
  Suspense,
} from '@axiom/ui';

import SubmitStateHelperTreatment from '../SubmitStateHelperTreatment/SubmitStateHelperTreatment';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { EducationItemShape } from '../../models/education-item';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { FormEducationItemStore } from '../../stores/protected/form-education-item-store';
import { SubmitStateHelperShape } from '../../lib/submit-state-helper';

import { EducationItemEdit } from './EducationItemEdit';

const EducationComponent = ({
  educationItems,
  candidate,
  deleteEducationItemState,
  deleteEducationItem,
}) => (
  <Card name="education_container">
    <CardHeader>
      <Layout position="middle">
        <LayoutItem fluid>
          <SmallHeader>Education</SmallHeader>
        </LayoutItem>
        <LayoutItem>
          <Button
            name="educationItemsAddButton"
            pattern="secondary"
            variation="minimal"
            icon="plus"
            onClick={() => {
              FormEducationItemStore.clearState(null);
              FormEducationItemStore.load({}, candidate.id);
            }}
          >
            Add
          </Button>
        </LayoutItem>
      </Layout>
    </CardHeader>
    <Suspense>
      <SubmitStateHelperTreatment submitState={deleteEducationItemState}>
        <FormStateTreatment
          name="NEW_EDUCATION_ITEM_FORM_TREATMENT"
          dataId={null}
          dataIdName="id"
          formStore={FormEducationItemStore}
          renderNonLoadedView={() =>
            educationItems.length === 0 && (
              <CardSection>
                <Banner impact="low" type="info">
                  No education added
                </Banner>
              </CardSection>
            )
          }
          renderLoadedView={({
            formData: { institution },
            fireSubmit,
            fireCancel,
            form,
          }) => (
            <CardSection divider>
              <EducationItemEdit
                institution={institution}
                onSubmit={fireSubmit}
                onCancel={fireCancel}
                form={form}
              />
            </CardSection>
          )}
        />
        {educationItems.map(educationItem => (
          <CardSection key={educationItem.id} divider>
            <FormStateTreatment
              name="EDUCATION_ITEM_FORM_TREATMENT"
              dataId={educationItem.id}
              dataIdName="id"
              formStore={FormEducationItemStore}
              renderNonLoadedView={() => (
                <>
                  <Layout position="middle">
                    <LayoutItem fluid>
                      <ParagraphHeader>
                        {educationItem.institution}
                      </ParagraphHeader>
                    </LayoutItem>
                    <LayoutItem>
                      <Layout horizontalGutter="8px" position="middle">
                        <ConfirmSubmitDialog
                          yesButtonText="Delete"
                          size="confirm"
                          modalTitle="Delete This Education"
                          modalBody="Deleting this education removes it from your Axiom for Talent Profile and the bio that Axiom shares with prospective clients."
                          Trigger={
                            <IconButton
                              icon="trash"
                              pattern="secondary"
                              variation="minimal"
                              disabled={educationItem.isPrimary}
                            />
                          }
                          onYes={() => deleteEducationItem(educationItem.id)}
                        />
                        <IconButton
                          icon="pencil"
                          pattern="secondary"
                          variation="minimal"
                          onClick={() =>
                            FormEducationItemStore.load(
                              educationItem,
                              candidate.id
                            )
                          }
                        />
                      </Layout>
                    </LayoutItem>
                  </Layout>
                  <Paragraph>
                    {[
                      educationItem.degree,
                      educationItem.areaOfStudy?.name,
                      educationItem.yearAwarded &&
                        `${
                          educationItem.yearAwarded > new Date().getFullYear()
                            ? `expected completion ${educationItem.yearAwarded}`
                            : `${educationItem.yearAwarded}`
                        }`,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </Paragraph>
                </>
              )}
              renderLoadedView={({
                formData: { institution },
                fireSubmit,
                fireCancel,
                form,
              }) => (
                <EducationItemEdit
                  institution={institution}
                  onSubmit={fireSubmit}
                  onCancel={fireCancel}
                  form={form}
                />
              )}
            />
          </CardSection>
        ))}
      </SubmitStateHelperTreatment>
    </Suspense>
  </Card>
);

EducationComponent.propTypes = {
  candidate: CurrentCandidateStore.getDataShape().isRequired,
  deleteEducationItemState: SubmitStateHelperShape.isRequired,
  educationItems: arrayOf(EducationItemShape).isRequired,
  deleteEducationItem: func.isRequired,
};

export const Education = connect(
  state => ({
    deleteEducationItemState:
      CurrentCandidateStore.selectDeleteEducationItemState(state),
    educationItems: CurrentCandidateStore.selectEducationItems(state),
  }),
  {
    deleteEducationItem: CurrentCandidateStore.actionDeleteEducationItem,
  }
)(EducationComponent);
