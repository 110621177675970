import React, { useContext } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

import { AccordionContext } from './AccordionContext';

export type AccordionSectionProps = {
  children: React.ReactNode;
  className?: string;
  name?: string;
};

export const AccordionSection = ({
  children,
  className,
  name,
}: AccordionSectionProps) => {
  const { expanded } = useContext(AccordionContext);
  if (!expanded) {
    return null;
  }

  return (
    <div
      className={AttrsHelper.formatClassname('accordion-section', className)}
      data-test={name}
    >
      {children}
    </div>
  );
};
