import { GenericDto } from './generic-dto';

export const EducationDto = class EducationDto extends GenericDto {
  constructor(body) {
    super(body, [
      'candidateId',
      'degree',
      'isPrimary',
      'lawSchoolId',
      'yearAwarded',
      'areaOfStudyId',
    ]);
  }
};
