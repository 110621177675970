import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, string, func } from 'prop-types';
import { ConfirmSubmitDialog, IconButton, Layout, LayoutItem } from '@axiom/ui';

import {
  CandidateLanguageShape,
  LanguageShape,
} from '../../models/language-model';
import { FormLanguageStore } from '../../stores/protected/form-profile-detail-languages-store';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { LanguagesStore } from '../../stores/protected/languages-store';
import { LanguageUtil } from '../../utils/language-util';
import { CandidateLanguagesStore } from '../../stores/protected/candidate-languages-store';

const LanguageItemsDisplayComponent = ({
  formKey,
  candidateId,
  languages,
  candidateLanguages,
  editLanguage,
  allCandidateLanguages,
  deleteLanguage,
}) => (
  <Layout position="middle">
    <LayoutItem fluid>
      {LanguageUtil.buildLanguageDisplayBars(candidateLanguages)}
    </LayoutItem>
    <LayoutItem>
      <Layout horizontalGutter="8px" position="middle">
        <ConfirmSubmitDialog
          yesButtonText="Delete"
          size="tiny"
          modalTitle="Delete This Language"
          modalBody="Deleting this language removes it from your Axiom for Talent Profile and the bio that Axiom shares with prospective clients."
          Trigger={
            <IconButton icon="trash" pattern="secondary" variation="minimal" />
          }
          onYes={() =>
            deleteLanguage(
              languages,
              allCandidateLanguages,
              candidateLanguages[0],
              candidateId
            )
          }
        />
        <IconButton
          icon="pencil"
          pattern="secondary"
          variation="minimal"
          onClick={() => editLanguage(candidateId, formKey, candidateLanguages)}
        />
      </Layout>
    </LayoutItem>
  </Layout>
);

LanguageItemsDisplayComponent.propTypes = {
  languages: arrayOf(LanguageShape).isRequired,
  candidateLanguages: arrayOf(CandidateLanguageShape).isRequired,
  allCandidateLanguages: arrayOf(CandidateLanguageShape).isRequired,
  formKey: string.isRequired,
  editLanguage: func.isRequired,
  deleteLanguage: func.isRequired,
  candidateId: string.isRequired,
};

export const LanguageItemsDisplay = connect(
  state => ({
    languages: LanguagesStore.selectLanguages(state),
    candidateId: CurrentCandidateStore.selectCandidate(state).candidateId,
    allCandidateLanguages: CandidateLanguagesStore.selectLanguages(state),
  }),
  {
    editLanguage: FormLanguageStore.actionShowForm,
    deleteLanguage: CandidateLanguagesStore.actionDeleteLanguages,
  }
)(LanguageItemsDisplayComponent);
