import React from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { Education } from '@axiom/const';
import {
  Button,
  FluidButtonLayout,
  Gutter,
  LabelWrapper,
  Layout,
  LayoutItem,
  useApi,
} from '@axiom/ui';

import { FFLawSchoolsAutoComplete } from '../FFLawSchoolsAutoComplete/FFLawSchoolsAutoComplete';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { FFDropdownList } from '../FFDropdownList/FFDropdownList';
import { DateUtil } from '../../utils/date-util';
import { AreasOfStudyApi } from '../../api/protected/areas-of-study/areas-of-study';

const educationYears = DateUtil.getYearOptionsByRange(
  new Date().getFullYear() + 10,
  1950
);

function getAreaOfStudyOptions(areasOfStudy) {
  const arr = [{ label: '', value: null }];

  areasOfStudy.forEach(area => {
    arr.push({ label: area.name, value: area.id });
  });

  return arr.sort((a, b) => (a.label > b.label ? 1 : -1));
}

const EducationItemEditComponent = ({
  candidate,
  onSubmit,
  onCancel,
  institution,
  form,
}) => {
  const [{ data: areasOfStudy }] = useApi(AreasOfStudyApi.readAreasOfStudy());

  const degreeDropdownOptions = Object.values(Education.DEGREES).reduce(
    (degreeOptions, degreeOption) => {
      // omit JD unless none exists or it's already in the form
      if (
        degreeOption === Education.DEGREES.JD &&
        form.getState().values.degree !== Education.DEGREES.JD &&
        candidate.educationItems.some(i => i.degree === Education.DEGREES.JD)
      ) {
        return degreeOptions;
      }
      degreeOptions.push(degreeOption);
      return degreeOptions;
    },
    []
  );
  return (
    <>
      <Field
        label="Institution"
        name="lawSchoolId"
        component={FFLawSchoolsAutoComplete}
        placeholder={institution}
      />
      <Layout position="space-between" horizontalGutter="16px">
        <LayoutItem fluid>
          <LabelWrapper name="PENDO-CHOOSE-AREA-OF-STUDY" label="Area Of Study">
            <Gutter top="4px" />
            <Field
              name="areaOfStudyId"
              component={FFDropdownList}
              options={getAreaOfStudyOptions(areasOfStudy)}
            />
          </LabelWrapper>
        </LayoutItem>
        <LayoutItem fluid>
          <LabelWrapper label="Year">
            <Gutter top="4px" />
            <Field
              name="yearAwarded"
              component={FFDropdownList}
              options={educationYears}
            />
          </LabelWrapper>
        </LayoutItem>
        <LayoutItem fluid>
          <LabelWrapper label="Degree">
            <Gutter top="4px" />
            <Field
              name="degree"
              component={FFDropdownList}
              options={degreeDropdownOptions}
              onChange={degree => {
                form.change('degree', degree);
                form.change('stateExamScore', null);
              }}
            />
          </LabelWrapper>
        </LayoutItem>
      </Layout>
      <FluidButtonLayout>
        <Button
          name="educationEditCancelButton"
          variation="outline"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button type="submit" name="educationEditSaveButton" onClick={onSubmit}>
          Save
        </Button>
      </FluidButtonLayout>
    </>
  );
};

EducationItemEditComponent.defaultProps = {
  institution: undefined,
};

EducationItemEditComponent.propTypes = {
  candidate: CurrentCandidateStore.getDataShape().isRequired,
  institution: string,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  form: shape({
    change: func.isRequired,
  }).isRequired,
};

export const EducationItemEdit = connect(state => ({
  candidate: CurrentCandidateStore.selectCandidate(state),
}))(EducationItemEditComponent);
