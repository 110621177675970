import React from 'react';

import { ComponentShape } from '../../utils/prop-type-util';
import { SubmitStateHelperShape } from '../../lib/submit-state-helper';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { PositionRelative } from '../../themes/components';
import Toast from '../Toast/Toast';

/**
 * Use this for handling when a SubmitState is submitting
 *
 * Assuming you connect selectors look like this:
 * export default connect(state => ({
    donutState: DonutsStore.selectSubmitDonutState(state)
  }))(ListOfDonutsPage);

 *
 * <SubmitStateHelperTreatment submitState={donutState}>
 *   Im some data
 *  </SubmitStateHelperTreatment>
 *
 *  This will handle showing the loader while the submit is happening.
 */

const SubmitStateHelperTreatment = ({ submitState, children }) => {
  if (submitState.errorData) {
    /**
     * Need this console log, else it'll get swallowed.
     */
    // eslint-disable-next-line no-console
    console.error(submitState.errorData);
  }

  return (
    <PositionRelative>
      {submitState.errorData && (
        <Toast>An error happened with your request. Please try again.</Toast>
      )}
      {submitState.isSubmitting && <LoadingCurtain />}
      {children}
    </PositionRelative>
  );
};

SubmitStateHelperTreatment.propTypes = {
  submitState: SubmitStateHelperShape,
  children: ComponentShape,
};

SubmitStateHelperTreatment.defaultProps = {
  submitState: {},
  children: null,
};

export default SubmitStateHelperTreatment;
