import { useEffect } from 'react';
import { useApi } from '@axiom/ui';
import { useNavigate } from 'react-router-dom';

import { CandidateApi } from '../../api/protected/candidates/candidate';
import { UserApi } from '../../api/protected/user/user';

// Only used in conjunction with DefaultPage.tsx. This will redirect eligible LPUsers to the WorkFeed
// Non-LPUsers will be handled by default error messaging provided by the TalentDataProvider
export const WorkFeedRedirect = (): null => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId } = user;
  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));
  const navigate = useNavigate();

  useEffect(() => {
    if (candidate.isAccessibleToWorkFeed) {
      navigate('./talent/opportunities');
    } else {
      navigate('./talent/profile');
    }
  });

  return null;
};
