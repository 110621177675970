import React from 'react';
import {
  Gutter,
  Banner,
  Flashy,
  Paragraph,
  ParagraphHeader,
  useApi,
} from '@axiom/ui';
import { Candidate, CandidateWorkFeedItem } from '@axiom/validation';
import { CandidateOpportunitiesConst, Opportunity } from '@axiom/const';

import { WorkFeedUtil } from '../../utils/work-feed-util';
import { RejectionLossCodesUtil } from '../../utils/rejection-loss-codes-util';
import { CandidateOpportunitiesLegacyApi } from '../../api/protected/candidates/candidate-opportunities-legacy';

const { CLOSED_LOST, CLOSED_WON } = Opportunity;
const { CandidateStatuses } = CandidateOpportunitiesConst;

export const WorkFeedCardMessages = ({
  workFeedItem,
  candidate,
}: {
  workFeedItem: CandidateWorkFeedItem;
  candidate: Candidate;
}) => {
  const [{ data: candidateOpportunities }] = useApi(
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidate.id)
  );

  const candidateOpportunity = candidateOpportunities.find(
    co => co.opportunityId === workFeedItem.id
  );

  const { isSubmitted, isSelected, isInterested } =
    WorkFeedUtil.getWorkFeedCandidateStatuses(workFeedItem);

  const candidateStatusesForClientRejectedMessages = [
    CandidateStatuses.Cooled,
    CandidateStatuses.Rejected,
  ];

  // Rejected by Client Messaging
  if (
    RejectionLossCodesUtil.rejectedByClientMessages[
      candidateOpportunity?.rejectionLossCode
    ] &&
    candidateStatusesForClientRejectedMessages.includes(
      workFeedItem.candidateStatus
    )
  ) {
    return (
      <Gutter bottom="16px">
        <Banner name="REJECTED_MESSAGE">
          {
            RejectionLossCodesUtil.rejectedByClientMessages[
              candidateOpportunity?.rejectionLossCode
            ]
          }
        </Banner>
      </Gutter>
    );
  }

  // Rejected Messaging
  if (RejectionLossCodesUtil.defaultMessages[workFeedItem.rejectionLossCode]) {
    return (
      <Gutter bottom="16px">
        <Banner name="REJECTED_MESSAGE">
          {
            RejectionLossCodesUtil.defaultMessages[
              workFeedItem.rejectionLossCode
            ]
          }
        </Banner>
      </Gutter>
    );
  }

  // Opportunity is Closed Won but candidate was not selected
  if (workFeedItem.opportunity.stage === CLOSED_WON && !isSelected) {
    return (
      <Gutter bottom="16px">
        <Banner name="CLOSED_WON_MESSAGE">
          <Paragraph>
            <Flashy color="textSecondary">
              <ParagraphHeader as="span">
                It's not you, it's us.
              </ParagraphHeader>
            </Flashy>{' '}
            This opportunity was very popular, and the role was filled before we
            could consider all interested talent. We know this isn't ideal and
            we're working on it. Thanks for your patience and we encourage you
            to keep checking the feed for more opportunities like this.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  // Closed Lost OR opp is hidden OR BE flag
  if (
    workFeedItem.opportunity.stage === CLOSED_LOST ||
    workFeedItem.opportunity.isExcludedFromFeed ||
    WorkFeedUtil.isUnavailableToCandidate(workFeedItem)
  ) {
    return (
      <Gutter bottom="16px">
        <Banner name="CLOSED_LOST_MESSAGE">
          <Paragraph>
            <Flashy color="textSecondary">
              <ParagraphHeader as="span">
                The opportunity is no longer available
              </ParagraphHeader>
            </Flashy>{' '}
            due to a change in client needs. This can happen for many reasons
            but some of the top ones include an internal hire, reduction of
            budget eliminating the need for talent, or client simply stopped
            replying.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  // Interested Message
  if (isInterested) {
    return (
      <Gutter bottom="16px">
        <Banner name="INTERESTED_MESSAGE" impact="high" type="critical">
          <Paragraph>
            <Flashy color="textBrand">
              <ParagraphHeader as="span">We are on it!</ParagraphHeader>
            </Flashy>{' '}
            Axiom is carefully considering your profile for this opportunity.
            When we have an update we'll post it right here so please check
            back.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  // Submitted Message
  if (isSubmitted || isSelected) {
    return (
      <Gutter bottom="16px">
        <Banner name="SUBMITTED_MESSAGE" impact="high" type="critical">
          <Paragraph>
            <Flashy color="textBrand">
              <ParagraphHeader as="span">Great news!</ParagraphHeader>
            </Flashy>{' '}
            Your profile has been submitted for the client's consideration. You
            can track the status of this opportunity in Submissions on your
            Engagements page.
          </Paragraph>
        </Banner>
      </Gutter>
    );
  }

  return null;
};
