import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const QuickLinksWrapper = styled.div`
  text-transform: uppercase;
  margin: 0 !important;
  width: 100%;
  min-width: ${props => (props.minWidth ? props.minWidth : 'inherit')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'inherit')};

  .quick-links {
    margin: 0;
    min-width: ${props => (props.minWidth ? props.minWidth : 'inherit')};
    max-width: ${props => (props.maxWidth ? props.maxWidth : 'inherit')};
  }

  ${props =>
    props.fixedLeftAmount
      ? `
          left: ${props.fixedLeftAmount};
        `
      : `
          left: calc(16vw + 33px);
        `}
  ${props =>
    props.isFloating === true
      ? `
        position: fixed;
        top: ${props.floatingTopAmount};
        z-index: 3;
        display: inline-block;
        height: ${props.floatingHeight};
        overflow-y: auto;
      `
      : MediaQueryUtil.smallScreenOnly(css`
          padding-left: 0px;
        `)}

  ${MediaQueryUtil.smallScreenOnly(css`
    display: none !important;
    opacity: 0 !important;
  `)}
`;

export const QuickLinksUlWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  li {
    list-style-type: none;
    font-weight: bold;
    line-height: 1rem;
    padding-bottom: 1rem;
  }
`;

export const QuickLinksCaret = styled.span`
  position: absolute;
  background-color: #1c222e;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  & > a {
    height: 100%;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${props =>
    props.isActive === true
      ? `
        display: block;
      `
      : `
        display: none;
      `};
`;
