import { arrayOf } from 'prop-types';

import GenericStore from '../../classes/generic-store';
import { getLawSchools } from '../../api/lawSchools';
import { lawSchoolShape } from '../../models/lawSchool';

class LawSchoolsStoreClass extends GenericStore {
  getDataShape() {
    return arrayOf(lawSchoolShape);
  }

  load({ searchTerm, filters, page, results = [] } = {}) {
    return this.setState(
      getLawSchools({ search: searchTerm, filters, page }).then(data => ({
        page: data.meta.currentPage,
        results: [...results, ...(data.results || [])],
        totalResultsCount: data.meta.resultCount,
        searchTerm,
        filters,
      }))
    );
  }

  selectData(state) {
    return super.select(state).data || [];
  }
}

export const LawSchoolsStore = new LawSchoolsStoreClass();
