import React from 'react';
import { WarningIcon } from '@axiom/ui';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PrintStylesWrapper } from '../themes/components';
import {
  BannerIconWrapper,
  StyledBanner,
} from '../components/Banner/BannerStyles';
import { PALETTE } from '../themes/constants';
import SharedBioDownloadMessage from '../components/SharedBioDownloadMessage/SharedBioDownloadMessage';
import BioView from '../components/BioView/BioView';
import { PageLayout } from '../layouts/PageLayout';

export const AnonymousBioPage = () => (
  <TalentDataProvider>
    <PrintStylesWrapper>
      <StyledBanner
        className="hide-print"
        style={{ marginTop: '1px' }}
        backgroundColor={PALETTE.colors.sky}
        textColor="black"
      >
        <BannerIconWrapper>
          <WarningIcon
            width={28}
            height={24}
            name="bannerAlert"
            backgroundColor="black"
            color={PALETTE.colors.sky}
          />
        </BannerIconWrapper>
        <SharedBioDownloadMessage />
      </StyledBanner>
      <PageLayout name="ANONPAGE">
        <BioView isAnonView />
      </PageLayout>
    </PrintStylesWrapper>
  </TalentDataProvider>
);
