import React, { useEffect, useState } from 'react';

import { Card, CardProps } from '../Card/Card';

import { DrawerContext } from './DrawerContext';

export type DrawerProps = {
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  name?: string;
  shadow?: CardProps['shadow'];
};

export const Drawer = ({
  children,
  className,
  expanded,
  name,
  shadow = 'default',
}: DrawerProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <Card
      name={name}
      shadow={shadow}
      background={shadow ? 'light' : null}
      className={className}
    >
      <DrawerContext.Provider
        value={{
          expanded: isExpanded,
          toggle: () => {
            setIsExpanded(!isExpanded);
          },
        }}
      >
        {children}
      </DrawerContext.Provider>
    </Card>
  );
};
