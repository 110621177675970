import Ajv from 'ajv';
import { CandidateSchema } from '@axiom/validation';
import { z } from 'zod';
import { CandidatesConst } from '@axiom/const';

import { axiomValidationOptions } from './options';

const BADGES = Object.values(CandidatesConst.Badges);

export const ExternalCandidateSchema = CandidateSchema.pick({
  id: true,
  addressCity: true,
  addressCountry: true,
  addressState: true,
  candidateHeader: true,
  occupationType: true,
  practiceArea: true,
  profileImageKey: true,
  profileImageName: true,
  publicCandidateSummary: true,
  calculatedDisplayName: true,
  yearsOfExperience: true,
  isSpotlightedLawyer: true,
  hasRate: true,
  barredLocations: true,
  publicRefId: true,
  industries: true,
  weeklyAvailability: true,
  lawDegreeSchools: true,
  lawFirms: true,
  isViewOnly: true,
  isAvailableToConsult: true,
}).extend({
  badges: z.array(z.enum(BADGES)),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const idOrPublicRefIdValidation = {
  anyOf: [
    {
      type: 'string',
      format: 'uuid',
    },
    {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
  ],
};

export const idOrPublicRefIdValidator = ajv.compile(idOrPublicRefIdValidation);
