import React, { useEffect, useState } from 'react';
import { AttrsHelper } from '@axiom/ui/src/sb-helpers/attrs-helper';

export type ImageCircleProps = {
  background?: 'blue' | 'light blue';
  src?: string;
  imageName: string;
  name?: string;
  size?: 'large' | 'default' | 'small' | 'thumbnail';
};
export const ImageCircle = ({
  background = 'blue',
  src,
  imageName,
  name,
  size = 'default',
}: ImageCircleProps) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const nameSplit = imageName.split(' ');
  const imageInitials = imageName.split(' ').reduce((acc, word, index) => {
    if (word && (index === 0 || index === nameSplit.length - 1)) {
      acc += word[0];
    }

    return acc;
  }, '');

  useEffect(() => {
    if (src) {
      try {
        const getImageAsBase64 = async (url: string) => {
          const rawImg = await fetch(url);

          if (!rawImg.ok) {
            return setImageLoadFailed(true);
          }

          const img = await rawImg.blob();
          const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(img);
          });

          return setBase64Image(base64);
        };

        getImageAsBase64(src);
      } catch (e) {
        setImageLoadFailed(true);
      }
    } else {
      setBase64Image(null);
      setImageLoadFailed(null);
    }
  }, [src]);

  return (
    <span
      className={AttrsHelper.formatClassname(
        'image-circle',
        `background-${background.replace(' ', '-')}`,
        `size-${size}`
      )}
      data-test={name}
    >
      {(!src || imageLoadFailed === true) && (
        <span className="image-initials">{imageInitials}</span>
      )}
      {src && base64Image && imageLoadFailed !== true && (
        <img alt={imageName} src={base64Image} data-test="IMAGE" />
      )}
    </span>
  );
};
