import React from 'react';
import {
  Brief,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  Icon,
  Layout,
  RichText,
} from '@axiom/ui';
import { CandidateWorkFeedItem } from '@axiom/validation';

import { WorkFeedUtil, WorkFeedViewsType } from '../../utils/work-feed-util';

import { DescriptionBlock } from './WorkFeedCardJobDescriptionStyles';

export const WorkFeedCardJobDescription = ({
  workFeedItem,
  view,
}: {
  workFeedItem: CandidateWorkFeedItem;
  view: WorkFeedViewsType;
}) => {
  const jobDescription = (workFeedItem.position.description ?? '').trim();
  return (
    <div data-test="JOB_DESCRIPTION">
      <Layout position="middle" horizontalGutter="8px">
        {!jobDescription && <Icon name="clock-filled" />}
        <CondensedHeader>Work Description</CondensedHeader>
      </Layout>
      <Gutter bottom="4px" />
      <Brief
        name="JOB_DESCRIPTION_BRIEF"
        briefMaxHeight="143px"
        pattern="secondary"
        expanded={WorkFeedUtil.isSinglePageView(view)}
      >
        <CondensedLarge name={!jobDescription ? 'missing-jd' : 'DESCRIPTION'}>
          <DescriptionBlock>
            <RichText
              plainText={
                jobDescription ||
                'This opportunity is not accepting expressions of interest. Save it to receive an alert if it starts accepting expressions of interest.'
              }
            />
          </DescriptionBlock>
        </CondensedLarge>
      </Brief>
    </div>
  );
};
