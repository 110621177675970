import React from 'react';
import { number } from 'prop-types';

import { ComponentShape } from '../../utils/prop-type-util';

import { ToastBody, ToastWrapper } from './ToastStyles';

export default class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: true,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        showing: false,
      });
    }, this.props.showFor);
  }

  componentWillUnmount() {
    if (typeof this.timeout === 'function') {
      this.timeout();
    }
  }

  render() {
    if (!this.state.showing) {
      return null;
    }

    const { children } = this.props;

    return (
      <ToastWrapper>
        <ToastBody>{children}</ToastBody>
      </ToastWrapper>
    );
  }
}

Toast.propTypes = {
  showFor: number,
  children: ComponentShape.isRequired,
};
Toast.defaultProps = {
  showFor: 4000,
};
