/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';
import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';
import { z } from 'zod';
import { SchemaLocation } from '@axiom/types';

declare global {
  interface Window {
    google: {
      maps: typeof google.maps;
    };
  }
}

type PlaceResult = {
  address_components: Array<unknown>;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  name: string;
  place_id: string;
};

type SchemaLocation = z.infer<typeof SchemaLocation>;

const DefaultLocation = {
  locationLatitude: 40.7127753,
  locationLongitude: -74.0059728,
};

export const useGoogleMaps = ({
  googleApiUrl,
  mapRef,
  locationLatitude = DefaultLocation.locationLatitude,
  locationLongitude = DefaultLocation.locationLongitude,
}: {
  googleApiUrl: string;
  mapRef: React.MutableRefObject<unknown>;
  locationLatitude?: number;
  locationLongitude?: number;
}) => {
  const googleMapApiService = useRef({
    autocomplete: null,
    map: null,
    marker: null,
    places: null,
    requests: {
      clear: (): void => {
        const { marker } = googleMapApiService.current;
        marker.setMap(null);
        marker.setPosition(null);
      },
      getPlaceSuggestions: (
        placeName: string
      ): Promise<Array<SchemaLocation>> => {
        return new Promise<Array<SchemaLocation>>((resolve, reject) => {
          const { autocomplete, sessionToken } = googleMapApiService.current;
          autocomplete.getPlacePredictions(
            {
              input: placeName,
              sessionToken,
            },
            (
              predictions: Array<{
                description: string;
                place_id: string;
              }>,
              status: string
            ) => {
              if (status === 'OK') {
                const formattedResults = predictions.map(prediction => ({
                  locationName: prediction.description,
                  locationPlaceId: prediction.place_id,
                }));
                resolve(formattedResults);
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject([]);
              }
            }
          );
        });
      },
      getPlaceDetails: (placeLocationId: string): Promise<SchemaLocation> => {
        const { map, marker, places, sessionToken } =
          googleMapApiService.current;
        return new Promise<SchemaLocation>((resolve, reject) => {
          places.getDetails(
            { placeId: placeLocationId, sessionToken },
            (placeDetails: PlaceResult, status: string) => {
              if (status === 'OK') {
                map.setCenter(placeDetails.geometry.location);
                marker.setMap(null);
                marker.setPosition(placeDetails.geometry.location);
                setTimeout(() => marker.setMap(map), 0);

                googleMapApiService.current.sessionToken =
                  new google.maps.places.AutocompleteSessionToken();
                resolve({
                  locationAddressComponents: {
                    addresses: placeDetails.address_components,
                  },
                  locationName: placeDetails.name,
                  locationLatitude: placeDetails.geometry.location.lat(),
                  locationLongitude: placeDetails.geometry.location.lng(),
                  locationPlaceId: placeDetails.place_id,
                });
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(null);
              }
            }
          );
        });
      },
    },
    sessionToken: null,
  });

  useEffect(() => {
    const loader = new GoogleApiLoader({
      apiKey: new URL(googleApiUrl).searchParams.get('key'),
      libraries: ['places', 'marker'],
      region: 'US',
      language: 'en',
    });
    loader.load().then(() => {
      const { google } = window;

      const mapInstance = new google.maps.Map(mapRef.current as HTMLElement, {
        center: {
          lat: locationLatitude,
          lng: locationLongitude,
        },
        controlSize: 22,
        draggable: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        mapTypeId: 'roadmap',
        zoom: 13,
        disableDoubleClickZoom: true,
        zoomControl: false,
      });
      googleMapApiService.current.autocomplete =
        new google.maps.places.AutocompleteService();
      googleMapApiService.current.map = mapInstance;
      googleMapApiService.current.marker = new google.maps.Marker({
        map: mapInstance,
      });
      googleMapApiService.current.places = new google.maps.places.PlacesService(
        mapInstance
      );
      googleMapApiService.current.sessionToken =
        new google.maps.places.AutocompleteSessionToken();
    });
  }, []);

  return googleMapApiService.current;
};
