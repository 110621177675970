import React, { useEffect, useRef, useState } from 'react';
import { Card, CardHeader } from '@axiom/ui';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { useWindowResize } from '../../../hooks/use-window-resize';

import { TabProps } from './Tab';

export type TabsProps = {
  appendToRight?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  fluid?: boolean;
  name?: string;
  scrollable?: boolean;
  stretched?: boolean;
  startTab?: string;
  variant?: 'default' | 'card';
};

export const Tabs = ({
  appendToRight,
  children,
  className,
  fluid,
  name,
  scrollable,
  stretched,
  startTab,
  variant = 'default',
}: TabsProps) => {
  const windowResized = useWindowResize();
  const tabChildren = React.Children.toArray(children) as Array<
    React.ReactElement<TabProps>
  >;
  const beginWithTab =
    startTab || (tabChildren.length && tabChildren[0].props.name);

  const tooManyChildren = tabChildren.length > 4;
  const [activeTab, setActiveTab] = useState(beginWithTab);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(!tooManyChildren);
  const tabsRef = useRef(null);
  const containerTabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef?.current && !fluid) {
      const { offsetWidth: containerOffsetWidth } = containerTabsRef.current;
      // If you change these values, change in less side as well
      const overflows =
        tabChildren.length * 220 + (tabChildren.length - 1) * 12 >=
        containerOffsetWidth;
      setIsOverflowing(overflows);
    }
  }, [tabsRef, windowResized]);

  useEffect(() => {
    if (startTab) {
      setActiveTab(startTab);
    }
  }, [startTab]);

  const tabBodyRender = React.Children.map(
    children,
    (tab: React.ReactElement<TabProps>) => {
      if (activeTab !== tab.props.name) {
        return null;
      }
      return (
        <div
          key={tab.props.name}
          data-test={`${tab.props.name}-tab-content`}
          className={AttrsHelper.formatClassname(
            'tab-content',
            'tab-content-active',
            stretched && 'tab-stretched'
          )}
        >
          {tab.props.children}
        </div>
      );
    }
  );
  return (
    <div
      className={AttrsHelper.formatClassname(
        'tabs',
        isOverflowing && 'tabs-too-large',
        tooManyChildren && !fluid && 'tabs-too-many',
        isOpen && 'tabs-are-open',
        fluid && 'tabs-fluid',
        scrollable && 'tabs-scrollable',
        className,
        stretched && 'tabs-stretched'
      )}
      data-test={name}
    >
      <Card>
        <CardHeader>
          <div className="tabs-boundary" ref={containerTabsRef}>
            <div
              className={AttrsHelper.formatClassname(
                'tabs-container',
                !appendToRight && 'no-appends'
              )}
              data-test="tabs-container"
              ref={tabsRef}
            >
              {React.Children.map(
                children,
                (tab: React.ReactElement<TabProps>) => {
                  return (
                    <tab.type
                      key={tab.props.name}
                      name={tab.props.name}
                      label={tab.props.label}
                      countBadge={tab.props.countBadge}
                      icon={
                        isOverflowing && !isOpen && tooManyChildren
                          ? 'arrow-down'
                          : tab.props.icon
                      }
                      className={AttrsHelper.formatClassname(
                        activeTab === tab.props.name && 'tab-active',
                        tab.props.className
                      )}
                      onClick={() => {
                        if (tooManyChildren) {
                          if (isOpen) {
                            setIsOpen(false);
                          } else {
                            setIsOpen(tab.props.name === activeTab);
                          }
                        }
                        setActiveTab(tab.props.name);
                        tab.props.onClick?.();
                      }}
                    />
                  );
                }
              )}
            </div>
            {appendToRight && (
              <div className="append-to-tabs">{appendToRight}</div>
            )}
          </div>
        </CardHeader>
        {variant === 'card' && tabBodyRender}
      </Card>
      {variant === 'default' && tabBodyRender}
    </div>
  );
};
