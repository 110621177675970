/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, shape, arrayOf, any } from 'prop-types';

import { FlexLeftWrapper, FlexWrapper } from '../../themes/components';
import { FFError, isErroring } from '../FFError/FFError';
import ComboBox from '../ComboBox/ComboBox';

class ComboBoxFF extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
    };
  }

  onFilterChange = ({ filter }) => {
    this.setState({ filterText: filter.value });
  };

  onChange = ({ target: { value: dataItem } }) => {
    const { dataItemKey, input } = this.props;
    const finalFormValue =
      dataItemKey && dataItem !== null ? dataItem[dataItemKey] : dataItem;
    input.onChange(finalFormValue);
  };

  onBlur = ({ target: { value: dataItem } }) => {
    const { dataItemKey, input } = this.props;
    const finalFormValue =
      dataItemKey && dataItem !== null ? dataItem[dataItemKey] : dataItem;
    input.onBlur(finalFormValue);
  };

  render() {
    const { input, meta, options, dataItemKey, textField, ...rest } =
      this.props;
    const { filterText } = this.state;

    const kendoValue = options.find(dataItem => {
      const dataItemValue = dataItemKey ? dataItem[dataItemKey] : dataItem;
      return dataItemValue === input.value;
    });

    const filteredData = options.filter(dataItem => {
      const dataItemLabel = textField ? dataItem[textField] : dataItem;
      return dataItemLabel.toLowerCase().includes(filterText.toLowerCase());
    });

    return (
      <>
        <ComboBox
          value={kendoValue}
          dataItemKey={dataItemKey}
          textField={textField}
          filterable
          data={filteredData}
          invalid={isErroring(meta)}
          onFilterChange={this.onFilterChange}
          onChange={this.onChange}
          onBlur={this.onBlur}
          {...rest}
        />
        <FlexWrapper>
          <FlexLeftWrapper>
            <FFError finalFormElementMeta={meta} />
          </FlexLeftWrapper>
        </FlexWrapper>
      </>
    );
  }
}

ComboBoxFF.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: arrayOf(any).isRequired,
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  dataItemKey: string,
  textField: string,
};

ComboBoxFF.defaultProps = {
  dataItemKey: null,
  textField: null,
};

export default ComboBoxFF;
