/** Education */
const JD = 'JD';
const MBA = 'MBA';
const BS = 'BS';
const BA = 'BA';
const BCL = 'BCL';
const MS = 'MS';
const MA = 'MA';
const MD = 'MD';
const PHD = 'PHD';
const LLB = 'LLB';
const LLM = 'LLM';
const GDL = 'GDL';
const LPC = 'LPC';
const QLTS = 'QLTS';
const BVC = 'BVC';
const PCLL = 'PCLL';
const MLIS = 'MLIS';

// eslint-disable-next-line import/prefer-default-export
export const Education = {
  DEGREES: {
    JD,
    MBA,
    BS,
    BA,
    MS,
    MA,
    MD,
    PHD,
    BCL,
    LLB,
    LLM,
    GDL,
    LPC,
    QLTS,
    BVC,
    PCLL,
    MLIS,
  },
  LAWDEGREES: {
    JD,
    LLB,
    LLM,
  },
  /** field names */
  CANDIDATE_ID: 'candidateId',
  CREATED_AT: 'createdAt',
  DEGREE: 'degree',
  INSTITUTION: 'institution',
  STATE_EXAM_SCORE: 'stateExamScore',
  YEAR_AWARDED: 'yearAwarded',
  ID: 'id',
  UPDATED_AT: 'updatedAt',
};
