import { CountryCodesConst } from '@axiom/const';
import { Opportunity, Position } from '@axiom/validation/src';

import { PositionUtil } from './position-util';

const { CountryCodesAbbreviations } = CountryCodesConst;

export const LocationUtil = {
  displayFormattedJobLocation: (
    opportunity: Opportunity,
    position: Position
  ) => {
    const worksite = PositionUtil.getWorksite(position);
    const location =
      opportunity.locationCityStateCountry || opportunity.locationName;

    if (worksite && location) {
      return `${worksite} - ${location}`;
    } else if (worksite) {
      return worksite;
    } else if (location) {
      return location;
    }

    return '--';
  },
  qualifyCountryCode: (opportunitycountryCode: string | null) => {
    switch (opportunitycountryCode) {
      case CountryCodesAbbreviations.GB:
        return CountryCodesAbbreviations.UK;
      default:
        return opportunitycountryCode;
    }
  },
};
