import memoize from 'lodash/memoize';
import {
  isClientUser,
  isEnvoyAdmin,
  isEnvoyUser,
  isLpUser,
  isSalesUser,
} from '@axiom/utils';
import {
  PermissionImpersonationRoles,
  PermissionImpersonationRolesValuesType,
} from '@axiom/const';
import { User } from '@axiom/validation';

export type calendarPermissionsType = {
  staticEvent: {
    canCreate: boolean;
    canMove: boolean;
    canResize: boolean;
    canDragToCreate: boolean;
    canDelete: boolean;
  };
  eventBoundary: {
    canCreate: boolean;
    canMove: boolean;
    canResize: boolean;
    canDragToCreate: boolean;
    canDelete: boolean;
  };
};

export const CalendarPermissionsUtil = (
  user?: User,
  appType?: PermissionImpersonationRolesValuesType
) => {
  const getBasePermissions = (): calendarPermissionsType => ({
    staticEvent: {
      canCreate: false,
      canMove: false,
      canResize: false,
      canDragToCreate: false,
      canDelete: false,
    },
    eventBoundary: {
      canCreate: false,
      canDragToCreate: false,
      canMove: false,
      canResize: false,
      canDelete: false,
    },
  });

  const isImpersonatingClientUser = () => {
    const isImpersonationAllowed =
      isEnvoyUser(user) || isEnvoyAdmin(user) || isSalesUser(user);
    return (
      appType === PermissionImpersonationRoles.client && isImpersonationAllowed
    );
  };

  const isImpersonatingTSUser = () => {
    return appType === PermissionImpersonationRoles.envoy && isEnvoyAdmin(user);
  };

  const isImpersonatingLPUser = () => {
    const isTsOrAdmin = isEnvoyUser(user) || isEnvoyAdmin(user);
    return appType === PermissionImpersonationRoles.talent && isTsOrAdmin;
  };

  const userPermissions = memoize(() => {
    // SalesUser has the least permissions.
    const data: calendarPermissionsType = getBasePermissions();
    const impersonatedClient = isImpersonatingClientUser();
    const impersonatedTS = isImpersonatingTSUser();
    const impersonatedTalent = isImpersonatingLPUser();

    if (isClientUser(user) || impersonatedClient) {
      data.staticEvent.canCreate = true;
      data.staticEvent.canDelete = true;
    } else if (isLpUser(user) || impersonatedTalent) {
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
    } else if (isEnvoyUser(user) || impersonatedTS) {
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
      data.staticEvent.canDelete = true;
    } else if (isEnvoyAdmin(user)) {
      data.staticEvent.canCreate = true;
      data.staticEvent.canDragToCreate = true;
      data.staticEvent.canMove = true;
      data.staticEvent.canResize = true;
      data.staticEvent.canDelete = true;
      data.eventBoundary.canCreate = true;
      data.eventBoundary.canDragToCreate = true;
      data.eventBoundary.canMove = true;
      data.eventBoundary.canResize = true;
      data.eventBoundary.canDelete = true;
    }

    return data;
  });

  return { userPermissions, getBasePermissions };
};
