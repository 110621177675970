import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardSection,
  CondensedSmall,
  DateUtil,
  Flashy,
  Form,
  Grid,
  GridColumn,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
  Toggle,
  useApi,
} from '@axiom/ui';
import { CandidateSchema } from '@axiom/validation';

import { CandidateApi } from '../../api/protected/candidates/candidate';
import { UserApi } from '../../api/protected/user/user';
import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { PreferencesUtil } from '../../utils/preferences-util';
import { isLPUser } from '../../utils/roles';

import { PreferencesBanner } from './PreferencesBanner';

const EditProfileVisibilityPreferencesSchema = CandidateSchema.partial().pick({
  isProfileShared: true,
  isSpotlightedLawyer: true,
  searchableByClient: true,
});

const ProfileVisibilityPreferences = ({
  permissions,
}: {
  permissions: {
    viewWorkStylePreferences: boolean;
    viewProfileVisibilityPreferences: boolean;
    viewProgramsPreferences: boolean;
  };
}) => {
  const navigate = useNavigate();

  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));
  const { profileVisibilityReviewedAt } = candidate;

  useEffect(() => {
    if (
      !PreloadedPermissionsStore.canViewProfileVisibilityPreferences(
        permissions
      )
    ) {
      navigate('../communications');
    }
  }, [permissions]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null;
    if (
      PreloadedPermissionsStore.canViewProfileVisibilityPreferences(
        permissions
      ) &&
      isLPUser(user)
    ) {
      timeout = setTimeout(() => {
        CandidateApi.updateCandidate(candidateId, {
          profileVisibilityReviewedAt: DateUtil.formatAsTimestamp(new Date()),
        });
      }, 500);
    }
    return () => timeout && clearTimeout(timeout);
  }, []);

  return (
    <Grid name="PROFILE_VISIBILITY_PREFERENCES">
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <PreferencesNavigation />
        <Gutter only="smallScreen" bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        {PreferencesUtil.shouldPreferenceNeedReview(
          profileVisibilityReviewedAt
        ) && <PreferencesBanner />}
        <Form
          schema={EditProfileVisibilityPreferencesSchema}
          onSubmit={async formData => {
            await CandidateApi.updateCandidate(candidateId, {
              ...CandidateSchema.partial().parse(formData),
              profileVisibilityReviewedAt: DateUtil.formatAsTimestamp(
                new Date()
              ),
            });
            return CandidateApi.refreshCandidate(candidateId);
          }}
          initialValues={{
            isProfileShared: !!candidate.isProfileShared,
            isSpotlightedLawyer: !!candidate.isSpotlightedLawyer,
            searchableByClient: !!candidate.searchableByClient,
          }}
          submitOnChange
        >
          {() => (
            <Card>
              <CardHeader>
                <SmallHeader name="PENDO-PROFILE-VISIBILITY-PREFERENCES">
                  Profile Visibility
                </SmallHeader>
              </CardHeader>
              <CardSection divider>
                <ParagraphHeader>
                  Select “yes” to access more potential opportunities and get
                  engaged faster.
                </ParagraphHeader>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <Paragraph>
                      <Flashy bold>
                        I want my profile included on the Axiom website and
                        marketing materials
                      </Flashy>
                      <Gutter bottom="16px" />
                      This allows clients and prospective clients to find a
                      redacted version of your profile (first name, photo and
                      summary) when they are considering hiring through Axiom.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="isSpotlightedLawyer" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <Paragraph>
                      <Flashy bold>
                        I want Axiom to share my profile with clients, even
                        before a formal opportunity exists
                      </Flashy>
                    </Paragraph>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      This allows the Axiom team to put your bio in front of
                      clients when we see an opportunity we think you’ll love,
                      without contacting you first.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="isProfileShared" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <Paragraph>
                      <Flashy bold>
                        I want vetted clients to be able to find my profile on
                        the Axiom platform
                      </Flashy>
                    </Paragraph>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      Some clients use a private client portal to search for
                      talent on their own instead of contacting Axiom to search
                      on their behalf. This allows clients to find your full bio
                      for potential opportunities.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="searchableByClient" />
                  </LayoutItem>
                </Layout>
              </CardSection>
            </Card>
          )}
        </Form>
        {profileVisibilityReviewedAt && (
          <Gutter top="10px">
            <CondensedSmall>
              <Flashy
                italic
                color="textSubtle"
                name="PROFILE_VISIBILITY_PREFERENCES_REVIEWED_AT"
              >
                Last reviewed:{' '}
                {DateUtil.displayDateFullYearShortMonthDayName(
                  profileVisibilityReviewedAt
                )}
              </Flashy>
            </CondensedSmall>
          </Gutter>
        )}
        <Gutter bottom="16px" />
        <Paragraph>
          Axiom will never provide your contact information to a client without
          your consent; set up an interview without your consent; or limit prior
          clients from seeing your profile if you’ve worked with them at Axiom
          in the past.
        </Paragraph>
      </GridColumn>
    </Grid>
  );
};

export const ProfileVisibilityPreferencesView = connect(state => ({
  permissions: PreloadedPermissionsStore.select(state),
}))(ProfileVisibilityPreferences);
