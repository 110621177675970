import moment from 'moment';
import { Experience } from '@axiom/validation';

export const CandidateExperiencesUtil = {
  createShouldShowMergeTipMapping: (experiences: Experience[]) => {
    return Object.fromEntries(
      experiences
        // for each Axiom experience, mark true if
        .filter(({ isAxiom }) => isAxiom)
        .map((experience, index, array) => [
          experience.id,
          array
            // another experience later in the sorted array (aka "earlier" in time)
            .slice(index + 1)
            // at the same client
            .filter(
              earlierExperience =>
                earlierExperience.client === experience.client
            )
            // exists where one of the following conditions is met:
            .some(
              earlierExperience =>
                // it's (another) ongoing engagement
                earlierExperience.endDate === null ||
                // it's an overlapping engagement
                moment(experience.startDate).isSameOrBefore(
                  moment(earlierExperience.endDate),
                  'day'
                ) ||
                // it's an earlier engagement that ended within 180 days of this one starting
                moment(experience.startDate).isSameOrBefore(
                  moment(earlierExperience.endDate).add(180, 'days'),
                  'day'
                )
            ),
        ])
    );
  },
};
