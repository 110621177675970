import { bool, shape } from 'prop-types';
import { CandidatesConst } from '@axiom/const';

import GenericStore from '../classes/generic-store';

const PERMISSIONS = {
  viewTalentPartner: 'viewTalentPartner',
  viewWorkFeed: 'viewWorkFeed',
  viewEngagements: 'viewEngagements',
  viewEmployeeResources: 'viewEmployeeResources',
  viewTimeSheets: 'viewTimeSheets',
  viewAvailabilityPanel: 'viewAvailabilityPanel',
  viewGetHelp: 'viewGetHelp',
  viewWorkStylePreferences: 'viewWorkStylePreferences',
  viewProfileVisibilityPreferences: 'viewProfileVisibilityPreferences',
  viewProgramsPreferences: 'viewProgramsPreferences',
  viewReferClient: 'viewReferClient',
  viewReferLawyer: 'viewReferLawyer',
  viewEmployeeItems: 'viewEmployeeItems',
};

const {
  EmployeeTypes,
  ProfileStatuses,
  ProfileStatuses: { Alum, Certifying, InDiligence, NewLead, Reservoir },
} = CandidatesConst;

const isValidProfileStatus = status =>
  Object.values(ProfileStatuses).includes(status);

class PreloadedPermissionsStoreClass extends GenericStore {
  load({ candidate, user } = {}) {
    if (!isValidProfileStatus(candidate.profileStatus)) {
      // no permissions
      this.setState(
        Object.fromEntries(
          Object.values(PERMISSIONS).map(permission => [permission, false])
        )
      );
      return;
    }

    const { ownerUser, profileStatus } = candidate;

    // be careful with changing this as it's used for many permissions
    const hasGenerallyRedactedStatus = [
      NewLead,
      Certifying,
      Reservoir,
    ].includes(profileStatus);

    this.setState({
      ...(user
        ? (() => {
            // only update permissions which are based on user when it is
            // passed in (see comment in current-candidate-store)
            const canViewSsoLinks =
              user.isSSO &&
              !hasGenerallyRedactedStatus &&
              ![InDiligence, Alum].includes(profileStatus);

            return {
              [PERMISSIONS.viewEmployeeResources]:
                // 1099 employees should not see Employee Resources
                canViewSsoLinks &&
                candidate.employeeType !== EmployeeTypes.Consultant,
              [PERMISSIONS.viewTimeSheets]: canViewSsoLinks,
            };
          })()
        : {}),
      [PERMISSIONS.viewAvailabilityPanel]: !hasGenerallyRedactedStatus,
      // see extra logic in TopNav to show the engagements link
      [PERMISSIONS.viewEngagements]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewGetHelp]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewWorkStylePreferences]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewProfileVisibilityPreferences]:
        !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewProgramsPreferences]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewEmployeeItems]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewTalentPartner]:
        ownerUser && !hasGenerallyRedactedStatus && profileStatus !== Alum,
      [PERMISSIONS.viewReferClient]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewReferLawyer]: !hasGenerallyRedactedStatus,
      [PERMISSIONS.viewWorkFeed]:
        candidate.employeeType &&
        candidate.employeeType !== EmployeeTypes.Consultant &&
        !hasGenerallyRedactedStatus,
    });
  }

  getDataShape() {
    return shape(
      Object.fromEntries(
        Object.values(PERMISSIONS).map(permission => [permission, bool])
      )
    );
  }

  select(state) {
    return super.select(state).data;
  }

  canViewReferClient(state) {
    return state[PERMISSIONS.viewReferClient];
  }

  canViewReferLawyer(state) {
    return state[PERMISSIONS.viewReferLawyer];
  }

  canViewTalentPartner(state) {
    return state[PERMISSIONS.viewTalentPartner];
  }

  canViewGetHelp(state) {
    return state[PERMISSIONS.viewGetHelp];
  }

  canViewWorkStylePreferences(state) {
    return state[PERMISSIONS.viewWorkStylePreferences];
  }

  canViewProfileVisibilityPreferences(state) {
    return state[PERMISSIONS.viewProfileVisibilityPreferences];
  }

  canViewProgramsPreferences(state) {
    return state[PERMISSIONS.viewProgramsPreferences];
  }

  canViewAvailabilityPanel(state) {
    return state[PERMISSIONS.viewAvailabilityPanel];
  }

  canViewWorkFeed(state) {
    return state[PERMISSIONS.viewWorkFeed];
  }

  canViewEngagements(state) {
    return state[PERMISSIONS.viewEngagements];
  }

  canViewEmployeeItems(state) {
    return state[PERMISSIONS.viewEmployeeItems];
  }
}

export const PreloadedPermissionsStore = new PreloadedPermissionsStoreClass();
