import { z } from 'zod';
import { SchemaDate, SchemaEmail } from '@axiom/types';

import { CandidateSchema } from './candidate';
import { OpportunitySchema } from './opportunity';
import { TimeZoneIanaSchema } from './time-zone';

export const CalendarEventAttendeeSchema = z.object({
  name: z.string(),
  userType: z.enum(['Talent', 'Client']).nullable(),
  email: SchemaEmail.nullable(),
});

const CalendarEventBaseSchema = z.object({
  id: z.string().uuid(),
  busy: z.boolean(),
  title: z.string().nullable(),
  name: z.string().nullish(),
  attendees: z.array(CalendarEventAttendeeSchema).nullable(),
});

export const CalendarEventSchema = CalendarEventBaseSchema.extend({
  error: z.string().nullish(),
  endTime: z.date(),
  startTime: z.date(),
});

export const CalendarComponentEventSchema = CalendarEventBaseSchema.extend({
  end: z.date(),
  start: z.date(),
  error: z.string().nullish(),
  state: z.enum(['new', 'modified']).nullable(),
  isBackgroundEvent: z.boolean().nullable(),
});

const CalendarBaseSchema = z.object({
  id: z.string().uuid().nullable(),
  candidateId: z.string().uuid().nullable(),
  contactId: z.string().uuid().nullable(),
  timezone: TimeZoneIanaSchema,
  nylasCalendarId: z.string().nullable(),
});

export const CalendarComponentSchema = CalendarBaseSchema.extend({
  events: z.array(CalendarComponentEventSchema).nullable(),
});

export const CalendarSchema = CalendarBaseSchema.extend({
  events: z.array(CalendarEventSchema).nullable(),
  freeTimeUpdatedAt: SchemaDate.nullable(),
  freeTimeUpdatedUserType: z.enum(['Talent', 'HQ']).nullable(),
});

export const CalendarManualInterviewSchema = CandidateSchema.pick({
  id: true,
})
  .merge(
    OpportunitySchema.pick({
      submissionId: true,
    })
  )
  .extend({
    adhocAttendees: z
      .array(
        z.object({
          firstName: z.string(),
          lastName: z.string(),
          email: SchemaEmail,
        })
      )
      .nullable(),
    contactIds: z.array(z.string().uuid()).nullable(),
  });

export const CalendarScheduledInterviewSchema =
  CalendarManualInterviewSchema.extend({
    candidateId: z.string().uuid().nullable(),
    title: z.string(),
    endTime: z.date(),
    startTime: z.date(),
  });
