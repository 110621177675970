import React, { useState } from 'react';
import { Candidate } from '@axiom/validation';
import {
  Card,
  CardHeader,
  CardSection,
  IconButton,
  Layout,
  SmallHeader,
  useApi,
} from '@axiom/ui';

import { TaxonomyApi } from '../../api/protected/taxonomy/taxonomy';
import { CandidateLegalTechSkillsApi } from '../../api/protected/candidates/candidate-legal-tech-skills';

import { LegalTechSkillsHintBanner } from './LegalTechSkillsHintBanner';
import { LegalTechSkillsDisplay } from './LegalTechSkillsDisplay';
import { LegalTechSkillsEdit } from './LegalTechSkillsEdit';

export const LegalTechSkills: React.FC<{
  candidateId: Candidate['id'];
}> = ({ candidateId }) => {
  const [{ data: taxonomy }, { data: legalTechSkills }] = useApi(
    TaxonomyApi.readTaxonomy(),
    CandidateLegalTechSkillsApi.readLegalTechSkills(candidateId)
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const openForm = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <Card name="LEGAL_TECH_SKILLS_CARD">
        <CardHeader>
          <Layout position="space-between">
            <SmallHeader>Legal Tech Skills</SmallHeader>
            <IconButton
              name="PENDO_EDIT_LEGAL_TECH_SKILLS_BUTTON"
              icon="pencil"
              pattern="secondary"
              variation="minimal"
              onClick={openForm}
            />
          </Layout>
        </CardHeader>
        <CardSection>
          {legalTechSkills.length > 0 ? (
            <LegalTechSkillsDisplay
              legalTechSkills={legalTechSkills}
              taxonomy={taxonomy}
            />
          ) : (
            <LegalTechSkillsHintBanner onAddNowClicked={openForm} />
          )}
        </CardSection>
      </Card>
      {showEditModal && (
        <LegalTechSkillsEdit
          legalTechSkills={legalTechSkills}
          taxonomy={taxonomy}
          candidateId={candidateId}
          closeModal={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};
