import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';

import { ProfileCompletionBanner } from '../ProfileCompletionBanner/ProfileCompletionBanner';

export const AccomplishmentsBannerPrompt = ({
  onOpenEdit = null,
}: {
  onOpenEdit?: () => void;
}) => {
  return (
    <ProfileCompletionBanner
      title={
        ProfileCompletionConst.ProfileCompletionAccomplishmentsBanner
          .missingTitle
      }
      description={
        ProfileCompletionConst.ProfileCompletionAccomplishmentsBanner
          .missingContent
      }
      estimatedTime="5 mins"
      onOpenEdit={onOpenEdit}
      name="ACCOMPLISHMENTS_BANNER_PROMPT"
    />
  );
};
