import React, { useContext } from 'react';
import { AttrsHelper } from '@axiom/ui/src/sb-helpers/attrs-helper';

import { CardSection } from '../Card/CardSection';

import { DrawerContext } from './DrawerContext';

export type DrawerSectionProps = {
  children: React.ReactNode;
  className?: string;
  divider?: boolean;
  name?: string;
};

export const DrawerSection = ({
  children,
  className,
  divider,
  name,
}: DrawerSectionProps) => {
  const { expanded } = useContext(DrawerContext);
  if (!expanded) {
    return null;
  }

  return (
    <CardSection
      className={AttrsHelper.formatClassname(className)}
      divider={divider}
      name={name}
    >
      {children}
    </CardSection>
  );
};
