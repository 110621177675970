import { Education } from '@axiom/const';

import { EducationItemShape } from '../../models/education-item';
import { AjvUtil } from '../../utils/ajv-util';
import {
  createEducationItem,
  updateEducationItem,
} from '../../api/protected/candidates/candidate-education-items';
import FormStore from '../../classes/form-store';

import { CurrentCandidateStore } from './current-candidate-store';

export const FORM_EDUCATION_ITEM_NAMESPACE = 'FORM_EDUCATION_ITEM';

const FormEducationItemValidation = AjvUtil.compileSchema({
  required: ['lawSchoolId', 'degree', 'yearAwarded'],
  properties: {
    lawSchoolId: {
      type: ['string'],
    },
    degree: {
      title: 'Degree',
      enum: Object.values(Education.DEGREES),
    },
    yearAwarded: {
      title: 'Year',
      type: 'number',
      minimum: 1950,
      maximum: new Date().getFullYear() + 10,
    },
    areaOfStudyId: {
      type: ['string'],
    },
  },
});

class FormEducationItemStoreClass extends FormStore {
  getDataShape() {
    return EducationItemShape;
  }

  load(education, candidateId) {
    return this.setState({
      candidateId,
      id: education.id || null,
      institution: education.institution,
      lawSchoolId: education.lawSchoolId,
      degree: education.degree,
      yearAwarded: education.yearAwarded,
      areaOfStudyId: education.areaOfStudy?.id,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormEducationItemValidation,
      formData
    );
  }

  submit(changedFields, formData) {
    if (formData.id) {
      this.clearState(updateEducationItem(formData)).then(() => {
        this.dispatch(
          CurrentCandidateStore.actionFetchCandidate(formData.candidateId)
        );
      });
    } else {
      this.clearState(
        createEducationItem(formData.candidateId, formData).then(() => {
          this.dispatch(
            CurrentCandidateStore.actionFetchCandidate(formData.candidateId)
          );
        })
      );
    }
  }
}

export const FormEducationItemStore = new FormEducationItemStoreClass();
