import styled from 'styled-components';

import theme from '../../themes';

// eslint-disable-next-line import/prefer-default-export
export const StyledBanner = styled.header`
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || theme.main.qualified};
  color: ${({ textColor }) => textColor || theme.main.appBackground};
  justify-content: center;
  margin: -18px -8px 8px -8px;
  padding: 1rem 16px;
  line-height: 1.5;
  display: flex;

  a,
  a:visited,
  a:hover,
  a:active {
    color: ${({ textColor }) => textColor || theme.main.appBackground};
    text-decoration: underline;
  }
`;

export const BannerIconWrapper = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
`;
