import styled from 'styled-components';

export const MultiSelectWrapper = styled.div`
  .k-multiselect-wrap {
    height: auto;
  }

  ${({ invalid }) => {
    if (invalid) {
      return `
        .k-widget {
          border: none !important;
        }  
        .k-multiselect-wrap {
          height: 1.8rem;
        }
      `;
    }
    return null;
  }};

  li.k-button {
    margin: 0 0.25rem 0.25rem 0 !important;
  }
`;
