import React, { useState } from 'react';
import { Candidate, CandidateSchema } from '@axiom/validation';
import {
  Banner,
  Button,
  Card,
  CardHeader,
  CardSection,
  Flashy,
  FluidButtonLayout,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Icon,
  IconButton,
  ImageCircle,
  Input,
  LargeHeader,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';
import { CandidateProfileUtils } from '@axiom/utils';

import { ProfileImageEdit } from '../ProfileImageEdit/ProfileImageEdit';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateStore } from '../../stores/protected/candidate-store';

const formSchema = CandidateSchema.pick({
  displayFirstName: true,
  displayLastName: true,
});

export const ProfileHeader = ({ candidate }: { candidate: Candidate }) => {
  const [isEditShowing, setIsEditShowing] = useState(false);
  const nameErrors = CandidateProfileUtils.getNameErrors(candidate);

  return (
    <Card name="PROFILE_HEADER">
      <CardHeader>
        <Layout position="middle" wrap>
          <LayoutItem rightGutter="16px">
            <ImageCircle
              name="PROFILE_IMAGE_NAME"
              background="blue"
              imageName={candidate.calculatedDisplayName}
              src={CandidateUtil.getProfileImageUri(
                candidate,
                PossibleImageSizes.W_160
              )}
            />
          </LayoutItem>
          <LayoutItem fluid>
            <LargeHeader name="CALCULATED_DISPLAY_NAME">
              {candidate.calculatedDisplayName}
            </LargeHeader>
          </LayoutItem>
          {!isEditShowing && (
            <LayoutItem>
              <IconButton
                name="EDIT_BUTTON"
                icon="pencil"
                pattern="secondary"
                variation="minimal"
                onClick={() => setIsEditShowing(true)}
              />
            </LayoutItem>
          )}
        </Layout>
      </CardHeader>
      {isEditShowing && (
        <CardSection name="EDIT_SECTION" divider>
          <ParagraphHeader name="PENDO-PROFILE-PHOTO">
            Profile Photo
          </ParagraphHeader>
          <Gutter bottom="16px" />
          <ProfileImageEdit />
          <Gutter bottom="24px" />
          <Form
            name="PROFILE_HEADER_DISPLAY_NAME_FORM"
            schema={formSchema}
            initialValues={{
              displayFirstName:
                candidate.displayFirstName || candidate.firstName,
              displayLastName: candidate.displayLastName || candidate.lastName,
            }}
            onSubmit={async formData => {
              await CandidateApi.updateCandidate(
                candidate.id,
                CandidateSchema.partial().parse(formData)
              );
              // Needs to call CandidateStore to trigger re-fetch of legacy CurrentCandidateStore
              // since ProfileImageEdit references candidate data from old store
              await CandidateStore.refresh(candidate.id);
              return setIsEditShowing(false);
            }}
          >
            {({ fireSubmit }) => {
              return (
                <>
                  <ParagraphHeader>Display Name</ParagraphHeader>
                  <Gutter bottom="16px" />
                  {nameErrors.length > 0 && (
                    <div data-test="NAME_ERRORS">
                      {nameErrors.map(msg => (
                        <Gutter bottom="16px" key={msg}>
                          <Banner impact="low">
                            <ParagraphHeader as="span">
                              <Flashy color="textSecondary">
                                Update your display name.
                              </Flashy>
                            </ParagraphHeader>{' '}
                            {msg}
                          </Banner>
                        </Gutter>
                      ))}
                    </div>
                  )}
                  <Paragraph>
                    Your display name is the name that appears to Talent Success
                    and our clients. This does not need to be your legal name.
                    Additional personalizations to avoid:
                  </Paragraph>
                  <Gutter bottom="16px" />
                  <Paragraph>
                    <ul>
                      <li>Do not use all caps.</li>
                      <li>
                        Do not include middle names or initials unless they help
                        clarify your name or are part of your personal brand. If
                        you need to add a middle name or initial, append it to
                        your Display First Name.
                      </li>
                      <li>
                        Do not include qualifications (JD, MBA, LLM, etc.) in
                        your display name fields.
                      </li>
                      <li>
                        Do not include titles (Esq., Mr., Mrs., etc.) in your
                        display name fields.
                      </li>
                    </ul>
                  </Paragraph>
                  <Grid>
                    <GridRow>
                      <GridColumn
                        largeScreenWidth={6}
                        tabletWidth={6}
                        mobileWidth={12}
                      >
                        <Input
                          name="displayFirstName"
                          label="Display First Name"
                        />
                      </GridColumn>
                      <GridColumn
                        largeScreenWidth={6}
                        tabletWidth={6}
                        mobileWidth={12}
                      >
                        <Input
                          name="displayLastName"
                          label="Display Last Name"
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                  <FluidButtonLayout>
                    <Button
                      variation="outline"
                      name="CANCEL"
                      onClick={() => {
                        setIsEditShowing(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button name="SAVE" type="submit" onClick={fireSubmit}>
                      Save
                    </Button>
                  </FluidButtonLayout>
                </>
              );
            }}
          </Form>
        </CardSection>
      )}
      {candidate.barredLocations.length > 0 && (
        <CardSection name="BAR_ADMISSIONS_SECTION">
          <SectionHeader>Bar Admissions</SectionHeader>
          <Gutter bottom="16px" />
          <Layout
            horizontalGutter="24px"
            verticalGutter="8px"
            wrap
            name="BAR_ADMISSIONS"
          >
            {candidate.barredLocations.map(bl => (
              <Layout
                horizontalGutter="8px"
                key={bl.name}
                position="middle"
                inline
              >
                <Icon name="waypoint" />

                <span>{bl.name}</span>
              </Layout>
            ))}
          </Layout>
        </CardSection>
      )}
    </Card>
  );
};
