import { Api, CandidateDto } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import ApiHelper from '../../../lib/api-helper';
import { CANDIDATE_API_NAME } from '../../constants';
import { filterParams } from '../../utils/clean-params';
import { EnvUtil } from '../../../utils/env-util';

const api = new ApiHelper(CANDIDATE_API_NAME);

export const getCandidates = async (body = { page: 1 }) => {
  const { data, _meta } = await api.GET('/candidates', filterParams(body));
  return { data, meta: _meta };
};

export const getCandidate = async (candidateId: string) => {
  const { data } = await api.GET(`/candidates/${candidateId}`);
  return data;
};

export const updateCandidate = async (
  candidateId: string,
  props: Candidate
) => {
  const requestBody = new CandidateDto(props);

  const { data } = await api.PATCH(
    `/candidates/${candidateId}`,
    requestBody.export()
  );
  return data;
};

class CandidateApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readCandidate(candidateId: string) {
    return super.read<{ data: Candidate }>({
      endpoint: `/candidates/${candidateId}`,
      method: 'GET',
    });
  }

  updateCandidate(candidateId: string, candidate: Candidate) {
    return super.write<{ data: Candidate }>({
      endpoint: `/candidates/${candidateId}`,
      body: candidate,
      method: 'PATCH',
    });
  }

  refreshCandidate(candidateId: string) {
    return super.invalidate(`/candidates/${candidateId}`);
  }
}

export const CandidateApi = new CandidateApiClass();
