import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';

import { FormField } from '../FormField/FormField';

import {
  RawDateInput,
  RawDateInputProps,
  RawDateInputValueType,
} from './RawDateInput';

export type DateInputProps = {
  description?: string;
  disabled?: RawDateInputProps['disabled'];
  id?: RawDateInputProps['id'];
  label?: React.ReactNode;
  name: RawDateInputProps['name'];
  onBlur?: RawDateInputProps['onBlur'];
  onChange?: RawDateInputProps['onChange'];
  onFocus?: RawDateInputProps['onFocus'];
  placeholder?: RawDateInputProps['placeholder'];
  Tooltip?: React.ReactNode;
};

export const DateInput = ({
  description,
  disabled,
  id,
  label,
  name,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  placeholder,
  Tooltip,
}: DateInputProps) => {
  id = id || name;
  return (
    <FormField
      {...{ description, disabled, id, name, label, Tooltip }}
      renderField={({
        value,
        onBlur: formOnBlur,
        setFieldValue,
        invalid,
        schemaProperty,
      }: {
        value: RawDateInputValueType;
      } & FormFieldProps) => {
        return (
          <RawDateInput
            disabled={disabled}
            id={id}
            invalid={invalid}
            name={name}
            onBlur={date => {
              onBlur(date);
              formOnBlur({ target: { name, value: date } });
            }}
            onChange={date => {
              onChange(date);
              setFieldValue(name, date);
            }}
            onFocus={() => {
              onFocus();
            }}
            placeholder={placeholder}
            type={schemaProperty.type}
            value={value}
          />
        );
      }}
    />
  );
};
