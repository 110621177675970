const Frequency = {
  Instant: 'Instant',
  Daily: 'Daily',
  Weekly: 'Weekly',
};

// eslint-disable-next-line import/prefer-default-export
export const CandidateOppFeedAlertsConst = {
  Frequency,
};
