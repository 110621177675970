import { EducationDto } from '@axiom/ui';

import ApiHelper from '../../../lib/api-helper';
import { CANDIDATE_API_NAME } from '../../constants';

const api = new ApiHelper(CANDIDATE_API_NAME);

export const createEducationItem = async (candidateId, educationItemForApi) => {
  const requestBody = new EducationDto(educationItemForApi);
  const { data } = await api.POST(`/candidates/${candidateId}/degrees`, {
    ...requestBody.export(),
    candidateId, // server shouldn't require this since it's in the url
  });
  return { data };
};

export const deleteEducationItem = async id => {
  const { data } = await api.DELETE(`/education/${id}`);
  return { data };
};

export const updateEducationItem = async educationItemForApi => {
  const requestBody = new EducationDto(educationItemForApi);
  const { data } = await api.PATCH(
    `/education/${educationItemForApi.id}`,
    requestBody.export()
  );
  return { data };
};
