/* eslint-disable jsx-a11y/heading-has-content */
import React, { Component } from 'react';
import { number } from 'prop-types';

const INITIAL_STATE = {};
class LoadingGradient extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  render() {
    const { lines } = this.props;
    const lineArray = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < lines; i++) {
      lineArray.push(<p key={i} className="block-placeholder" />);
    }

    return lineArray;
  }
}

LoadingGradient.defaultProps = {
  lines: 1,
};

LoadingGradient.propTypes = {
  lines: number,
};

export default LoadingGradient;
