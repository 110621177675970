/**
 *  This will make a value array of objects that kendo wants, but then
 * transform to array of scalars that we usually need
 */
export const KendoUtils = {
  convertScalarValuesToObjectValues: (
    scalarValues,
    dataOptions,
    dataItemKey,
    textField
  ) =>
    scalarValues
      .map(scalarV => {
        const found = dataOptions.find(item => item[dataItemKey] === scalarV);
        if (found) {
          return {
            [dataItemKey]: found[dataItemKey],
            [textField]: found[textField],
          };
        }
        return null;
      })
      .filter(item => item !== null),
  convertObjectValuesToScalarValues: (objectValues, dataItemKey) =>
    objectValues.map(itemObj => itemObj[dataItemKey]),
};
