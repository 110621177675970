import React from 'react';
import {
  Card,
  CondensedHeader,
  Gutter,
  SmallHeader,
  CardHeader,
  ProgressSteps,
  ProgressStep,
  SectionHeader,
  Layout,
  LayoutItem,
  Paragraph,
  CondensedLarge,
  ParagraphHeader,
  CardSection,
} from '@axiom/ui';
import { WorkFeedConst } from '@axiom/const';
import { CandidateWorkFeedItem } from '@axiom/validation';
import moment from 'moment';
import { Plural } from '@lingui/macro';

import { DateUtil } from '../../utils/date-util';

const { Phases } = WorkFeedConst;

export const OpportunityPhaseTracker = ({
  workFeedItem,
}: {
  workFeedItem: CandidateWorkFeedItem;
}) => {
  const {
    runningCandidateInterestedCount,
    runningCandidateInterviewingCount,
    runningCandidateSubmittedCount,
    phase,
    phaseInterviewingDate,
    phaseSubmittedDate,
    opportunity: { madeAvailableToFeedAt, closedDate },
  } = workFeedItem;

  const getDaysAvailable = (fromDate: moment.MomentInput) => {
    return !fromDate ? '0' : moment(new Date()).diff(moment(fromDate), 'days');
  };

  return (
    <Card>
      <CardHeader>
        <SmallHeader>Opportunity Stage Tracker</SmallHeader>
      </CardHeader>
      <CardSection>
        <ProgressSteps
          completedStep={phase}
          direction="vertical"
          icon="checkmark"
        >
          <ProgressStep stepName={Phases.New}>
            <Layout stackableOn="mobile">
              <LayoutItem fluid>
                <SectionHeader>New</SectionHeader>
              </LayoutItem>
              <LayoutItem>
                <CondensedHeader name="date-madeAvailableToFeedAt">
                  {DateUtil.displayDate(madeAvailableToFeedAt)}
                </CondensedHeader>
              </LayoutItem>
            </Layout>
            <Gutter bottom="8px" />
            <Paragraph name="days-opened">
              Axiom has not yet submitted any talent for this opportunity. This
              opportunity has been open for{' '}
              <Plural
                value={getDaysAvailable(madeAvailableToFeedAt)}
                one="# day"
                other="# days"
              />
              .
            </Paragraph>
            {!workFeedItem.opportunity.directEligible && (
              <CondensedLarge name="count-subtitle">
                <ParagraphHeader name="running-interested" as="span">
                  {runningCandidateInterestedCount}
                </ParagraphHeader>{' '}
                <Plural
                  value={runningCandidateInterestedCount}
                  one="talent is"
                  other="talent are"
                />{' '}
                interested in this opportunity.
              </CondensedLarge>
            )}
          </ProgressStep>
          <ProgressStep stepName={Phases.Submitted}>
            <Layout stackableOn="mobile">
              <LayoutItem fluid>
                <SectionHeader>Submitting</SectionHeader>
              </LayoutItem>
              <LayoutItem>
                <CondensedHeader name="date-submitted">
                  {DateUtil.displayDate(phaseSubmittedDate)}
                </CondensedHeader>
              </LayoutItem>
            </Layout>
            <Gutter bottom="8px" />
            <Paragraph>
              Talent profiles are currently being submitted for the client’s
              consideration.
            </Paragraph>
            <CondensedLarge name="count-subtitle">
              <ParagraphHeader name="running-submitted" as="span">
                {runningCandidateSubmittedCount}
              </ParagraphHeader>{' '}
              <Plural
                value={runningCandidateSubmittedCount}
                one="talent profile has"
                other="talent profiles have"
              />{' '}
              been submitted for this opportunity.
            </CondensedLarge>
          </ProgressStep>
          <ProgressStep stepName={Phases.Interviewing}>
            <Layout stackableOn="mobile">
              <LayoutItem fluid>
                <SectionHeader>Interviewing</SectionHeader>
              </LayoutItem>
              <LayoutItem>
                <CondensedHeader name="date-interviewing">
                  {DateUtil.displayDate(phaseInterviewingDate)}
                </CondensedHeader>
              </LayoutItem>
            </Layout>
            <Gutter bottom="8px" />
            <Paragraph>
              The client is actively interviewing talent who were submitted. If
              none are selected Axiom will consider additional profiles.
            </Paragraph>
            <CondensedLarge name="count-subtitle">
              <ParagraphHeader name="running-interviewing" as="span">
                {runningCandidateInterviewingCount}
              </ParagraphHeader>{' '}
              <Plural
                value={runningCandidateInterviewingCount}
                one="talent is"
                other="talent are"
              />{' '}
              interviewing for this opportunity.
            </CondensedLarge>
          </ProgressStep>
          <ProgressStep stepName={Phases.Closed}>
            <Layout stackableOn="mobile">
              <LayoutItem fluid>
                <SectionHeader>Closed</SectionHeader>
              </LayoutItem>
              <LayoutItem>
                <CondensedHeader name="date-closed">
                  {DateUtil.displayDate(closedDate)}
                </CondensedHeader>
              </LayoutItem>
            </Layout>
            <Gutter bottom="8px" />
            <Paragraph>
              The client has selected Axiom talent for this role and the
              opportunity is no longer available.
            </Paragraph>
          </ProgressStep>
        </ProgressSteps>
      </CardSection>
    </Card>
  );
};
