import { arrayOf, shape } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import SubmitStateHelper from '../../lib/submit-state-helper';
import { CandidateLanguageShape } from '../../models/language-model';
import { CandidateLanguageUtil } from '../../utils/candidate-language-util';
import {
  getLanguagesByCandidateId,
  normalizeCandidateLanguagesToApi,
  putLanguagesByCandidateId,
} from '../../api/protected/candidates/candidate-languages';
import { LanguageUtil } from '../../utils/language-util';

import { CurrentCandidateStore } from './current-candidate-store';

export const CANDIDATE_LANGUAGE_NAMESPACE = 'CANDIDATE_LANGUAGE';

const stateGetLanguages = new CollectionStateHelper(
  CANDIDATE_LANGUAGE_NAMESPACE,
  'FETCH'
);
const stateDeleteLanguage = new SubmitStateHelper(
  CANDIDATE_LANGUAGE_NAMESPACE,
  'DELETE_EXP'
);

export const CandidateLanguagesStore = {
  getReducers() {
    return {
      ...stateGetLanguages.generateReducers(),
      ...stateDeleteLanguage.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateGetLanguages.getStructure(this.getDataShape()),
      submits: shape({
        ...stateDeleteLanguage.getStructure(),
      }),
    });
  },
  getDataShape() {
    return arrayOf(CandidateLanguageShape);
  },

  actionFetchLanguages(candidateId) {
    return stateGetLanguages.fetchCollection(
      getLanguagesByCandidateId(candidateId)
    );
  },
  actionDeleteLanguages:
    (allLanguages, candidateLanguages, deleteLanguageObj, candidateId) =>
    dispatch => {
      candidateLanguages = CandidateLanguageUtil.filterOutByLanguageObj(
        candidateLanguages,
        deleteLanguageObj
      );

      candidateLanguages = normalizeCandidateLanguagesToApi(
        allLanguages,
        candidateLanguages
      );

      dispatch(
        stateDeleteLanguage.submitData(
          putLanguagesByCandidateId(candidateId, candidateLanguages).then(
            () => {
              dispatch(
                CandidateLanguagesStore.actionFetchLanguages(candidateId)
              );
              dispatch(CurrentCandidateStore.actionFetchCandidate(candidateId));
            }
          )
        )
      );
    },
  selectLanguagesState(state) {
    return stateGetLanguages.select(state);
  },
  selectLanguages(state) {
    return stateGetLanguages.selectData(state) || [];
  },
  selectLanguagesGrouped(state) {
    return LanguageUtil.groupByCategories(
      stateGetLanguages.selectData(state) || []
    );
  },
  selectDeleteLanguageState(state) {
    return stateDeleteLanguage.select(state);
  },
};
