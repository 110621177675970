import React from 'react';
import { Flashy, ParagraphHeader, Paragraph } from '@axiom/ui';
import { CandidateOpportunitiesConst, ClientRequestConst } from '@axiom/const';

const { CandidateRejectionLossCodes } = CandidateOpportunitiesConst;
const { AfcRejectionLossCodes } = ClientRequestConst;

export const RejectionLossCodesUtil = {
  defaultMessages: {
    [CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because the hours required for this engagement do
        not meet the minimums required for your state. Consider expressing
        interest in engagements with more of a time commitment required.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ConflictOfInterest]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ConflictOfInterest}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to a conflict of interest that might prevent
        you from performing the work. If you're not sure you have a conflict of
        interest with a client, it's a good idea to check with your Talent
        Success Partner before expressing interest.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.CostTooExpensive]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.CostTooExpensive}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to client budget constraints. Consider
        expressing interest in opportunities that require a little more
        experience or highly specialized work.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as your background didn't have the relevant
        industry experience. Consider filtering opportunities by industry to
        express interest in roles that match your industry experience. It's also
        important to associate a relevant industry with every experience in your
        Axiom profile.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceOverqualified]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceOverqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as it didn't match the required level of experience
        as determined by the client. Consider expressing interest in
        opportunities that require a little more experience or highly
        specialized work.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperiencePoorPracticeArea]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperiencePoorPracticeArea}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your background didn't have the relevant
        practice area. Consider filtering opportunities by practice area to
        express interest in roles that match up well with your legal background.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceUnderqualified]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceUnderqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as the client was looking for more experience.
        Consider taking a moment to ensure your Axiom profile includes all your
        legal experience and skills.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't submitted</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because the client is not reviewing any more
        profiles at this time. While some opportunities do get filled quickly,
        Axiom has you covered--the feed is refreshed daily with more
        opportunities like this.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.IncorrectLanguage]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.IncorrectLanguage}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because you don't have the required language skill
        set. Consider taking a moment to ensure all languages you speak, read,
        or write are included in your Axiom profile.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.InsufficientAvailability]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.InsufficientAvailability}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your availability doesn't match up with the
        client's requirements. Consider filtering opportunities by weekly
        commitment to express interest in roles that match your availability.
        It's also important to ensure your availability is up to date in your
        Axiom profile.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't selected</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your Axiom for Talent profile is
        incomplete. Consider taking a moment to check your Axiom profile
        completion % and add any missing information.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't selected</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity since the client has selected another legal
        professional for this opportunity.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.TimeZoneLocation]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.TimeZoneLocation}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your location or time zone doesn't match up
        with what the client is looking for. Consider filtering opportunities by
        country or double-checking the opportunity location to ensure your work
        schedule aligns with the client's local business hours.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.WrongTalentType]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.WrongTalentType}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your legal role is different than what the
        client requires. Consider filtering opportunities by legal role to
        express interest in roles that match your qualifications.
      </Paragraph>
    ),
  },
  rejectedByClientMessages: {
    [AfcRejectionLossCodes.ConflictOfInterest]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.ConflictOfInterest}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to a possible conflict of interest with
        something in your background or experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.CostTooExpensive]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.CostTooExpensive}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to budget constraints.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because they need someone whose background includes
        more relevant industry experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceOverqualified]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceOverqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your profile doesn't match up well with the
        required level of experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceUnderqualified]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceUnderqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because they are looking for a little more
        experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.IncorrectLanguage]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.IncorrectLanguage}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your profile doesn't indicate that you have
        the required language skills.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.Other]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.Other}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity but did not provide a reason.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ProfileNeedsMoreDetail]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ProfileNeedsMoreDetail}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your Axiom profile is incomplete.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client was impressed with your qualifications
          </ParagraphHeader>
        </Flashy>{' '}
        but decided to move forward with another Axiom legal talent for this
        opportunity.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.TimeZoneLocation]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.TimeZoneLocation}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your location or time zone doesn’t match up
        with the client's business needs.
      </Paragraph>
    ),
  },
};
