import moment from 'moment';
import {
  ProfileCompletionConst,
  WeeklyTimeUnits,
  LANGUAGE_SKILLS_KEYS,
} from '@axiom/const';
import {
  Candidate,
  CandidateLanguage,
  CandidateLegalTechSkill,
  CandidateOpportunity,
  Certification,
  Education,
  Experience,
  Position,
} from '@axiom/validation';

const isNumber = (value: string | number | null) =>
  value === null ? false : !Number.isNaN(Number(value));

const isWeeklyTimeUnitDaily = (weeklyTimeUnit: Position['weeklyTimeUnit']) => {
  if (!weeklyTimeUnit) return false;
  return weeklyTimeUnit === WeeklyTimeUnits.Daily;
};

const hasValidWeeklyTimeUnit = (weeklyTimeUnit: Position['weeklyTimeUnit']) =>
  !!weeklyTimeUnit && Object.keys(WeeklyTimeUnits).includes(weeklyTimeUnit);

const convertUnitsPerWeek = (
  billingUnitsPerWeek: Position['billingUnitsPerWeek']
) =>
  typeof billingUnitsPerWeek === 'string'
    ? parseFloat(billingUnitsPerWeek)
    : billingUnitsPerWeek;

const hasValidUnitsPerWeek = (
  billingUnitsPerWeek: Position['billingUnitsPerWeek']
) =>
  !!billingUnitsPerWeek && isNumber(convertUnitsPerWeek(billingUnitsPerWeek));

const hasValidBillingHoursPerDay = (
  billingHoursPerDay: Position['billingHoursPerDay']
) => !!billingHoursPerDay && isNumber(billingHoursPerDay);

const hasValidEndDate = (endDate: string) =>
  !!endDate &&
  moment(endDate, 'YYYY-MM-DD', true).isSameOrAfter(moment(), 'day');

export const ProfileCompletionUserPrefProps = ['desiredWeeklyMaxHours'];

export const CandidateProfileUtils = {
  hasNonAxiomExperience(experiences: Experience[] = []) {
    return (experiences ?? []).some(experience => !experience.isAxiom);
  },

  // If there is no Axiom Experience, the talent will not be docked
  // So we are not-bias towards new talent without axiom experiences
  hasNoAxiomExperienceWithBlankDescription(experiences: Experience[] = []) {
    return !(experiences ?? []).some(
      experience => experience.isAxiom && !experience.description?.trim()
    );
  },

  hasNoExperienceWithMissingIndustry(experiences: Experience[] = []) {
    return experiences.length > 0 && !experiences.some(e => !e.industryValue);
  },

  hasLegalTechSkills(legalTechSkills: CandidateLegalTechSkill[] = []) {
    return legalTechSkills.length > 0;
  },

  hasDegrees(degrees: Education[] = []) {
    return Array.isArray(degrees) && degrees.length >= 1;
  },

  hasSummaryOfSufficientLength(candidate: Candidate) {
    return (
      candidate?.candidateSummary?.length >=
      ProfileCompletionConst.minimumAcceptableSummaryLength
    );
  },

  hasCertifications(certifications: Certification[] = []) {
    return certifications?.length > 0;
  },

  /* The use of .toLowerCase() is temporary. It will be removed when the languageSkill fields
   * on the candidate_languages table are updated to match the casing of LANGUAGE_SKILLS.
   */
  hasNativeLanguage(languages: CandidateLanguage[] = []) {
    if (languages && Array.isArray(languages)) {
      const groupedLanguages = languages.reduce(
        (acc: Record<string, string[]>, language: CandidateLanguage) => {
          acc[language.id] = acc[language.id] ?? [];
          acc[language.id].push(language.languageSkill?.toLowerCase());
          return acc;
        },
        {}
      );

      return Object.values(groupedLanguages).some(language => {
        return (
          language.includes(LANGUAGE_SKILLS_KEYS.READING) &&
          language.includes(LANGUAGE_SKILLS_KEYS.SPEAKING) &&
          language.includes(LANGUAGE_SKILLS_KEYS.WRITING)
        );
      });
    }

    return false;
  },

  hasPracticeStartYear(candidate: Candidate) {
    return !!candidate.practiceStartYear;
  },

  hasValidName(candidate: Candidate) {
    return this.getNameErrors(candidate).length === 0;
  },

  getNameErrors(candidate: Candidate) {
    const { firstName, lastName, displayFirstName, displayLastName } =
      candidate;
    const errors = [];
    const threeOrMoreConsecutiveCaps = /(?!III|VII)[A-Z]{3,}/;
    const checkQualifiers = /(?:JD|J\.D\.|Esq|Esq\.|MD|M\.D\.|\\|\(|\))/;
    const checkTitles =
      /^(?:adv|av|dr|esq|hon|ma|md|miss|mr|mrs|ms|mx|prof|rev|wm)(?=\.|\s)/;

    const checkFirstName = displayFirstName ?? firstName ?? '';
    const checkLastName = displayLastName ?? lastName ?? '';
    const checkName = `${checkFirstName} ${checkLastName}`.trim();

    // Check if there are too many capital letters in the name
    if (threeOrMoreConsecutiveCaps.test(checkName)) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.allCaps);
    }

    // Check for specified qualifiers, 3 or more consecutive capital letters are included
    if (
      checkQualifiers.test(checkName) ||
      threeOrMoreConsecutiveCaps.test(checkName)
    ) {
      errors.push(
        ProfileCompletionConst.ProfileCompletionNameBanner.qualifications
      );
    }

    // Check if there are any titles in the name (title check is in beginning)
    if (
      checkTitles.test(
        checkFirstName.toLowerCase() || checkLastName.toLowerCase()
      )
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.titles);
    }

    return errors;
  },

  getTotalPoints({
    candidate,
    languages,
    experiences,
    degrees,
    certifications,
  }: {
    candidate?: Candidate;
    languages?: CandidateLanguage[];
    experiences?: Experience[];
    degrees?: Education[];
    certifications?: Certification[];
  } = {}) {
    return [
      this.hasNonAxiomExperience(experiences) ? 40 : 0,
      this.hasNoExperienceWithMissingIndustry(experiences) ? 15 : 0,
      this.hasNoAxiomExperienceWithBlankDescription(experiences) ? 10 : 0,
      this.hasDegrees(degrees) ? 15 : 0,
      this.hasSummaryOfSufficientLength(candidate) ? 10 : 0,
      this.hasCertifications(certifications) ? 5 : 0,
      this.hasNativeLanguage(languages) ? 5 : 0,
    ].reduce((acc, current) => acc + current, 0);
  },

  getNeededCompletionSteps(
    candidate: Candidate & { legalTechSkills?: CandidateLegalTechSkill[] } = {}
  ) {
    const { ProfileCompletionCriteria, ProfileCompletionCriteriaDetails } =
      ProfileCompletionConst;
    const { experiences, degrees, languages, legalTechSkills, certifications } =
      candidate;
    return [
      !this.hasPracticeStartYear(candidate) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsPracticeStartYear
        ],
      !this.hasValidName(candidate) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsValidName
        ],
      !this.hasSummaryOfSufficientLength(candidate) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsAcceptableSummary
        ],
      !this.hasNoAxiomExperienceWithBlankDescription(experiences) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNoAxiomExperienceWithBlankDescription
        ],
      !this.hasNonAxiomExperience(experiences) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNonAxiomExperience
        ],
      !this.hasNoExperienceWithMissingIndustry(experiences) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNoExperienceWithMissingIndustry
        ],
      !this.hasLegalTechSkills(legalTechSkills) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsLegalTechSkills
        ],
      !this.hasDegrees(degrees) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsDegrees
        ],
      !this.hasNativeLanguage(languages) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNativeLanguage
        ],
      !this.hasCertifications(certifications) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsCertifications
        ],
    ].filter(Boolean);
  },

  getAvailabilityDescription(candidate: Candidate, plain = false) {
    const { weeklyAvailability, isOpenToMultipleClients, isOpenToOtherWork } =
      candidate;
    let moreWork =
      weeklyAvailability > 0
        ? `available for ${weeklyAvailability} hours per week`
        : 'not looking for additional work';
    let moreClients =
      weeklyAvailability > 0 && isOpenToMultipleClients ? 'are' : 'are not';
    let nonMatched = isOpenToOtherWork ? 'will consider' : 'will not consider';

    if (!plain) {
      const addTag = (s: string, dataTest: string) =>
        `<strong data-test="${dataTest}">${s}</strong>`;
      moreWork = addTag(moreWork, 'IS_AVAILABLE_FOR_MORE_WORK');
      moreClients = addTag(moreClients, 'IS_OPEN_TO_MORE_CLIENTS');
      nonMatched = addTag(nonMatched, 'IS_OPEN_TO_OTHER_WORK');
    } else {
      // This is for hubspot formatting.
      const addTag = (s: string) => `<strong>${s}</strong>`;
      moreWork = addTag(moreWork);
      moreClients = addTag(moreClients);
      nonMatched = addTag(nonMatched);
    }
    return (
      `You are currently ${moreWork}. You ${moreClients} open to working with multiple clients and ` +
      `${nonMatched} opportunities that don't exactly match your preferences.`
    );
  },

  canCalculateHours(
    weeklyTimeUnit: Position['weeklyTimeUnit'] = null,
    billingUnitsPerWeek: Position['billingUnitsPerWeek'] = null,
    billingHoursPerDay: Position['billingHoursPerDay'] = null,
    endDate: Position['endDate'] = null
  ) {
    return (
      hasValidUnitsPerWeek(billingUnitsPerWeek) &&
      hasValidWeeklyTimeUnit(weeklyTimeUnit) &&
      (!isWeeklyTimeUnitDaily(weeklyTimeUnit) ||
        hasValidBillingHoursPerDay(billingHoursPerDay)) &&
      hasValidEndDate(endDate)
    );
  },

  calculateEngagementHours(engagement: CandidateOpportunity) {
    const { position } = engagement;

    if (!position) {
      return 0;
    }

    const { weeklyTimeUnit, billingUnitsPerWeek, billingHoursPerDay, endDate } =
      position;

    if (
      !this.canCalculateHours(
        weeklyTimeUnit,
        billingUnitsPerWeek,
        billingHoursPerDay,
        endDate
      )
    ) {
      return 0;
    }
    const convertedUnitsPerWeek = convertUnitsPerWeek(billingUnitsPerWeek);

    if (weeklyTimeUnit === WeeklyTimeUnits.Hourly) {
      return convertedUnitsPerWeek;
    } else if (isWeeklyTimeUnitDaily(weeklyTimeUnit)) {
      const convertedBillingHoursPerDay =
        typeof billingHoursPerDay === 'string'
          ? parseInt(billingHoursPerDay, 10)
          : billingHoursPerDay;
      return convertedUnitsPerWeek * convertedBillingHoursPerDay;
    }

    return 0;
  },

  calculateTotalEngagedHours(
    candidateOpportunities: CandidateOpportunity[] = []
  ) {
    // todo: move the filter for Engaged opps here and handle the fallout
    const totalEngagedHours = candidateOpportunities
      .map(candidateOpportunity => {
        return this.calculateEngagementHours(candidateOpportunity);
      })
      .reduce((totalHours, engagementHours) => totalHours + engagementHours, 0);
    return totalEngagedHours;
  },

  calculateCandidateWeeklyAvailability(
    candidateOpportunities: CandidateOpportunity[],
    desiredWeeklyHours: Candidate['calculatedDesiredWeeklyHours']
  ) {
    const totalEngagedHours = this.calculateTotalEngagedHours(
      candidateOpportunities
    );
    return Math.max(desiredWeeklyHours - totalEngagedHours, 0);
  },
};
